import { Vaarweg_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Vaarweg_01,
	yaw: -10,
	pitch: -2.27172
};


let customHotspots  =	[
	{
		'pitch': 9.604355,
		'yaw': -175.2546,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 4,
		}
	},
	{
		'pitch': -25.61296,
		'yaw': -80.01580,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'water',
			'param': 'watertablet'
		}
	
	},
	{
		'pitch': -16.7668,
		'yaw': -34.0399,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'de peilschaal',
			'param': 'peilschaaltablet'
		}
	
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene3 = {
	sceneId: '3',
	config: getConfig(customConfig),
};	

export default Scene3;
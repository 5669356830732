import { P1 } from 'components/Typography';
import Theme from 'style/theme';
import styled from 'styled-components';
import { analysis, media, questions } from '../Data';

const AnswerRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	//background-color: ${Theme.colors.accent_2};
	width: 100%;
	height: 40%;
	padding-top: 2%;

`;
interface AnswerboxProps {
	submited : boolean
}

const AnswerBox = styled.button<AnswerboxProps>`
	border: 2px solid ${Theme.colors.accent_2};
    width: 15%;
    height: 90%;
	font-weight: bold;
	opacity: ${p => p.submited? 1 : 0.5 };
	background-color: ${p => p.submited? Theme.colors.accent_2 : Theme.colors.accent_1  };
	color: ${p => p.submited? Theme.colors.accent_1 : Theme.colors.accent_2  };
	font-size: 2vh;	
	&:not(:first-child){
		margin-left: 4%;
		}
    `;

interface AnswerProps {
    answer: string;
	activeQuestion: number;
}

const Answers = (props: AnswerProps) => {

	return(
		<>
			<AnswerRow>
				<AnswerBox submited={questions[props.activeQuestion].keywords[props.answer[0]]? true : false} >
					{questions[props.activeQuestion].keywords[props.answer[0]]? questions[props.activeQuestion].keywords[props.answer[0]].text : 'Key word'}
				</AnswerBox>
				{/* <AnswerBox submited={media[props.answer[1]]? true : false} >
					{media[props.answer[1]]? media[props.answer[1]].text : 'Media'}
				</AnswerBox> */}
				<AnswerBox submited={analysis[props.answer[1]]? true : false} >
					{analysis[props.answer[1]]? analysis[props.answer[1]].text : 'Analyse'}
				</AnswerBox>
			</AnswerRow>
		</>	
	);
};

export default Answers;
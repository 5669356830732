/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { Html, useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { Monitor_m } from 'assets/models';
import { useThreeContext } from 'context/three';
import { useGlobalContext } from 'context/global';
import { ContentWrapper } from './styles';
import { setSubCompleted } from 'data/objectives';

type GLTFResult = GLTF & {
  nodes: {
    PC_Scherm: THREE.Mesh
  }
  materials: {
    material: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {


	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(Monitor_m) as GLTFResult;	
	const { setActiveDialogueChunk, updateObjectiveData } = useGlobalContext();
	const { setControlsActive } = useThreeContext();


	useEffect(() => {
		setActiveDialogueChunk && setActiveDialogueChunk('l_02_first_01');
		setControlsActive && setControlsActive(false);
		setSubCompleted(3,1);
		updateObjectiveData && updateObjectiveData();

		setTimeout(() => {
			setDistanceFactor(1);
		}, 1);
	},[]);

	const [distanceFactor,setDistanceFactor] = useState<number>(0);

	return (
		<>
			<group scale={7} ref={group} {...props} dispose={null}>
				<group position={[-0.31, 0, 0.31]} rotation={[0, 0, 3.09]}>
					<group rotation={[0, 0.01, -0.02]}>
						<group position={[0, 0.08, 0]} rotation={[0.02, -0.01, 0.07]}>
							<group position={[0, 0.08, 0]} rotation={[-0.2, 0.04, -0.01]}>
								<group position={[0, 0.07, 0]} rotation={[-0.11, 0.25, -0.7]}>
									<group position={[0, 0.02, 0]} rotation={[0.4, -0.01, -0.16]} />
								</group>
							</group>
						</group>
					</group>
				</group>
				<group position={[-0.46, 0.06, 0.3]} rotation={[0, 0, -Math.PI]} scale={0.08}>
					<group position={[0.15, 0, 0]} rotation={[0, -0.01, 0.15]}>
						<group position={[0, 1.03, 0]} rotation={[0.01, 0.02, 0.05]}>
							<group position={[0, 1.15, 0]} rotation={[-0.02, -0.02, -0.01]}>
								<group position={[0, 1.02, 0]} rotation={[-0.18, -0.08, 0.11]}>
									<group position={[0, 0.41, 0]} rotation={[-0.1, 0, 0.61]}>
										<group position={[0, 0.3, 0]} rotation={[0.07, -0.1, 0.66]} />
									</group>
								</group>
							</group>
						</group>
					</group>
				</group>
				<group position={[-0.32, 0, 0.31]} rotation={[0, 0, 3.09]}>
					<group rotation={[0, 0.01, -0.02]}>
						<group position={[0, 0.08, 0]} rotation={[0.02, -0.01, 0.66]}>
							<group position={[0, 0.08, 0]} rotation={[-0.2, 0.05, -0.06]}>
								<group position={[0, 0.07, 0]} rotation={[-0.15, 0.21, -0.58]}>
									<group position={[0, 0.02, 0]} rotation={[0.41, -0.01, -0.42]} />
								</group>
							</group>
						</group>
					</group>
				</group>
				<mesh geometry={nodes.PC_Scherm.geometry} material={materials.material} />
			</group>
			<Html
				as='div'
				distanceFactor={distanceFactor}
				transform
				occlude
				rotation={[-0.035,0,0]}
				position={[0.015,0.195,0.17]}
				scale={1}
			>
				<ContentWrapper>
					<iframe src="https://player.vimeo.com/video/491739300"  width="100%" height="100%" frameBorder="0" allow="autoplay;  picture-in-picture" allowFullScreen></iframe>
					{/* <iframe src="https://player.vimeo.com/video/180041247"  width="100%" height="100%" frameBorder="0" allow="autoplay;  picture-in-picture" allowFullScreen></iframe> */}
				</ContentWrapper>
			</Html>
		</>
	);
}

useGLTF.preload(Monitor_m);

import { proportional } from 'util/proportional';

import styled from 'styled-components';

const ConsoleContainer = styled.div.attrs({width:[438,600]})`

${proportional}

    z-index: 9001;
    position: absolute;
    right:0;
    top:0;
    display: flex;
    justify-content: center;
    height: 20vh;
	text-align: left;
`;

const ConsoleRow = styled.div`
    display: flex;
    justify-content: left;
	text-align: left;
    align-items: center;
	margin-top: 10px;
    button {
        margin-left: 30px;
    }
	select {
		font-size: 24px;
	}
`;

const ConsoleWrap = styled.div`
    z-index: 5;
    width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    color: white;
    overflow: auto;
    text-align: left;
	padding: 20px;
	button {
		float: right;
	}
`;

const ConsoleTitle = styled.div`
	font-size: 1.5rem;
	font-weight: bold;

`;

const ConsoleLabel = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
	padding-right: 10px;
`;


export { ConsoleRow, ConsoleLabel, ConsoleTitle, ConsoleWrap, ConsoleContainer};
import { useGlobalContext } from 'context/global';
import { useEffect, useState } from 'react';
import {ContentWrapper} from './styles';
import { questions } from './Data';
import { error, Kaart0, Textmining1, tweet } from 'assets/images';
import styled, { css } from 'styled-components';
import Answers from './Rows/AnswerRow';
import Output from './Rows/OutputRow';
import BackButton from './Rows/ButtonsRowBack';

import { setSubCompleted } from 'data/objectives';
import theme from 'style/theme';
import { useResponsiveFontSize } from 'react-responsive-font-size';

const PaddingWrapper = styled.div`

	width: 100%;
	height: 100%;
	
    background-color: white;
    border-radius: 25px;
	overflow: hidden;
`;

const TextMining = () => {

	const {setActiveDialogueChunk, setCloseButtonActive, setActiveIntObj, updateObjectiveData} = useGlobalContext();
	const [activeQuestion, setActiveQuestion] = useState<number>(0);
	const [activeAnwser, setActiveAnswer] = useState<string>('');
	const [activeRow, setActiveRow ] = useState<number>(1);
	const [correct, setCorrect] = useState<boolean>(false);
	const responsiveFont = useResponsiveFontSize(0.5, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });

	const [answer, setAnswer] = useState<string>('AA');

	const selectGridSlot = ( index, value ) => {
		setAnswer(a => replaceAt(a, value, index));
	};
	
	const replaceAt = (string, replacement, index) => {
		return string.substring(0, index) + replacement + string.substring(index +1);
	};

	const checkIfCorrect = () => {

		if(answer == questions[activeQuestion].correct){
			setActiveAnswer(questions[activeQuestion].img);
			setCorrect(true);
			setActiveDialogueChunk && setActiveDialogueChunk(`l_02_result_0${activeQuestion}`);

		} else {
			setActiveAnswer(error);
			setCorrect(false);
			setAnswer('AA');
		}
	};

	const next = () => {
		setActiveRow(activeRow+1);
		if(activeRow == 2){
			checkIfCorrect();
		}
		if (activeRow == 3 && answer == questions[activeQuestion].correct) {		
			if (questions[activeQuestion+1]) {
				setActiveQuestion(activeQuestion+1);
				setActiveRow(1);
				setActiveAnswer('');
				setAnswer('AA');
				setCorrect(false);
				setActiveDialogueChunk && setActiveDialogueChunk(`l_02_open_0${activeQuestion}`);

			} else {			
				setActiveDialogueChunk && setActiveDialogueChunk('l_02_first_03');
				// setCloseButtonActive && setCloseButtonActive(true);
				setActiveIntObj && setActiveIntObj('');
				setSubCompleted(3,1);
				setSubCompleted(0,2);
				updateObjectiveData && updateObjectiveData();
			}	
		}
	};
	const back = () => {
		setActiveRow(activeRow-1);
		if (activeAnwser == error) {
			setActiveAnswer('');
			setActiveRow(1);
		}
	};

	return(
		<PaddingWrapper style={{borderRadius:'0', backgroundColor: `${theme.colors.accent_1}`}}>
			<Yellow>
				<Title>{questions[activeQuestion].question}</Title>
				<Answers 
					answer={answer} activeQuestion={activeQuestion} 
				/>
			</Yellow>	
			<Blue>
				<BlueBackdrop/>

				{ activeRow < 3 && 
					<TopRow>				
						<Output 
							selectGridSlot={(index, value) => selectGridSlot(index, value)} 
							activeRow={activeRow} 
							answer={answer} 
							activeQuestion={activeQuestion} 
							activeAnswer={activeAnwser}
						/>
					</TopRow>
				}

				<BottomRow>
					<div><Button onClick={() => back()} visible={correct && activeRow === 3 ? false : activeRow != 1 ? true : false}>Vorige</Button></div>
					{/* <Output 
						selectGridSlot={(index, value) => selectGridSlot(index, value)} 
						activeRow={activeRow} 
						answer={answer} 
						activeQuestion={activeQuestion} 
						activeAnswer={activeAnwser}
					/> */}
					<Result img={activeAnwser}/>
					<div><Button onClick={(e) => {next(); e.currentTarget.blur();}} visible={!correct && activeRow === 3 ? false : true }>{ activeRow == 2 ? 'Indienen' : 'Volgende'}</Button></div>
				</BottomRow>
				{/* <BackButton    
					activeRow={activeRow}
					activeAnswer={activeAnwser}
					answer={answer}
					back={() => back()}
					next={() => next()}
				/>

				<NextButton    
					activeRow={activeRow}
					activeAnswer={activeAnwser}
					answer={answer}
					back={() => back()}
					next={() => next()}
				/> */}
			</Blue>	
		</PaddingWrapper>
	);
};

//#region Styled Components

const Title = styled.div`
	color: ${theme.colors.accent_2};
	text-align: center;
	/* height: 15%; */
	width: 60%;
	font-weight: bold;
	font-size: 3vh;
	position: relative;
	left: 20%;
	padding-top: 2%;
`;

// Top Wrapper
const Yellow = styled.div`
	height: 30%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
`;

// Bottom Wrapper
const Blue = styled.div`

	position: relative;

	height:70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
`;

const BlueBackdrop = styled.div`
	position: absolute;
	left:0;
	top:0;
	bottom:0;
	right:0;

	background-color: ${theme.colors.accent_4};
	border-top-left-radius: 2%;
	border-top-right-radius: 2%;

	opacity: 0.5s;

	z-index: 0;
`;

const Button = styled.button<{visible:boolean}>`

	visibility: ${p => p.visible ? 'visible' : 'hidden'};

	background-color: ${theme.colors.primary};
    color: ${theme.colors.accent_2};
    //margin: 0;

	cursor: pointer;
	font-size: 3.5vh;
    display: flex;
    align-items: center;

    font-weight: bold;
    line-height: 100%;
	width: 100%;
	//height: 100%;
	border: none;

	padding-top: 10%;
	padding-bottom: 10%;


	&:hover, :focus{
		color: ${theme.colors.hover};
		background-color: ${theme.colors.hover_bg};
	}

`;

const TopRow = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const BottomRow = styled.div`

	margin-bottom: 5%;
	margin-top: 3%;

	position: relative;
	//height: 40%;

	flex-grow: 1;
	flex-basis: 0;

	width: 100%;
	z-index: 1;

	display:flex;
	flex-direction: row;

	/* align-items: flex-end; */

	& > div {
		margin-left:3%;
		margin-right:3%;
		display: flex;
		align-items: flex-end;
	}
`;

const Result = styled.div<{img: string}>`

	flex-grow: 1;

	/* margin-left: 5%;
	margin-right: 5%; */
	
	${ p => p.img && css `
		background-image: url(${p.img});
		background-position: center;
		background-size: 100% 100%;
	`}



`;

//#endregion

export default TextMining;

import { ArrowHover, ArrowNormal } from 'assets/icons';
import Button from 'components/Buttons/Button';
import { useGlobalContext } from 'context/global';
import Levels from 'data/levels';
import { useRef } from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';


const BasicButton = styled(Button)`
	width: 200px;
`;

const Container = styled.div`
	margin-top: 10px;
    position: relative;
`;



const SideButton = styled(BasicButton).attrs({})`
    position: absolute;
    right:0;
	bottom: 1rem;
    transform: translateX(15px);
	height: 3rem;
`;

const HiddenButton = styled(BasicButton)`
    visibility: hidden;
	/* outline: red solid 2px; */
	height: 3rem;
`;

const LevelStartButtonSpace = () => {
	return(
		<Container>
			<HiddenButton> Hidden </HiddenButton>
		</Container>
	);
};

const LevelStartButton = () => {

	const { setLevelSelectActive, setActiveObjective, setActivePannellumScene, activeLevel, levelSelectActive, setActiveDialogueChunk } = useGlobalContext();

	const handler = () => {
		if(activeLevel != undefined){
		// Disable Map / Play Map Animation
			setLevelSelectActive && setLevelSelectActive(false);
			// set active Pannellum Scene in global context of Selected Level
			setActivePannellumScene && setActivePannellumScene (Levels[activeLevel].startingPannellumScene);
			//close dialogue
			if(Levels[activeLevel].startingDialogue){
				setActiveDialogueChunk && setActiveDialogueChunk(Levels[activeLevel].startingDialogue);
			}
			else{
				setActiveDialogueChunk && setActiveDialogueChunk('');

			}
			// setActiveObjective in Global Context
			setActiveObjective && setActiveObjective(Levels[activeLevel].objectiveId);
		}

	};

	return (
		<SideButton onClick={() => handler()}>Start</SideButton>
	);
};

const ArrowIcon = styled.div.attrs({padding:[15,15]})`
    background-image: url(${ArrowNormal});
    background-size: 60%, 60%;
    background-position: center;
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;
`;

const SquareSideButton = styled(Button).attrs({})`
    ${proportional}

    position: absolute;
    right:0;
    bottom:1rem;
    transform: translateX(50%);

	height: 40px;
	width: 40px;

	background-image: url(${ArrowNormal});
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;

    &:hover , :focus {
		background-image: url(${ArrowHover});
	} 
`;

interface CDBProps {
    proceed: () => void;
}

const ContinueDialogueButton = (props : CDBProps) => {

	const ref = useRef<HTMLButtonElement>(null);

	const handler = () => {
		if(ref.current != undefined){
			ref.current.blur();
		}
	};

	return (
		<SquareSideButton ref={ref} onClick={() => {props.proceed(); handler();}}/>
	);
};

export {LevelStartButton, ContinueDialogueButton, LevelStartButtonSpace, SideButton};
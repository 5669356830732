import { PosterIMG } from 'assets/images';
import { CloseButton } from 'components/Buttons/Button';
import { useGlobalContext } from 'context/global';
import { Objectives, setSubCompleted } from 'data/objectives';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const EndPoster = () => {

	const {poster,setDisplayEnd, setPoster,updateObjectiveData, setActiveDialogueChunk} = useGlobalContext();

	const close = () => {
		setPoster && setPoster(false);
		if(!Objectives[7].subObjectives[3].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_06_eind_01');
			setTimeout(() => {
				setSubCompleted(7, 3);
				setSubCompleted(0,6);
				updateObjectiveData && updateObjectiveData();
			}, 100);
		}
		//setActiveDialogueChunk && setActiveDialogueChunk('blockinput');
	};

	return (<>
		{poster && <Container>
			<Content >
				{/* <img src={PosterIMG}></img> */}
			</Content>
			<CloseButton onClick={close}/>
		</Container>}
	</>
	);
};
	//#region Styled Components

const ContainerKF = keyframes`
    0% {
        opacity: 0;
        pointer-events: none;
    }
    99%{
        pointer-events: none;
    }
    100% {
        opacity: 1;
        pointer-events: all;
    }
`;

const Container = styled.div`
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: rgba(0,0,0,0.5);
    text-align: center;
    /* margin-left: 130px;
    margin-right: 130px; */


    @media ${p => p.theme.devices.mobile} {
		padding: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		padding: 30px;
	};

    z-index: 6;

    transition: opacity 1s;
    animation: ${ContainerKF} 1s;
`;



const Content = styled.div`
    background-image: url(${PosterIMG});
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height:100%;
    width: 75%;
    background-color: red;
    & > iframe {
        /* background: rgba(0,0,0,0.5); */
        /* margin-top: 15px; */
    }

`;

//#endregion



export default EndPoster;
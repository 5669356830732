/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { Suitcase_m } from 'assets/models';
import { useGlobalContext } from 'context/global';
import { setSubCompleted } from 'data/objectives';
import { checkLevelsForUnlock } from 'data/levels';

type GLTFResult = GLTF & {
  nodes: {
    suitcase_bottom: THREE.Mesh
    code_left_3: THREE.Mesh
    handle: THREE.Mesh
    code_left_1: THREE.Mesh
    code_left_2: THREE.Mesh
    code_right_1: THREE.Mesh
    code_right_2: THREE.Mesh
    code_right_3: THREE.Mesh
    suitcase_top: THREE.Mesh
    latch_left: THREE.Mesh
    latch_right: THREE.Mesh
  }
  materials: {
    Material: THREE.MeshStandardMaterial
  }
}

type ActionName = 'suitcase_open' | 'latch_left' | 'handle_01' | 'codelock' | 'latch_right'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(Suitcase_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const { updateObjectiveData } = useGlobalContext();

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].clampWhenFinished = true;		
			}
		}

	},[actions]);

	const click = () => {
		actions['handle_01'].play();		
		setSubCompleted(0, 2); 
		setSubCompleted(3, 0); 
		updateObjectiveData && updateObjectiveData();
	};

	return (
		<group scale={0.1} ref={group} {...props} dispose={null}>
			<mesh geometry={nodes.suitcase_bottom.geometry} material={nodes.suitcase_bottom.material}>
				<mesh
					name="code_left_3"
					geometry={nodes.code_left_3.geometry}
					material={nodes.code_left_3.material}
					position={[8.01, -0.69, 6.7]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={[0.33, 0.14, 0.33]}
				/>
				<mesh
					name="handle"
					geometry={nodes.handle.geometry}
					material={nodes.handle.material}
					position={[8.42, -1.32, -2.62]}
					rotation={[0, 0, -1.4]}
					scale={[0.5, 0.31, 2.24]}
					onClick={click}
				/>
				<mesh
					geometry={nodes.code_left_1.geometry}
					material={nodes.code_left_1.material}
					position={[8.01, -0.69, 7.7]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={[0.33, 0.14, 0.33]}
				/>
				<mesh
					geometry={nodes.code_left_2.geometry}
					material={nodes.code_left_2.material}
					position={[8.01, -0.69, 7.2]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={[0.33, 0.14, 0.33]}
				/>
				<mesh
					geometry={nodes.code_right_1.geometry}
					material={nodes.code_right_1.material}
					position={[8.01, -0.69, -7.71]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={[0.33, 0.14, 0.33]}
				/>
				<mesh
					geometry={nodes.code_right_2.geometry}
					material={nodes.code_right_2.material}
					position={[8.01, -0.69, -7.2]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={[0.33, 0.14, 0.33]}
				/>
				<mesh
					geometry={nodes.code_right_3.geometry}
					material={nodes.code_right_3.material}
					position={[8.01, -0.69, -6.71]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={[0.33, 0.14, 0.33]}
				/>
			</mesh>
			<mesh
				name="suitcase_top"
				geometry={nodes.suitcase_top.geometry}
				material={nodes.suitcase_top.material}
				position={[-8.16, 0, 0]}>
				<mesh
					name="latch_left"
					geometry={nodes.latch_left.geometry}
					material={nodes.latch_left.material}
					position={[16.41, 0.91, 6]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[0.11, 0.5, 0.11]}
				/>
				<mesh
					name="latch_right"
					geometry={nodes.latch_right.geometry}
					material={nodes.latch_right.material}
					position={[16.41, 0.91, -6]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[0.11, 0.5, 0.11]}
				/>
			</mesh>
		</group>
	);
}

useGLTF.preload(Suitcase_m);

import i18next from 'i18next';
import { initReactI18next }from 'react-i18next';

import Backend from 'i18next-xhr-backend';



const Languages = ['en','nl'];


i18next
	//load translation from public/locales
	.use(Backend)
	//select user language
	//.use(LanguageDetector)
	.use(initReactI18next)
	
	.init({
		lng: window.location.pathname.substring(1),
		fallbackLng: 'en', 
		debug: false,
		whitelist: Languages,
	
		interpolation:{ escapeValue: false },	

		react: {
			wait: true
		}

	});


export default i18next;

import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext, useGlobalContext } from 'context/global';
import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';
import Algorithm from './screen/mainscreen';
import styled from 'styled-components';
import Theme from 'style/theme';
import { algorithmHelp } from 'assets/images';


const AlgoritmeScherm = () => {

	const { setActiveDialogueChunk, setCloseButtonActive } = useGlobalContext();
	const globalContextValues = useContext(GlobalContext);
	const [showIntroduction, setShowIntroduction] = useState<boolean>(true);
	
	useEffect(() => {
		setCloseButtonActive && setCloseButtonActive(false);
		setActiveDialogueChunk && setActiveDialogueChunk('l_05_screen_04');
	},[]);
	
	const start = () => {
		setActiveDialogueChunk && setActiveDialogueChunk('l_05_play_01');	
		setShowIntroduction(false);
	};

	return (
		<>
			<TabletBorder>				
				<GlobalContext.Provider value={globalContextValues}>
					<Content>
						{showIntroduction ? 
							<>
								<Open>
									<Title>Overzicht informatie</Title>
									<IntroPicture/>
									<Next onClick={start}>Volgende</Next>
								</Open>
							</>					
							: 
							<Algorithm/>
						}
					</Content>					
				</GlobalContext.Provider>
			</TabletBorder>
		</>
	);
};

const IntroPicture = styled.div`
	background-image: url(${algorithmHelp});
	background-position: center;
	background-size: 100% 100%;
	background-color: white;
	background-repeat: no-repeat;
	width: 70%;
	height: 70%;
`;

const Content = styled.div`
	background-color: ${Theme.colors.accent_1};
    border-radius:2%;
	width: 100%;
	height: 100%;
`;

const Next =  styled.button`

	height: 10%;
	padding: 1%;
	border: none;
	margin-top:2%;
	color: #1cb1e0;
	font-weight: bold;
	font-size: 3vh;
	background-color: white;
	background-repeat: no-repeat;
	cursor: pointer;

	&:hover, :focus{
		background-color: #1cb1e0;
		color: white;	
	}

`;

const Title = styled.div`
	color: black;
	text-align: center;
	font-size: 2.5vh;
	padding: 1%;
	font-weight: bold;
	position: relative;
`;


const Open = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width:100%;
	height:100%;
`;

export default AlgoritmeScherm;
import { zeeschermBorder } from 'assets/images';
import { ReactNode } from 'react';
import styled from 'styled-components';

type zeeschermborderProps = {
    children: ReactNode;
}
const ZeeschermBorder = ({children}: zeeschermborderProps) => {
	return(
		<Content>
			<ActiveComponent>
				{children}
			</ActiveComponent>
		</Content>
	);
};

//styled components
const Content = styled.div`
    background-image: url(${zeeschermBorder});
	background-size: 100% 100%;
	background-repeat: no-repeat;

    border-radius: 1%;
	overflow: hidden; 

    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 135vh;

`;
const ActiveComponent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    height: 84%;
    width: 88%;
    transform: translate(1%, -1.15%);
	overflow: hidden; 

`;

export default ZeeschermBorder;
import { Sluis_02 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Sluis_02,
	pitch: 90,
	yaw:  -10,
};

let customHotspots  =	[
	{
		
		'pitch': -1.664,
		'yaw':  -34.276,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 8,
		}
	},
	{
		'pitch': -23.171,
		'yaw': 102.537,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'Luchtdrone',
			'param': 'luchtdronetablet'
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene1 = {
	sceneId: '7',
	config: getConfig(customConfig),

};	

export default Scene1;
import { TargetIconHover, TargetIconNormal } from 'assets/icons';
import { goal_accomplished_01, goal_accomplished_02 } from 'assets/sounds';
import BackToMap from 'components/Buttons/BackToMapButton';
import { CloseButton } from 'components/Buttons/Button';
import { useAudioContext } from 'context/audio';
import { useGlobalContext } from 'context/global';
import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { proportional } from 'util/proportional';
import SubObj, { SubObjBox } from './SubObj';

const ObjectiveWrapper = styled.div`

    position: absolute;
    left:0;
    top:0;
	//overflow-x: auto;
	@media ${p => p.theme.devices.mobile} {
		max-width: 50vw;
		margin: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		max-width: 28vw;
		margin: 30px;
	};

    z-index: 6;


`;

const Container = styled.div`
	
	${proportional}

	position: relative;


	display: flex;
	flex-direction: column;
	justify-content: center;
	color: ${props => props.theme.colors.secondary};

    background-color: ${props => props.theme.colors.accent_1};

	padding: 30px;

	& > :last-child{
		margin-bottom: 0;
	}

	user-select: none;

`;

const MainObj = styled.div<{completed: boolean}>`
	font-size: 1.2rem;
	font-weight: bold;
	margin: 0;
	margin-bottom: 1rem;

	display: flex;
	align-items: center;

	${p => p.completed && css`

		& > :nth-child(2){
			opacity: 0.5;
		}

	`}

`;


const Title = styled.h2.attrs({marginRight:[15,30]})`

	${proportional};

	margin: 0;

	color: ${p => p.theme.colors.accent_2};
`;

const ObjectiveButton = styled.button` 
	position: absolute;
	left:0;
	top:0;

	height: 100px;
	width: 100px;
	margin: 30px;

	border-radius: 100%;

	background-image: url(${TargetIconNormal});
	background-size: 60%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;

	border: none;

	z-index: 6;

	filter: ${p => p.theme.filters.dropShadow};

	&:hover, :focus{
		background-color: ${p => p.theme.colors.hover_bg};
		background-image: url(${TargetIconHover});
	}

	@media ${p => p.theme.devices.mobile} {
		margin: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		margin: 30px;
	};

`;


const CloseButtonStyled = styled(CloseButton)`

	right:-15px;
	top:0;
	margin: 0;

	transform: translateX(100%);

`;

const Line = styled.hr`
	border-color: ${p => p.theme.colors.accent_4};
	width: 100%;
	margin: 0;
	margin-bottom: 1rem;

`;


const ObjectiveInterface = () => {

	const { tutorialActive, setPlayObjectiveSound, playObjectiveSound, objectivesHidden, setObjectivesHidden, activeLevel, activeObjective, setActiveObjective, activeObjectiveData , setActiveObjectiveData, activeDialogueChunk} = useGlobalContext();

	const [showObjectives, setShowObjectives] = useState<boolean>(false);

	const {playSound} = useAudioContext();

	useEffect(() => {
		if(activeObjectiveData && !activeDialogueChunk){
			setShowObjectives(true);
		}
		else{
			setShowObjectives(false);

		}
	},[activeDialogueChunk, activeObjectiveData,]);

	useEffect(() => {
		if(!objectivesHidden && playObjectiveSound && showObjectives){
			if(playObjectiveSound === 1){
				playSound && playSound(goal_accomplished_01);
			}
			else{
				playSound && playSound(goal_accomplished_02);
			}
			setPlayObjectiveSound && setPlayObjectiveSound(0);
		}
	},[objectivesHidden, playObjectiveSound, showObjectives]);

	const toggleObjectivesHidden = () => {
		setObjectivesHidden && setObjectivesHidden(!objectivesHidden);
	};

	return(
		<>
			{ activeObjectiveData && showObjectives && !objectivesHidden ?
				<ObjectiveWrapper>
					<BackToMap/>
					<Container>
						<Title>Hoofddoel</Title>
						<Line/>
						<CloseButtonStyled onClick={() => {toggleObjectivesHidden();}}/>
						<MainObj completed={activeObjectiveData.completed}>
							<SubObjBox completed={activeObjectiveData.completed}><div/></SubObjBox>
							<div>{activeObjectiveData.text}</div>
						</MainObj>
						<Title>Subdoelen</Title>
						<Line/>


						{ activeObjectiveData.subObjectives.map( (objective, index ) => {
							return(
								<SubObj 
									key={`objective-${index}-${objective}`} 
									completed={objective.completed}
									objective={objective}
								> 

								</SubObj>
							);
						})
						}
					</Container>
				</ObjectiveWrapper>
				: showObjectives && objectivesHidden && !tutorialActive ?
					<ObjectiveButton onClick={() => {toggleObjectivesHidden();}}/> 
					: null
			}		
		</>
	);
};

export default ObjectiveInterface;


import { useHelper } from '@react-three/drei';
import { useThree, useFrame, Object3DNode} from '@react-three/fiber';
import { DirectionalLightHelper } from 'three';
import { useEffect, useRef, useState } from 'react';
import { useThreeContext } from 'context/three';

const Lighting = () => {

	const {camera} = useThree();

	const dl = useRef<any>();

	const { controls } = useThreeContext();

	useEffect(() => {
		controls.current && controls.current.addEventListener('change', updateDirectionLightPos);
		return () => {
			controls.current && controls.current.removeEventListener('change', updateDirectionLightPos);
		};
	},[]);

	const updateDirectionLightPos = () => {
		if(dl.current && camera){
			if(dl.current.position != camera.position){
				dl.current.position.copy(camera.position);
			}
		}
	};

	// useHelper(dl, DirectionalLightHelper, 1, 'cyan');
    
	return(
		<>
			<ambientLight intensity={0.2} />
			<directionalLight ref={dl} color="white" position={[1,1,1]} intensity={0.8}/>
		</>
	);
};

export default Lighting;
import { mute_hover, mute_normal, sound_hover, sound_normal } from 'assets/icons';
import { useAudioContext } from 'context/audio';
import { useState } from 'react';
import styled from 'styled-components';

interface MuteProps {
    img: string
    hoverimg: string
}

const MuteButton = styled.button<MuteProps>` 
	position: absolute;
	left:0;
	bottom:0;

	height: 50px;
	width: 50px;
	margin: 30px;

	border-radius: 100%;

	background-image: url(${p => p.img});
	background-size: 60%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;
opacity: 0.5;
	border: none;

	z-index: 6;

	filter: ${p => p.theme.filters.dropShadow};

	&:hover{
        opacity: 1;
		background-color: ${p => p.theme.colors.hover_bg};
		background-image: url(${p => p.hoverimg});
        cursor:pointer;
	}

	@media ${p => p.theme.devices.mobile} {
		margin: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		margin: 30px;
	};

`;
const Mutebutton = () => {

	const {voSource, audioSources, backgroundSource, setMuted} = useAudioContext();
	const [muteState, setMuteState] = useState<boolean>(false);

	const mute = () => {
		setMuted && setMuted(true);
		setMuteState(true);
		if(backgroundSource && voSource && audioSources){
			backgroundSource.muted = true;
			voSource.muted = true;
			audioSources.map((audioSource, index) => {
				audioSource.muted = true;
			});
		}
	};

	const soundon = () => {
		setMuted && setMuted(false);
		setMuteState(false); 
		if(backgroundSource && voSource && audioSources){
			backgroundSource.muted = false;
			voSource.muted = false;
			audioSources.map((audioSource, index) => {
				audioSource.muted = false;
			});		}

	};
	return (
		<>
			{muteState ? 
				<MuteButton onClick={soundon} img={mute_normal} hoverimg={mute_normal}/> 
				:
				<MuteButton onClick={mute} img={sound_normal} hoverimg={sound_normal}/>
			}
		</>
	);

};

export default Mutebutton;
import LevelSelect from 'components/LevelSelect';
import { Controls, Lighting, ThreeCanvas } from 'components/Three';
import InteractiveObject from 'components/Three/InteractiveObject';
import { GlobalContextProvider } from 'context/global';
import React, { Suspense, useEffect, useState } from 'react';
import Layout from './components/Layout';
import { I18nextProvider } from 'react-i18next';
import i18next from './components/Localization/index';
import Console from './components/Console/index';
import Input from 'components/Input';
import Pannellum from 'components/Pannellum/Pannellum';
import ObjectiveInterface from 'components/ObjectivesInterface';
import Dialogue from 'components/Dialogue';
import Tutorial from 'components/Tutorial';
import ForceLandscape from 'components/ForceLandscape';
import { AudioContextProvider } from 'context/audio';
import SnelwegManager from 'components/Snelweg';
import SoundManager from 'components/backgroundsoundManager';
import EndScreen from 'components/EndScreen';
import Mutebutton from 'components/Buttons/Mutebutton/Mutebutton';
import StartingVideo from 'components/StartingVideo';
import EndPoster from 'components/EndPoster';
import UniversalCanvas from 'components/UniversalComponent';
import SluishologramButton from 'components/Buttons/SluishologramButton';

import {SCORM} from 'pipwerks-scorm-api-wrapper';
import { setObjectives } from 'data/objectives';
import Levels from 'data/levels';
// import ConsoleModelSelect from 'components/Console/Views/ModelSelect';

const App = () => { 

	const [scormInitialized, setScormInitialized] = useState<boolean>(false);

	useEffect(() => {
		SCORM.init();

		const suspendData = SCORM.get('cmi.suspend_data');
		// console.log('suspend data:', suspendData);

		if(suspendData === 'null' || !suspendData){
			// console.log('no save data found');
			setScormInitialized(true);
		}
		else{
			console.log('save data found!');
			const saveData = JSON.parse(suspendData);

			setObjectives(saveData.objectives);

			saveData.levels.map((level, index) => {
				Levels[index].completed = level.completed;
				Levels[index].firstTime = level.firstTime;
				Levels[index].locked = level.locked;
			});
			setScormInitialized(true);
		}

	},[]);

	return (
		<> {scormInitialized && 
			<Suspense fallback={ (<div>Loading...</div>)}>
				<Layout>				
					<I18nextProvider i18n={i18next}>
						<GlobalContextProvider>
							<AudioContextProvider>
								<>
									<ForceLandscape/>				
									<Tutorial/>
									<SoundManager/>
									<SnelwegManager/>
									<Console />
									<Input />
									<Dialogue/>
									<ObjectiveInterface/>
									<StartingVideo/>
									<Mutebutton/>
									<SluishologramButton/>
									<LevelSelect/>
									<EndScreen/>
									<EndPoster/>
									<UniversalCanvas>
										<InteractiveObject/>
									</UniversalCanvas>
									<ThreeCanvas>
										<Controls/>
										<Lighting/>
										<InteractiveObject/>
									</ThreeCanvas>
									<Pannellum/>
								</>
							</AudioContextProvider>
						</GlobalContextProvider>
					</I18nextProvider>
				</Layout>
			</Suspense>
		}
		</>
	);
};

export default App;

import { Vrachtschip_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Vrachtschip_01,
	yaw: -10,
	pitch: -2.27172
};


let customHotspots  =	[
	// {
	// 	'pitch': -33.42542,
	// 	'yaw': 139.671,
	// 	'action': 'changeScene',
	// 	'createTooltipArgs': {
	// 		sceneId: 5,
	// 	}
	// },
	{
		'pitch': 6,
		'yaw': -32,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'Kaartenscherm',
			'param': 'zeeminigame'
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene6 = {
	sceneId: '6',
	config: getConfig(customConfig),
};	

export default Scene6;
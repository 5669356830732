import { goal_accomplished_01 } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import Levels from 'data/levels';
import { Objectives } from 'data/objectives';
import { createContext, useContext, useEffect, useState } from 'react';
import { contextProps, globalContextProps, objective } from './types';

import {SCORM} from 'pipwerks-scorm-api-wrapper';

const GlobalContext = createContext<Partial<globalContextProps>>({});

const GlobalContextProvider = (props: contextProps) => {

	//#region functions

	const onSetDisplayConsole = (val) => {
		setDisplayConsole(val);
	};

	const onToggleDisplayConsole = () => {
		setDisplayConsole(e => !e);
	};

	const updateObjectiveData = () => {
		if(activeObjective){
			setActiveObjectiveData({...Objectives[activeObjective - 1]});
			setObjectivesHidden && setObjectivesHidden(false);
			if(Objectives[activeObjective - 1].completed){
				setPlayObjectiveSound(2);
			}
			else{
				setPlayObjectiveSound(1);
			}
		}

		SCORM.set('cmi.suspend_data', JSON.stringify({levels: Levels, objectives: Objectives}));
		// SCORM.set('cmi.suspend_data', JSON.stringify({test:'test string', boolean: false}));
		SCORM.save();

	};

	//#region useStates

	//#region Objectives

	const [ activeObjective, setActiveObjective ] = useState<number | undefined>(1);
	const [ activeObjectiveData, setActiveObjectiveData ] = useState<objective | undefined>();
	const [ objectivesHidden, setObjectivesHidden ] = useState<boolean>(false);

	//#endregion
	const [ hologramButtonState, setHologramButtonState] = useState<number>(0);

	const [ poster, setPoster] = useState<boolean>(false);

	const [ activeIntObj, setActiveIntObj ] = useState<string>('');

	const [ displayConsole, setDisplayConsole] = useState<boolean>(false);

	const [ displayEnd, setDisplayEnd] = useState<boolean>(false);

	const [ levelSelectActive, setLevelSelectActive ] = useState<boolean>(true);
	
	const [ activePannellumScene, setActivePannellumScene ] = useState<number>();
	
	const [ activeLevel, setActiveLevel ] = useState<number>(-1);

	const [ closeButtonActive, setCloseButtonActive ] = useState<boolean>(true);

	const [ activeDialogueChunk, setActiveDialogueChunk ] = useState<string | undefined>('hm_introduction_01');

	const [ optionsActive, setOptionsActive ] = useState<boolean>(true);

	const [ dialogueBlocksInteraction, setDialogueBlocksInteraction ] = useState<boolean>(false);

	const [ playObjectiveSound, setPlayObjectiveSound ] = useState<number>(0);

	const [ tutorialActive, setTutorialActive ] = useState<boolean>(false);
 
	//#endregion

	//#region useEffects

	const [initalize, setInitialize] = useState<boolean>(false);

	useEffect(() => {
		const suspendData = SCORM.get('cmi.suspend_data');
		if(suspendData !== 'null' && suspendData !== '' && suspendData !== null && suspendData && !initalize){
			setActiveDialogueChunk(undefined);
			setInitialize(true);
		}
	},[]);

	useEffect(() => {
		if(activeObjective){
			setActiveObjectiveData && setActiveObjectiveData(Objectives[activeObjective-1]);
		} else {
			setActiveObjectiveData && setActiveObjectiveData(undefined);
		}
	},[activeObjective]);
	
	//#endregion

	//#region Passed Props

	const passedFunctions = {
		//level select
		setLevelSelectActive,
		setActiveLevel,

		//objectives
		setActiveObjective,
		setActiveObjectiveData,
		updateObjectiveData,
		setObjectivesHidden,
		setPlayObjectiveSound,
				
		//objects/models
		setActiveIntObj,
		setDisplayEnd,
		//buttons
		setCloseButtonActive,

		//console
		setDisplayConsole,
		onSetDisplayConsole,
		onToggleDisplayConsole,

		//pannellum
		setActivePannellumScene,
		setPoster,
		//dialogue
		setActiveDialogueChunk,
		setOptionsActive,
		setDialogueBlocksInteraction,

		setTutorialActive,
		setHologramButtonState
	};

	const passedValues = {
		//level select
		levelSelectActive,
		activeLevel,
		poster,

		//objectives
		activeObjective,
		activeObjectiveData,
		objectivesHidden,
		playObjectiveSound,

		//objects/models
		activeIntObj,

		//buttons
		closeButtonActive,

		//console
		displayConsole,
		displayEnd,
		//pannellum
		activePannellumScene,

		//dialogue
		activeDialogueChunk,
		optionsActive,
		dialogueBlocksInteraction,

		tutorialActive,
		hologramButtonState
	};

	//#endregion

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};

import { Level3Overzicht } from 'assets/images';
import { platform } from 'os';
import { theme } from 'style';
import styled, { css } from 'styled-components';

const BottomRow = ({leave}: {leave:boolean}) => {
	return(
		<Row leave={leave}/>
	);
};

//#region styledcomponents

const Row = styled.div<{leave:boolean}>`
    background-color: ${theme.colors.accent_1};
    background-image: url(${Level3Overzicht});
    background-size: 90%;
	background-position: 50% 15%;
	background-repeat: no-repeat;

    position: relative;
    bottom:0;
    left:0;
    right:0;

    height: 30%;
    //width: 110%;
    border-radius: 4% 4% 2% 0;

    transition: bottom 1s;

    ${p => p.leave && css`
        bottom: -50%;
    `}
`;

//#endregion

export default BottomRow;
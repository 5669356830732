import { Kantoor_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Kantoor_01,
	'pitch': 230,
	'yaw': -20,
};


let customHotspots  =	[
	{
		'pitch': -12.9701,
		'yaw': -130.860,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 20,
		}
	},
	{
		'pitch': -1.497464,
		'yaw': 45.50765,
		'action': 'openModel',
		'createTooltipArgs': {
			'type': 'scene',
			'text': 'Kalender',
			'param': 'scheurkalender2',			
		}
	},

	{
		'pitch': -15,
		'yaw': -35,
		'action': 'openModel',
		'createTooltipArgs': {
			'type': 'scene',
			'text': 'Monitor',
			'param': 'vopmonitor1',			
		}
	},

	{
		'pitch': -29,
		'yaw': -161,
		'action': 'openModel',
		'createTooltipArgs': {
			'type': 'scene',
			'text': 'Monitor',
			'param': 'vopmonitor2',			
		}
	},
	{
		'pitch': -17.7,
		'yaw': 13.89,
		'action': 'openModel',
		'createTooltipArgs': {
			'type': 'scene',
			'text': 'Monitor',
			'param': 'vopmonitor3',			
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene3 = {
	sceneId: '9',
	config: getConfig(customConfig),
};	

export default Scene3;
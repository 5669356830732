import React, { useEffect, useRef, useState} from 'react';

import { Content, ContentWrapper} from '../styles';
import { Brug1} from 'assets/images';
import { useGlobalContext } from 'context/global';
import { Objectives, setSubCompleted } from 'data/objectives';
import { dots } from './Data';
import Theme from 'style/theme';
import styled from 'styled-components';
import { bridgeIcon } from 'assets/icons';
import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';

interface DotsProps {
	top : string
	left : string
	selected: boolean
}

const Dots = styled.div<DotsProps>`
	position: relative;
	left: ${p => p.left};
	top: ${p => p.top};
	font-family: 'Rijksoverheidsans';
	height: 6%;
	width: 4%;
	background-image: url(${bridgeIcon});
	background-size: 80% 80%;
	background-position: center;
	background-repeat: no-repeat;
	background-color: ${p => p.selected? 'yellow' : 'lightgrey'  };
	border-radius: 50%;
	//border: 4px solid black;
	cursor: pointer!important;
	user-select: none;
	/* &:hover {
	background-color:  ${Theme.colors.hover_bg};
	border-color: ${Theme.colors.hover};
	} */
  `;

const BrugScherm = () => {

	const { setActiveDialogueChunk, updateObjectiveData, setActiveIntObj } = useGlobalContext();
	const [activeImage, setActiveImage ] = useState<string>(Brug1);
	const [select, setSelect] = useState<string>('AAA');

	const selectSlot = ( index, value ) => {
		setSelect(a => replaceAt(a, value, index));
	};
	
	const replaceAt = (string, replacement, index) => {
		return string.substring(0, index) + replacement + string.substring(index +1);
	};

	useEffect(() => {
		if(!Objectives[6].subObjectives[2].completed ){
			setActiveDialogueChunk && setActiveDialogueChunk('l_05_screen_03');
		}

		return () => {

			if(Objectives[6].subObjectives[0].completed == true && Objectives[6].subObjectives[1].completed == true && Objectives[6].subObjectives[2].completed == true){
				setTimeout(() => {
					setActiveIntObj && setActiveIntObj('algoritmetablet');
				}, 1);			}
		};
	},[]);

	const setDotProps = (img, index) => {
		selectSlot(0, index);
		setActiveImage(img);
		dots[0].props[index].used = true;
		if (!Objectives[6].subObjectives[2].completed && dots[0].props[0].used == true && dots[0].props[1].used == true && dots[0].props[2].used == true) {
			setSubCompleted(6,2);
			updateObjectiveData && updateObjectiveData();
			setActiveDialogueChunk && setActiveDialogueChunk('l_05_screen_031');
		}
	};

	return (
		<>
			<TabletBorder>
				<ContentWrapper img={activeImage}>
					<Content>
						{dots[0].props.map((dot, index) => (
							<Dots key={`${index}-${dot}-dot`} selected={select[0] == index.toString() ? true : false} left={dot.left} top={dot.top} onClick={() => setDotProps(dot.img, index)}/>
						))}
					</Content>			
				</ContentWrapper>
			</TabletBorder>
		</>
	);
};

export default BrugScherm;

import { ReactNode } from 'react';
import {ThemeProvider} from 'styled-components';
import { GlobalStyle, theme } from '../../style';

export interface LayoutProps {
    children: ReactNode
}

const Layout = ({children}:LayoutProps) => {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle/>
			{children}
		</ThemeProvider>
	);
};

export default Layout;
import { Kantoor_02 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Kantoor_02,
	yaw: -16.9648,
	pitch: 80.6668
};


let customHotspots  =	[
	{
		'pitch': -32,
		'yaw': 69.0399,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'tablet',
			'param': 'tutorialtablet'
		}
	
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene2 = {
	sceneId: '2',
	config: getConfig(customConfig),
};	

export default Scene2;

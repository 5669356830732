import { sceneList } from 'components/Pannellum/Scenes';
import { useGlobalContext } from 'context/global';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ConsoleRow = styled.div`
    display: flex;
    justify-content: left;
	text-align: left;
    align-items: center;
	margin-top: 10px;
    button {
        margin-left: 30px;
    }
	select {
		font-size: 24px;
	}
`;

const ConsoleLabel = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
	padding-right: 10px;
`;

const ConsoleSceneSelect = () => {
	const { setActivePannellumScene, activePannellumScene } = useGlobalContext();
	const { t } = useTranslation();

	const [consoleActiveScene, setConsoleActiveScene] = useState<number | undefined>(activePannellumScene ? activePannellumScene : undefined);
	const consoleRefActiveScene = useRef<HTMLSelectElement>(null);

	// On Change of Console Item
	useEffect(() => {

		setActivePannellumScene && setActivePannellumScene(consoleActiveScene);

	}, [consoleActiveScene]);


	// On Change of Active Pannellum Scene in context
	useEffect(() => {

		if(activePannellumScene != consoleActiveScene){
			activePannellumScene && setConsoleActiveScene(activePannellumScene);
		}

	}, [activePannellumScene]);	
	
	const setMethod = (value: string) => {
		if(value){
			setConsoleActiveScene(Number(value));
		}
		else{
			setConsoleActiveScene(undefined);
		}
	};
	
	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.2')}</ConsoleLabel>
			<select onChange={(e) =>{setMethod(e.target.value);}} ref={consoleRefActiveScene} value={consoleActiveScene}>
				<option label={'none'} value={undefined}/>
				{sceneList && sceneList.map((opt, index) => (<option key={`key-${opt}-${index + 1}`} label={String(index + 1)} value={index + 1}/>))}
			</select>
		</ConsoleRow>
	
	);
};

export default ConsoleSceneSelect;
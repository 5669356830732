import { Kaart0, Kaart1, Kaart2, Kaart3, Kaart4, vertrektijd } from 'assets/images';
import styled from 'styled-components';
import Grid from './components/Grid';
import TitleCard from './components/TitleCard';
import BottomRow from './components/BottomRow';
import ContinueButton from './components/ContinueButton';
import { useEffect, useState } from 'react';
import { useGlobalContext } from 'context/global';
import { theme } from 'style';
import { Objectives, setSubCompleted } from 'data/objectives';
import { useResponsiveFontSize } from 'react-responsive-font-size';
import { questionIcon, questionIconHover } from 'assets/icons';

const charts = [
	{
		img :Kaart1,
		answer:'1100110111111101',
		label: 'Laag wolkendek',
	},	
	{
		img :Kaart2,
		answer:'0000000110111111',
		label: 'Golfhoogte',
	},
	{
		img :Kaart3,
		answer:'1011101110101110',
		label: 'Zeevaart',
	},
	{
		img :Kaart4,
		answer:'1100110111110111',
		label: 'Weerrisico',
	},
	{
		img :Kaart0,
		answer:'0000000110100100',
	}
];

const ChartOveriew = (props : {id: number}) => {

	const {activeDialogueChunk, setCloseButtonActive, updateObjectiveData, setActiveDialogueChunk, setActivePannellumScene} = useGlobalContext();
	const responsiveFont2 = useResponsiveFontSize(0.6, { setFontSize: true, globalVariableName: 'responsiveFont2', lockFontSize: false });
	const responsiveFont3 = useResponsiveFontSize(0.6, { setFontSize: true, globalVariableName: 'responsiveFont2', lockFontSize: false });

	const chart = charts[props.id];

	//#region useStates
	const [selectedSpaces, setSelectedSpaces] = useState<string>('0000000000000000');
	const [confirmActive, setConfirmActive] = useState<boolean>(false);
	const [solved, setSolved] = useState<boolean>(false);
	const [wrong, setWrong] = useState<boolean>(false);
	const [triedOnce, setTriedOnce] = useState<boolean>(false);

	useEffect(() => {
		setCloseButtonActive && setCloseButtonActive(true);
		if(Objectives[4].subObjectives[props.id].completed){
			setSolved(true);
			setWrong(true);
			setSelectedSpaces(chart.answer);
		} 

		return () => {
			if(Objectives[4].subObjectives[0].completed == true && Objectives[4].subObjectives[1].completed == true && Objectives[4].subObjectives[2].completed == true && Objectives[4].subObjectives[3].completed == true && Objectives[4].subObjectives[4].completed != true){
				setActiveDialogueChunk && setActiveDialogueChunk('l_03_final_01');
				setActivePannellumScene && setActivePannellumScene(6);
			}
		};
	},[]);
	//#region Methods

	const toggleSpace = (index) => {
		if(!solved){
			setSelectedSpaces(a => replaceAt(a, index, a[index] === '1' ? 0 : 1));
			if(!confirmActive){
				setConfirmActive(true);
			}
		}
	};

	const checkAnswer = () => {
		//correct
		if(selectedSpaces == chart.answer){				
			setSolved(true);
			setWrong(true);
			if(props.id == 4) setActiveDialogueChunk && setActiveDialogueChunk('l_03_rightroute'); else	setActiveDialogueChunk && setActiveDialogueChunk('l_03_rightgmap');
			setSubCompleted(4, props.id);
			updateObjectiveData && updateObjectiveData();	
		}	

		//wrong		
		else{
			//automatic correct when answered wrong for second time
			if(triedOnce){
				setActiveDialogueChunk && setActiveDialogueChunk(`l_03_answer_0${props.id}`);
				setSolved(true);
				setWrong(true);
				setSelectedSpaces(chart.answer);
				setSubCompleted(4, props.id); 
				if(props.id == 4 ) setSubCompleted(0, 3);
				updateObjectiveData && updateObjectiveData();
			} else {
				setActiveDialogueChunk && setActiveDialogueChunk(`l_03_wrongmap_0${props.id}`);
				setTriedOnce(true);	
				setWrong(true);
				setTimeout(() => {
					setSelectedSpaces('0000000000000000');
					setWrong(false);
				}, 2000);
			}
		}
	};

	const openCriteria = () => {
		setActiveDialogueChunk && setActiveDialogueChunk(`l_03_criteria_0${props.id}`);
	};

	//#endregion

	//#region Render

	return (
		<PaddingWrapper style={{backgroundImage: `url("${chart.img}")`}}>
			{props.id === 4 ? 
				<TitleCards>
					<TitleCard height='15%' style={{backgroundImage: `url("${vertrektijd}")`}} left={'0'}></TitleCard>
					<TitleCard2 right={'0'}>Aankomsttijd Nederland <div>{solved ? '08:30' : '...?'}</div>
						{!solved && !wrong &&
							<ContinueButton2 ref={responsiveFont2} style={{}} onClick={(e) => {checkAnswer(); e.currentTarget.blur();}}>bereken</ContinueButton2>
						}
					</TitleCard2>
				</TitleCards>
				:
				<TitleCards>
					<TitleCard height='10%' ref={responsiveFont3} right={'0'}>{chart.label} { !solved && <InfoButton onClick={() => openCriteria()}/>}</TitleCard>
				</TitleCards>
			}
			<Grid
				onSelectSpace={(index) => toggleSpace(index)}
				selectedSpaces={selectedSpaces}
				wrong={wrong}
				answer={chart.answer}
			/>

			{ props.id === 4 ? 				
				<BottomRow leave={solved}/> 
				: <>{ !solved && <ContinueButton ref={responsiveFont2} onClick={() => checkAnswer()} bottom={'0%'}>Indienen</ContinueButton>}</>
			}
		</PaddingWrapper>
	);

	//#endregion

};

//#region Styled Components
const PaddingWrapper = styled.div`

	width: 100%;
	height: 100%;
	
    background-color: white;
    border-radius: 0% 1% 1% 0%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

	overflow: hidden;
`;
type TitleCard2Props = {
	left?: string;
	right?: string;
}
const ContinueButton2 = styled.button`

	position: relative;

	//padding: 1%;
	color: ${theme.colors.accent_2};
	background-color: ${theme.colors.primary};
	width: 60%;
	height: 50%;
	/* pointer-events: all;
	user-select: auto; */

	font-weight: bold;
	cursor: pointer;

	border: none;

	box-shadow: 0px 2px 10px grey;

	&:hover, :focus {
		background-color: ${theme.colors.hover_bg};
		color: ${theme.colors.hover};
		border: none;
		outline: none;
	}

`;
const TitleCard2 = styled.button<TitleCard2Props>`

	position: absolute;
	margin:2%;
	left: ${p => p.left ? p.left : ''};
	right: ${p => p.right ? p.right : ''};

	//padding: 10px;
	color: ${theme.colors.accent_2};
	background-color: ${theme.colors.accent_1};
	width: 25%;
	height: 15%;

	font-weight: bold;
font-size: 2vh;
	user-select: none;
	border: none;
	& > div {
		color: black;
		font-weight: normal;
		
		text-align: center;
	}
`;
const TitleCards = styled.div`
	position: relative;
	height: 70%;
	width: 100%;
`;

const InfoButton = styled.button`

	border: none;
	background: white;
	width: 15%;
	height: 100%;
	color: ${theme.colors.accent_2};
	font-weight: bold;
	background-image: url(${questionIcon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 80% 80%;
	position: absolute;
	left:0;
	top:0;

	transform: translateX(-125%);

	box-shadow: 0 0 10px grey;

	&:hover, :focus {
		background-image: url(${questionIconHover});
		background-color: ${theme.colors.hover_bg};
		color: ${theme.colors.hover};
		border:none;
		outline:none;
	}

`;

//#endregion

//#region Helper Functions

const replaceAt = (string, index, replacement) => {
	return string.substring(0, index) + replacement + string.substring(index + 1);
};

//#endregion

export default ChartOveriew;

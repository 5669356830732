//#region Data

import { background_highway, background_nearwater, background_office, background_sea } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import { useGlobalContext } from 'context/global';
import { useEffect } from 'react';


//#endregion

const SoundManager = () => {
	const { activeLevel, levelSelectActive } = useGlobalContext();
	const { playBackgroundSound } = useAudioContext();

	useEffect(() => {
		if (!levelSelectActive) {
			switch(activeLevel){
			case 0:
				playBackgroundSound && playBackgroundSound(true, background_office);
				break;
			case 1:
				playBackgroundSound && playBackgroundSound(true, background_nearwater);
				break;
			case 2:
				playBackgroundSound && playBackgroundSound(true, background_office);
				break;
			case 3:
				playBackgroundSound && playBackgroundSound(true, background_sea);
				break;
			case 4:
				playBackgroundSound && playBackgroundSound(true, background_highway);
				break;
			case 5:
				playBackgroundSound && playBackgroundSound(true, background_nearwater);
				break;
			case 6:
				playBackgroundSound && playBackgroundSound(true, background_office);
				break;
			default:playBackgroundSound && playBackgroundSound(false);
			}
		}
		else {
			playBackgroundSound && playBackgroundSound(false);
		}
	}, [activeLevel, levelSelectActive]);
	
	

	return (	
		<> 		
		</>
	);

};

//#region Styled Components

//#endregion

//#region Helper Functions


//#endregion

export default SoundManager;

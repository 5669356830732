import { theme } from 'style';
import styled from 'styled-components';

type TitleCardProps = {
	left?: string;
	right?: string;
	bottom?: string;
	top?: string;
	height: string;
}

const TitleCard = styled.button<TitleCardProps>`
	background-repeat: no-repeat;
	background-size: 90% 90%;
	background-position: center;
	position: absolute;
	margin:2%;
	left: ${p => p.left ? p.left : ''};
	right: ${p => p.right ? p.right : ''};
	bottom: ${p => p.bottom ? p.bottom : ''};
	top: ${p => p.top ? p.top : ''};

	//padding: 10px;
	color: ${theme.colors.accent_2};
	background-color: ${theme.colors.accent_1};
	width: 22%;
	height: ${p => p.height ? p.height : ''};;

	font-weight: bold;

	user-select: none;
border: none;
	& > div {
		color: black;
		font-weight: normal;
		padding-top: 10px;
		text-align: center;
	}
`;

export default TitleCard;
import { useGlobalContext } from 'context/global';
import { useEffect, useRef, useState } from 'react';
import PubSub from 'pubsub-js';
import { setSubCompleted } from 'data/objectives';
import { act } from 'react-dom/test-utils';


const Tutorial = () => {

	const subscribeToken = useRef();

	const {setTutorialActive, setCloseButtonActive ,setObjectivesHidden, activeDialogueChunk, objectivesHidden, activeLevel, levelSelectActive, setActiveDialogueChunk, setActivePannellumScene, activePannellumScene, activeIntObj, updateObjectiveData} = useGlobalContext();

	const [tutorial, setTutorial] = useState<boolean>(false);
	const [tutorialStep, setTutorialStep] = useState<number>(0);

	const el = activePannellumScene ? document.getElementById(activePannellumScene.toString()) : null;


	useEffect(() => {
		if(activeLevel === 0 && !tutorial && !levelSelectActive){
			setTutorial(true);
			setTutorialActive && setTutorialActive(true);
			setTutorialStep(1);
			setObjectivesHidden && setObjectivesHidden(false);
		}
		if(tutorial && levelSelectActive && tutorialStep === 7){
			next();
		}
	}, [activeLevel, levelSelectActive]);


	useEffect(() => {
		if(tutorialStep === 4){
			if(activeIntObj != ''){
				next();
			}
		}
		if(tutorialStep === 6){
			if(!activeIntObj){
				next();
			}
		}
	}, [activeIntObj]);

	useEffect(() => {
		if(objectivesHidden && tutorialStep === 3){
			setTutorialStep(4);
			setSubCompleted(1, 0); 

		}
	},[objectivesHidden]);

	useEffect(() => {
		if(activeDialogueChunk === 'l_00_tutorial_03' && tutorialStep === 1){
			next();
		}
	}, [activeDialogueChunk]);

	const lookedAround = () => {
		setActiveDialogueChunk && setActiveDialogueChunk('l_00_tutorial_02_next');
	};

	useEffect(() => {
		switch(tutorialStep){
		case 1 :
			el?.addEventListener('mouseup', lookedAround, {once:true});
			el?.addEventListener('touchmove', lookedAround, {once:true});
			break;
		//  Looked around	
		case 2 :
			setActivePannellumScene && setActivePannellumScene(14);
			subscribeToken.current = PubSub.subscribe( 'navigate', () => {next();});
			break;
		//  Navigated	
		case 3 :
			PubSub.unsubscribe(subscribeToken.current);
			setActiveDialogueChunk && setActiveDialogueChunk('l_00_tutorial_04');

			break;
		// Clicked objectives away
		case 4 :
			setActiveDialogueChunk && setActiveDialogueChunk('l_00_tutorial_06');	
			setActivePannellumScene && setActivePannellumScene(2);

			break;
		// Opened model
		case 5 :
			subscribeToken.current = PubSub.subscribe( 'tutorialtabletclick', () => {next(); });
			setActiveDialogueChunk && setActiveDialogueChunk('l_00_tutorial_07');	

			break;
			// Interacted with model
		case 6 :
			PubSub.unsubscribe(subscribeToken.current);
			setActiveDialogueChunk && setActiveDialogueChunk('l_00_tutorial_08');	
			setCloseButtonActive && setCloseButtonActive(true);
			break;

			// Closed model	
		case 7 :
			setActiveDialogueChunk && setActiveDialogueChunk('l_00_tutorial_09');
			setSubCompleted(1, 1); 
			setSubCompleted(0, 0); 
			setTutorialActive && setTutorialActive(false);
			updateObjectiveData && updateObjectiveData();
			break;
		case 8 : 
			setActiveDialogueChunk && setActiveDialogueChunk('l_00_completed_01');

			// setTimeout(() => {
			// }, 200);
			setTutorial(false);

			break;
		}
	},[tutorialStep]);

	const next = () => {
		setTutorialStep(i => {return i + 1;});
	};

	return (
		<>
			{/* {tutorial ? 'tutorial active' : 'tutorial inactive'} */}
		</>
	);
};

export default Tutorial;



import { useGlobalContext } from 'context/global';
import { Objectives, setSubCompleted } from 'data/objectives';
import data from 'data/vop';
import { useEffect, useState } from 'react';
import VOPinterface from './VOPinterface';

interface VOPProps {
    id: number; // Bepaald welke data we gebruiken
}
 
const VOP = (props: VOPProps) => {

	const VOPData = data[props.id];
	const [step, setStep] = useState<number>(-1);

	const {updateObjectiveData, setActiveIntObj, setCloseButtonActive, setActivePannellumScene} = useGlobalContext();

	const onComplete = () => {
		// setActiveDialogueChunk && setActiveDialogueChunk('l_06_monitor1_01');
		setActiveIntObj && setActiveIntObj('');
		setCloseButtonActive && setCloseButtonActive(true);
		setSubCompleted(7, props.id);
		updateObjectiveData && updateObjectiveData();
	};

	useEffect(() => {
		if(Objectives[7].subObjectives[props.id].completed){
			setStep(VOPData.steps.length - 1);
		}
		return (() => {
			if(Objectives[7].subObjectives[0].completed && Objectives[7].subObjectives[1].completed && Objectives[7].subObjectives[2].completed ){
				setActivePannellumScene && setActivePannellumScene(19);
			}
		});	
	},[]);

	return ( 
		<>
			<VOPinterface
				setStep={(number) => setStep(number)}
				step={step}
				onComplete={() => onComplete()}
				data={VOPData}
				id={props.id}
			/>
		</>
	);
};
 
export default VOP;
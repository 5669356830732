
import Close from 'components/Buttons/CloseButton';
import { useGlobalContext } from 'context/global';
import { Suspense, useEffect } from 'react';
import { getObjectById } from './Objects';

const InteractiveObject = () => {

	const { activeIntObj } = useGlobalContext();
	const ObjectComponent = getObjectById(activeIntObj);

	return (
		<Suspense fallback={true}>
			{ ObjectComponent && 
				<ObjectComponent/>
										
			}
		</Suspense>
	);

};


export default InteractiveObject;

import Scene1 from './scene1';
import Scene2 from './scene2';
import Scene3 from './scene3';
import Scene4 from './scene4';
import Scene5 from './scene5';
import Scene6 from './scene6';
import Scene7 from './scene7';
import Scene8 from './scene8';
import Scene9 from './scene9';
import Scene10 from './scene10';
import Scene11 from './scene11';
import Scene11filter from './scene11filter';
import Scene11filterP2 from './scene11filterP2';
import Scene12 from './scene12';
import Scene13 from './scene13';
import Scene14 from './scene14';
import Scene15 from './scene15';
import Scene16 from './scene16';
import Scene9_1 from './scene9.1';
import Scene9_2 from './scene9.2';
import Scene2_2 from './scene2.2';





// list of the scenes
const sceneList = [
	{...Scene1},{...Scene2},{...Scene3},{...Scene4},
	{...Scene5},{...Scene6},{...Scene7},{...Scene8},
	{...Scene9},{...Scene10},{...Scene11},
	{...Scene12},{...Scene13},{...Scene14},
	{...Scene15},{...Scene11filter},{...Scene16},{...Scene11filterP2},{...Scene9_1}, {...Scene9_2}, {...Scene2_2}
	
];

export {sceneList};

export {
	Scene1, Scene2,
	Scene3, Scene4,
	Scene5, Scene6,
	Scene7, Scene8,	
	Scene9, Scene10,
	Scene11, 
	Scene12, Scene16,
	Scene13, Scene14,
	
};
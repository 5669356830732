import { useEffect, useState } from 'react';
import { theme } from 'style';
import i18next from 'i18next';

import styled from 'styled-components';

interface Size {
    width: number;
    height: number;
}

const FullScreenMessage = styled.div`

    position: absolute;
    left:0;
	top:0;
	bottom:0;
	right:0;

    background: ${p => p.theme.colors.accent_2};
    z-index: 9001;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 3rem;
    text-align: center;
`;

const ForceLandscape = () => {

	const [size, setSize] = useState<Size>({width: window.innerWidth, height: window.innerHeight});

	const render = size.width < 600 && size.width < size.height;

	useEffect(() => {
		window.onresize = resizeHandler;
	},[]);

	const resizeHandler = () => {
		setSize({height: window.innerHeight, width: window.innerWidth});
	};

	return (
		<>
			{ render &&
                <FullScreenMessage>Turn your device horizontally to play</FullScreenMessage>
			}
		</>
	);
};

export default ForceLandscape;
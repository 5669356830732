import { CloseButton } from 'components/Buttons/Button';
import Close from 'components/Buttons/CloseButton';
import { useGlobalContext } from 'context/global';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

 
const StartingVideo = () => {

	const [activeVideo, setActiveVideo] = useState<string>('');
	const [used, setUsed] = useState<boolean[]>([false, false]);
	const {levelSelectActive ,activeDialogueChunk, setActiveDialogueChunk, activeLevel} = useGlobalContext();


	useEffect(()=>{
		if(!activeDialogueChunk && !levelSelectActive){
			switch(activeLevel){
			//sluis video
			case 1:
				if(used[0] == false){
					setActiveVideo('659594705');
					setUsed([true, used[1]]);
				}
				break;
			//vaarweg video 	
			case 5:
				if(used[1] == false){
					setActiveVideo('659594053');
					setUsed([used[0], true]);
				}
				break;
			}
			
		}
	},[activeDialogueChunk]);



	return ( <>
		{activeVideo && <Container>
			<Content>
				<iframe src={'https://player.vimeo.com/video/'+activeVideo}  width="100%" height="100%" frameBorder="0" allow="autoplay" allowFullScreen></iframe>
			</Content>
			<CloseButton onClick={() => setActiveVideo('')}/>
		</Container>}
	</> 
	);
};

//#region Styled Components

const ContainerKF = keyframes`
    0% {
        opacity: 0;
        pointer-events: none;
    }
    99%{
        pointer-events: none;
    }
    100% {
        opacity: 1;
        pointer-events: all;
    }
`;

const Container = styled.div`
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom: 0;

    background: rgba(0,0,0,0.5);
    text-align: center;
    /* margin-left: 130px;
    margin-right: 130px; */


    @media ${p => p.theme.devices.mobile} {
		padding: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		padding: 30px;
	};

    z-index: 6;

    transition: opacity 1s;
    animation: ${ContainerKF} 1s;
`;



const Content = styled.div`

    margin-left: 130px;
    margin-right: 130px;   
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    height: 100%;

    & > iframe {
        /* background: rgba(0,0,0,0.5); */
        /* margin-top: 15px; */
    }

`;

//#endregion
 
export default StartingVideo;
import {  useEffect } from 'react';
import { useGlobalContext } from 'context/global';
import ChartOveriew from './ChartOverview';
import { Objectives } from 'data/objectives';
import ZeeschermBorder from 'components/UniversalComponent/Borders/zeeschermborder';

const ZeeScherm4 = () => {

	const { setActiveDialogueChunk } = useGlobalContext();

	useEffect(() => {
		if(!Objectives[4].subObjectives[3].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_03_weerrisico_01');
		}
	},[]);

	return (
		<>
			<ZeeschermBorder>
				<ChartOveriew id={3}/>
			</ZeeschermBorder>
		</>
	);
};

export default ZeeScherm4;
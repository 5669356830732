import * as THREE from 'three';
import React, { useEffect, useRef, useState, } from 'react';
import { useGLTF, Html} from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { Tablet_m } from 'assets/models';
import { useThreeContext } from 'context/three';
import styled from 'styled-components';
import PubSub from 'pubsub-js';
import { Objectives, setSubCompleted } from 'data/objectives';
import { useGlobalContext } from 'context/global';


const ContentWrapper = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;

	justify-content: center;

	width: 1650px;
	height: 980px;

	padding: 15px;
	user-select: none;
	border-radius: 25px;

	font-size: 4rem;
`;

const Content = styled.div`
	width: 100%;
	height: 100%;
`;

type GLTFResult = GLTF & {
  nodes: {
    Tablet: THREE.Mesh
  }
  materials: {
    material: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(Tablet_m) as GLTFResult;
	const { setControlsActive } = useThreeContext();
	const { updateObjectiveData, setActiveDialogueChunk } = useGlobalContext();

	useEffect(() => {

		setControlsActive && setControlsActive(false);

		if(!Objectives[3].subObjectives[2].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('wip');
		}

		setSubCompleted(3,2);
		updateObjectiveData && updateObjectiveData();
		
		setTimeout(() => {
			setDistanceFactor(1);
		}, 1);


	},[]);

	const [ activeImage, setActiveImage ] = useState<string>('');
	const [distanceFactor,setDistanceFactor] = useState<number>(0);


	return (
		<>
			<group scale={14} ref={group} {...props} dispose={null}>
				<mesh geometry={nodes.Tablet.geometry} material={materials.material} />
			</group>
			<Html					
				as='div'
				distanceFactor={distanceFactor}
				transform
				occlude
				position={[0,0,0.07]}
				scale={1}			
			>
				<ContentWrapper>
					<Content>
						Minigame is nog niet compleet.
					</Content>										
				</ContentWrapper>
			</Html>
		</>
	);
}

useGLTF.preload(Tablet_m);

import {  useEffect } from 'react';
import {  useGlobalContext } from 'context/global';
import ChartOveriew from './ChartOverview';
import { Objectives } from 'data/objectives';
import ZeeschermBorder from 'components/UniversalComponent/Borders/zeeschermborder';

const Zeescherm3 = () => {
	const { setActiveDialogueChunk } = useGlobalContext();

	useEffect(() => {
		if(!Objectives[4].subObjectives[2].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_03_vaarroutes_01');
		}
	},[]);

	return (
		<>
			<ZeeschermBorder>
				<ChartOveriew id={2}/>
			</ZeeschermBorder>
		</>
	);
};

export default Zeescherm3;

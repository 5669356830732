import { avatars } from 'data/avatars';
import { DialogueChunk } from 'data/dialogues';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import { ContinueDialogueButton, LevelStartButton, LevelStartButtonSpace } from './Buttons';

const TextboxContainer = styled.div`
	display:flex;
	flex-direction: column;
`;

const Name =  styled.div`
	font-weight: bold;
	color: ${props => props.theme.colors.accent_2};
`;

const Text = styled.div`
	padding-top: 0.5rem;
	font-style: italic;
	font-size: 1.2rem;
	padding-right: 20px;
`;

interface TextboxProps {
    chunk: DialogueChunk;
	proceed: () => void;
}

const Textbox = (props: TextboxProps) => {
	return (
		<TextboxContainer>
			{ props.chunk.avatarId != null && 
				<Name>{avatars[props.chunk.avatarId].name}</Name>
			}
			<Text dangerouslySetInnerHTML={{__html: props.chunk.text}}>
				
			</Text>
			{ props.chunk.levelSelectChunk && <LevelStartButtonSpace/> 

			}

		</TextboxContainer>
	);
};


export default Textbox;
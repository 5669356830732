/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { BootMonitor } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    Cube015: THREE.Mesh
    Cube015_1: THREE.Mesh
  }
  materials: {
    Plastic: THREE.MeshStandardMaterial
    Screen: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(BootMonitor) as GLTFResult;
	return (
		<group ref={group} {...props} dispose={null}>
			<group scale={2}>
				<mesh geometry={nodes.Cube015.geometry} material={materials.Plastic} />
				<mesh geometry={nodes.Cube015_1.geometry} material={materials.Screen} />
			</group>
		</group>
	);
}

useGLTF.preload(BootMonitor);

import { schip1, schip2 } from 'assets/images';
import { useGlobalContext } from 'context/global';
import { setSubCompleted } from 'data/objectives';
import { useState } from 'react';
import { algorithm, algorithm2, answers, answers2 } from '../Data';
import { Box, ButtonRow, Container, Container2, ExpressionContainer, ExpressionRow, FlexBox, Go, HelpImg, PlaceHolderBox, Reset, ResultBox, ResultContainer, ResultRow, Row, Title } from './components/components';

const Assignment = (props: {questionState: number, setQuestionState: (val: number) => void}) => {

	const { setActiveDialogueChunk,updateObjectiveData, setCloseButtonActive } = useGlobalContext();
	const [answer, setAnswer] = useState<string>('AAAAA');
	const [activeSlot, setActiveSlot] = useState<number>(0);
	const [wrongResult, setWrongResult] = useState<boolean>(false);
	const [triedOnce, setTriedOnce] = useState<boolean>(false);
	const [gameHelp, setGameHelp] = useState<boolean>(false);

	const array = props.questionState === 1 ? answers : answers2;
	const data = props.questionState === 1 ? algorithm : algorithm2;

	const checkIfCorrect = () => {
		if(!wrongResult){
			if(array.includes(answer)){
			//player correct part 1
				if(props.questionState === 1){
					props.setQuestionState(2);
					setAnswer('AAAAA');
					setActiveSlot(0);
					if(!gameHelp) setActiveDialogueChunk && setActiveDialogueChunk('goed_antwoord_deel_1'); else setActiveDialogueChunk && setActiveDialogueChunk('goed2_antwoord_deel_1');
					setTriedOnce(false);
				//player correct part 2
				} else {
					props.setQuestionState(3);
					setActiveDialogueChunk && setActiveDialogueChunk('goed_antwoord_deel_2');	
					setCloseButtonActive && setCloseButtonActive(true);
					setTimeout(() => {
						setSubCompleted(6,3);
						setSubCompleted(0,4);				
						updateObjectiveData && updateObjectiveData();
					}, 500);
				}
			} else {
			//wrong answer second time, computer sets correct
				if(triedOnce){
					setActiveDialogueChunk && setActiveDialogueChunk(`l_05_answer_0${props.questionState}`);	
					setAnswer(props.questionState === 1 ? '01236' : '05632');				
					setGameHelp(true);
					//wrong answer first time	
				} else {
					setActiveDialogueChunk && setActiveDialogueChunk(`fout_antwoord_deel_${props.questionState}`);	
					setWrongResult(true);
					setTriedOnce(true);	
				}
			}
		}
	};

	const selectSlot = ( index, value ) => {
		setAnswer(a => replaceAt(a, value, index));
	};
	
	const replaceAt = (string, replacement, index) => {
		return string.substring(0, index) + replacement + string.substring(index +1);
	};

	const onBoxClick  = (index) => {
		if(!array.includes(answer)){
			selectSlot(activeSlot, index);
			setActiveSlot(activeSlot+1);
		}
	};
	const resetWhenWrong = () => {
		setAnswer('AAAAA');
		setWrongResult(false);
		setActiveSlot(0);
	};

	return (
		<>					
			<Container>
				<HelpImg img={props.questionState === 1 ? schip1 : schip2}/>
			</Container>
			<Container2>
				{props.questionState === 1 ? 
					<Title><u>De waterstand</u> moet <u>hoger</u> zijn dan de <u>diepte van de vaargeul</u> <u>min</u> de <u>dieptegang van de boot</u>.</Title>
					:
					<Title><u>De waterstand</u> moet <u>lager</u> zijn dan de <u>hoogte van de brug</u> <u>min</u> de <u>hoogte van de bootligging</u></Title>}
				<ExpressionContainer>
					<b>Waarde-blokken</b>					
					<ExpressionRow>				
						<FlexBox>
							{data.map((option, index) => (
								<Box selected={array[activeSlot] == index.toString() ? true : false} onClick={() => onBoxClick(index)} key={`${index}-${option}-option`}>
									{option.text}
								</Box>
							))}
						</FlexBox>
					</ExpressionRow>
				</ExpressionContainer>					
				<ResultContainer>
					<b>Algoritme</b>
					<ResultRow>					
						{wrongResult ? 						
							<Row>
							Fout resultaat
							</Row>
							:
							<>
								<Row>{data[answer[0]]? <ResultBox>{data[answer[0]].text}</ResultBox>: <PlaceHolderBox bc={activeSlot == 0}>...</PlaceHolderBox>}
									{data[answer[1]]? <ResultBox>{data[answer[1]].text}</ResultBox>: <PlaceHolderBox bc={activeSlot == 1}>...</PlaceHolderBox>}
									{data[answer[2]]? <ResultBox>{data[answer[2]].text}</ResultBox>: <PlaceHolderBox bc={activeSlot == 2}>...</PlaceHolderBox>}</Row>
								<Row>{data[answer[3]]? <ResultBox>{data[answer[3]].text}</ResultBox>: <PlaceHolderBox bc={activeSlot == 3}>...</PlaceHolderBox>}
									{data[answer[4]]? <ResultBox>{data[answer[4]].text}</ResultBox>: <PlaceHolderBox bc={activeSlot == 4}>...</PlaceHolderBox>}</Row>
							</>}
					</ResultRow>					
					<ButtonRow>
						<Go onClick={checkIfCorrect}>Go</Go>			
						<Reset onClick={resetWhenWrong}></Reset>
					</ButtonRow>
				</ResultContainer>
			</Container2>
		</>);
};

export default Assignment;

import { useGlobalContext } from 'context/global';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsoleLabel, ConsoleRow } from './Styles';
import { checkObjective, Objectives, setSubCompleted } from 'data/objectives';

const ObjectivesSelect = () => {

	const { t } = useTranslation();

	const selectTabRef = useRef<HTMLSelectElement>(null);
	const { activeObjective, setActiveObjective, activeObjectiveData, setActiveObjectiveData, updateObjectiveData} = useGlobalContext();

	return(
		<ConsoleRow>
			<ConsoleLabel>{'Objective'}</ConsoleLabel>
			<select onChange={(e) => setActiveObjective && setActiveObjective(Number(e.target.value))} ref={selectTabRef} value={activeObjective} >
				<option label={'none'} value={0}/>
				{Objectives && Objectives.map((opt, index) => (<option key={index} label={String(index + 1)} value={index + 1}/>))}
			</select>		
			{/* <button onClick={setMainCompleted}>Toggle completed: Main</button> */}
			{ activeObjective && Objectives[activeObjective-1].subObjectives.map( (objective, index ) => {
				return(
					<button onClick={() => {setSubCompleted(activeObjective - 1, index); updateObjectiveData && updateObjectiveData(); } } key={index}>set completed: {objective.text}</button>
				);
			})
			}
			<button onClick={() => {console.log(Objectives);}}>log</button>
		</ConsoleRow>
	);
};

export default ObjectivesSelect;
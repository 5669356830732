import { useResponsiveFontSize } from 'react-responsive-font-size';
import Theme from 'style/theme';
import styled from 'styled-components';
import { analysis, media, questions } from '../Data';

const Row = styled.div`

	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
`;
const Label = styled.div`
    color: white;
    font-weight: bold;

    font-size: 3vh;
	height: 40%;
	text-align: center;

	margin-bottom: 5%;
`;

const OutputRow = styled.div`
	//background-color: green; 

	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 80%;
	
`;
interface OutputBoxProps {
	img : string
}

const OutputBox = styled.div<OutputBoxProps>`
    background-image: url(${p => p.img});
    background-repeat: no-repeat;
	background-position: center; 
	background-size: 100% 100%;
	width: 100%;
	height: 100%;
`;

interface LockProps {
	selected : boolean
	default: boolean
}

const Box = styled.button<LockProps>`
    background-color: white;
    height: 100%;
	text-align:center;
	opacity:${p => p.default? 1 : p.selected? 1 : 0.5};
    outline: ${p => p.selected? '3px solid' + Theme.colors.accent_2 : ''};
	border:none;	
	font-size: 3vh;
	padding: 3%;
	color: ${Theme.colors.accent_2};
	font-weight: bold;
    cursor: pointer!important;
	&:not(:first-child){
		margin-left: 5%;
		}
`;

interface OutputProps {
    answer: string;
	activeQuestion: number;
    activeRow: number;
    activeAnswer: string;
    selectGridSlot: (ind: number, val: number) => void;
}

const Output = (props: OutputProps) => {
	const responsiveFont = useResponsiveFontSize(0.4, { setFontSize: true, globalVariableName: 'responsiveFont2', lockFontSize: false });

	return(
		<OutputRow>	
			{ props.activeRow == 1 && <>
				<Label >Kies een trefwoord voor de text mining.</Label>
				<Row>						
					{questions[props.activeQuestion].keywords.map((option, index) => (
						<Box default={props.answer[0] == 'A' ? true : false} selected={props.answer[0] == index.toString() ? true : false}  onClick={() => props.selectGridSlot(0, index)} key={`${index}-${option}-option`}>
							{option.text}
						</Box>
					))}
				</Row>
			</>}
			{/* { props.activeRow == 2 && <>
				<Label>Kies een media voor de text mining:</Label>	
				<Row>				
					{ media.map((option, index) => (
						<Box default={props.answer[1] == 'A' ? true : false} selected={props.answer[1] == index.toString() ? true : false} onClick={() => props.selectGridSlot(1, index)} key={`${index}-${option}-option`}>
							{option.text}
						</Box>
					))}
				</Row>
			</>} */}
			{ props.activeRow == 2 && <>
				<Label>Kies een analyse voor de text mining:</Label>
				<Row>
					{ analysis.map((option, index) => (
						<Box default={props.answer[1] == 'A' ? true : false} selected={props.answer[1] == index.toString() ? true : false} onClick={() => props.selectGridSlot(1, index)} key={`${index}-${option}-option`}>
							{option.text}
						</Box>
					))}
				</Row>
			</>}
			{ props.activeRow == 3 &&
			<>
			</>
			// <Row>
			// 	<OutputBox 
			// 		img={props.activeAnswer}>					
			// 	</OutputBox>
			// </Row>
			}
		</OutputRow>
	);
};

export default Output;
import { Plane } from '@react-three/drei';
import { useLoader, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { PosterIMG } from 'assets/images';
import { useEffect } from 'react';
import { Objectives, setSubCompleted } from 'data/objectives';
import { useGlobalContext } from 'context/global';



const Poster = () => {

	const texture = useLoader(THREE.TextureLoader, PosterIMG);
	
	const {setActiveDialogueChunk, updateObjectiveData, setDisplayEnd} = useGlobalContext();

	useEffect(() => {
		if(!Objectives[7].subObjectives[3].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_06_eind_01');
			setTimeout(() => {
				setSubCompleted(7, 3);
				setSubCompleted(0,6);
				updateObjectiveData && updateObjectiveData();
			}, 100);
		}

		return () => {
			setDisplayEnd && setDisplayEnd(true);
			//setActiveDialogueChunk && setActiveDialogueChunk('blockinput');
		};
	},[]);

	return (  <>
		<group scale={1.5}>
			<mesh>
				<planeBufferGeometry attach="geometry" args={[3.5, 2.48]} />
				<meshStandardMaterial attach="material" map={texture} side={THREE.DoubleSide}/>
			</mesh>
			<mesh position={[0,0,-0.01]}>
				<planeBufferGeometry attach="geometry" args={[3.5, 2.48]} />
				<meshBasicMaterial attach="material" color={'white'} side={THREE.DoubleSide}/>
			</mesh>
		</group>

	</>
	);
};
 
export default Poster;
import { Textmining1, Textmining2, Textmining3, Textmining4 } from 'assets/images';

const questions = [
	{
		question: 'Ik wil graag weten wat de burgers vinden van het incident.',
		keywords:[
			{
				text:'Sluis',
				selected: false
			},
			{
				text:'Herrie',
				selected: false
			},
			{
				text:'Belasting',
				selected: false
			},
			{
				text:'Vissen',
				selected: false
			},
		],
		correct: '00',
		img: Textmining1
	},
	{
		question: 'Welke zorgen uitten burgers over flora en fauna?',
		keywords:[
			{
				text:'Geld',
				selected: false
			},
			{
				text:'Belasting',
				selected: false
			},
			{
				text:'Milieu',
				selected: false
			},
			{
				text:'vervuiling',
				selected: false
			},
		],
		correct: '21'	,
		img: Textmining2

	},
	{
		question: 'Waar hebben de burgers het over op het gebied van financiën?',
		keywords:[
			{
				text:'Herrie',
				selected: false
			},
			{
				text:'Kosten',
				selected: false
			},
			{
				text:'Wegversperring',
				selected: false
			},
			{
				text:'Vervuiling',
				selected: false
			},
		],
		correct: '11',
		img: Textmining3		

	},
	{
		question: 'Hoe ervaren de burgers de hinder tijdens dit incident? Hebben ze begrip voor de situatie of zijn ze ongeduldig?',
		keywords:[
			{
				text:'Wegversperring',
				selected: false
			},
			{
				text:'Ongeluk',
				selected: false
			},
			{
				text:'Overlast',
				selected: false
			},
			{
				text:'Werkzaamheden',
				selected: false
			}
		],	
		correct: '20',
		img: Textmining4		
		
	},
];

const media = [
	{
		text: 'twitter',
		selected: false
	},
	{
		text: 'enquetes',
		selected: false
	},
	{
		text: 'e-mails',
		selected: false
	}
];

const analysis = [
	{
		text: 'Sentiment analyse',
		selected: false
	},
	{
		text: 'Context analyse',
		selected: false
	},

];

export {questions, media, analysis};
import { Brug2, Brug3, Brug4, Water2, Water3, Water4 } from 'assets/images';

const dots = [
	{
		props: [    		
			{
				used: false,
				selected: false,
				left: '6%',
				top: '45.5%',
				img:Brug2,
			},   		
			{
				used: false,
				selected: false,
				left: '60%',
				top: '31%',
				img:Brug3,
			},    		
			{
				used: false,
				selected: false,
				left: '86.5%',
				top: '14%',
				img:Brug4,
			},
		],	
	},
	{
		props: [    		
			{
				used: false,
				selected: false,
				left: '3%',
				top: '48%',
				img: Water4,
			},
			{
				used: false,
				selected: false,
				left: '50%',
				top: '24%',
				img: Water2,
			},
			{
				used: false,
				selected: false,
				left: '90%',
				top: '30%',
				img: Water3,
			},	

		],	
	},
];


export {dots};
import { s_testbackground } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import { useGlobalContext } from 'context/global/index';
import React, {useEffect} from 'react';
//import {loadScene} from 'react-pannellum';

const Input = () => {
	
	useEffect(() => {
		document.addEventListener('keyup', keyDown );
		
		return () => {
			document.removeEventListener('keyup', keyDown);
		};
	}, []);

	const {onToggleDisplayConsole} = useGlobalContext();
	const {playSound, playBackgroundSound} = useAudioContext();

	function keyDown(e){
		switch(e.keyCode){

		// Toggle Console Display 
		// F key
		case 70:
			// Toggle displayConsole when pressing F
			onToggleDisplayConsole && onToggleDisplayConsole();
			break;

		case 32:
			// playSound && playSound(s_testjibber);
			break;
		case 67:
			// playBackgroundSound && playBackgroundSound(true, s_testjibber);
			break;

		case 86:
			// playBackgroundSound && playBackgroundSound(true);
			break;
		}	

	}

	return (
		<></>
	);
};

    
export default Input;
import { sluisMelding } from 'assets/images';

const messages = [
	{
		title: 'Tablet en monitoren', 
		text: ' In de game kom je verschillende objecten tegen. Als speler kun je hier interactie mee hebben.'
	},
	{
		title: 'Spoed! Ongeluk met sluis.',
		img: sluisMelding
	},{
		title: 'Spoed! Ongeluk met sluis.',
		text: 'Vanochtend is een vrachtschip tegen een belangrijke schutsluis in Friesland gevaren.' 
	},
	{
		title: 'Spoed! Ongeluk met sluis.',
		text:'De schade moet met spoed in kaart worden gebracht. Het vaarverkeer en het milieu lopen gevaar!'
	}
];

export {messages};
import Ball from './Ball';
import Suitcase from './Suitcase';
import WaterDroneTablet from './Level1/Objects/WaterDroneTablet';
import LuchtDroneTablet from './Level1/Objects/LuchtDroneTablet';
import VideoMonitor from './Level2/Monitors/VideoMonitor';
import TextMiningMonitor from './Level2/Monitors/TextMiningMonitor';
import TutorialTablet from './Tutorial/TutorialTablet';
import PlaceholderTablet from './Level2/PlaceholderTablet';
import ZeeMinigame from './Level3/ZeeMinigame';
import ZeeScherm1 from './Level3/ZeeScherm1';
import ZeeScherm2 from './Level3/ZeeScherm2';
import ZeeScherm3 from './Level3/ZeeScherm3';
import ZeeScherm4 from './Level3/ZeeScherm4';
import Vaarwegscherm1 from './Level5/Schermen/WaterScherm';
import Vaarwegscherm2 from './Level5/Schermen/PeilschaalScherm';
import Vaarwegscherm3 from './Level5/Schermen/BrugScherm';
import SluisHologram from './Level1/Objects/SluisHologram';
import Kantoor_TV  from './Level2/Kantoor_TV';
import Vaarwegschermfinal from './Level5/Algoritme/AlgoritmeScherm';
import WegtabletFinal from './Level4/Objects/WegTabletFinal';
import BootMonitor from './Level3/BootMonitor';
import scheurkalender from './Level2/Scheurkalender';


import VOPMonitor1 from './Level6/objects/VOPMonitor01';
import VOPMonitor2 from './Level6/objects/VOPMonitor02';
import VOPMonitor3 from './Level6/objects/VOPMonitor03';
import Poster from './Level6/objects/Poster';
import Scheurkalender2 from './Level2/Scheurkalender_2';


const Objects = {
	'ball' : Ball,
	'suitcase' : Suitcase,
	'tutorialtablet' : TutorialTablet,

	//sluis level
	'waterdronetablet': WaterDroneTablet,
	'luchtdronetablet': LuchtDroneTablet,
	'sluishologram' : SluisHologram,

	//Crisis kantoor level
	'videomonitor' : VideoMonitor,
	'textminingmonitor' : TextMiningMonitor,
	'placeholdertablet' : PlaceholderTablet,
	'kantoortv' : Kantoor_TV,
	'scheurkalender' : scheurkalender,
	'scheurkalender2' : Scheurkalender2,


	//Zee level
	'zeeminigame' : ZeeMinigame,
	'zeescherm1' : ZeeScherm1,
	'zeescherm2' : ZeeScherm2,
	'zeescherm3' : ZeeScherm3,
	'zeescherm4' : ZeeScherm4,
	'bootmonitor' :BootMonitor,
	
	//weg level
	'wegtabletfinal' : WegtabletFinal,

	//Vaarweg level
	'watertablet' : Vaarwegscherm1,
	'peilschaaltablet' : Vaarwegscherm2,
	'brugtablet' : Vaarwegscherm3,
	'algoritmetablet' : Vaarwegschermfinal,

	// Control Room

	'vopmonitor1' : VOPMonitor1,
	'vopmonitor2' : VOPMonitor2,
	'vopmonitor3' : VOPMonitor3,
	'poster' : Poster,

};

const getObjectById = (id: string | undefined) => {
	if(id){
		return Objects[id];
	}
	else{
		return null;
	}
};

export { getObjectById };

export default Objects;

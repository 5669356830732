/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState} from 'react';
import { Html, useAnimations, useGLTF} from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { hologram, Tablet_m } from 'assets/models';
import { setSubCompleted } from 'data/objectives';
import { useThreeContext } from 'context/three';
import { useFrame } from '@react-three/fiber';
import styled from 'styled-components';
import Theme from 'style/theme';
import { ThreeCanvas } from 'components/Three';
import { useGlobalContext } from 'context/global';
import { model_scan } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import BasicHtml from '../../components/BasicHtml';
import { backgroundUI } from 'assets/images';

type GLTFResult = GLTF & {
	nodes: {
		Cube208: THREE.Mesh
		Cube208_1: THREE.Mesh
		Cube005: THREE.Mesh
		Cube005_1: THREE.Mesh
	}
	materials: {
		material: THREE.MeshStandardMaterial
		TabletEmission: THREE.MeshStandardMaterial
		HologramWire: THREE.MeshStandardMaterial
		HologramFlat: THREE.MeshStandardMaterial
	}
}
 
type ActionName = 'tablet_rotate' | 'hologram_popup' | 'hologram_rotate'
  type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function SluisHologram({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(hologram) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const {setControlsActive } = useThreeContext();
	const {activeDialogueChunk, hologramButtonState, setHologramButtonState, setActiveDialogueChunk, updateObjectiveData, setCloseButtonActive, setActivePannellumScene } = useGlobalContext();
	const {playSound} = useAudioContext();

	const [turn, setTurn] = useState<boolean>(false);
	const [turned, setTurned] = useState<boolean>(false);

	const tablet = useRef<THREE.Group>();
	const hologramg = useRef<THREE.Group>();


	const endQuaternion = new THREE.Quaternion();
	endQuaternion.setFromAxisAngle(new THREE.Vector3(-1,0,0), Math.PI / 2);

	const endVector = new THREE.Vector3(0,-0.12,0);

	const setHologram = () => {
		playSound && playSound(model_scan);
		setActiveDialogueChunk && setActiveDialogueChunk('l_01_hologram_033');
		setTurn(true);
	};

	useFrame(({clock})=>{
		if(hologramg.current){
			// hologramg.current.rotation.y = clock.getElapsedTime();
		}
		if(turn && tablet.current){
			tablet.current.quaternion.slerp(endQuaternion, 0.02);
			tablet.current.position.lerp(endVector, 0.02);
			if(tablet.current.quaternion.angleTo(endQuaternion) < 0.01 && tablet.current.position.distanceTo(endVector) < 0.01){
				actions['hologram_popup'].play();
				setTurned(true);
				setTurn(false);
				setControlsActive && setControlsActive(true);
			}
		}
	});
	useEffect(() => {
		setHologramButtonState && setHologramButtonState(1);
	},[]);
	useEffect(() => {
		if(hologramButtonState === 2)
			setHologram();
	},[hologramButtonState]);

	useEffect(() => {
	
		setControlsActive && setControlsActive(false);	

		if(actions){		
			for (const action in actions) {
				if(action == 'hologram_rotate'){
					actions[action].setLoop( THREE.LoopRepeat, 10);
				}
				else{
					actions[action].setLoop( THREE.LoopOnce, 1);
					actions[action].clampWhenFinished = true;	
				}
	
			}
			// actions['hologram_projection'].play();
			// actions['tablet_rotate'].play();
			// actions['hologram_popup'].play();
			// actions['hologram_rotate'].play();
		}

	},[actions]);

	useEffect(() => {
		// setControlsActive && setControlsActive(false);
		setCloseButtonActive && setCloseButtonActive(false);

		setActiveDialogueChunk && setActiveDialogueChunk('l_01_hologram_022');
		setTimeout(() => {
			setDistanceFactor(0.5);
		}, 1);
		// actions['hologram_projection'].play();
		return () =>{
			setActivePannellumScene && setActivePannellumScene(17);
			setSubCompleted(2,2);
			setSubCompleted(0,1);
			updateObjectiveData && updateObjectiveData();

		};

	},[]);

	useEffect(() => {
		if (!activeDialogueChunk) {
			setCloseButtonActive && setCloseButtonActive(true);

		}
	},[activeDialogueChunk]);


	const Background = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
		background-image: url(${backgroundUI});
		width: 100%;
		height: 100%;
		border-radius: 15px;
	`;

	const [distanceFactor, setDistanceFactor] = useState<number>(0);

	return (
		<>
			<group scale={8} ref={group} {...props} dispose={null} rotation={[0,0,0]}>
				<group ref={tablet}>
					<mesh geometry={nodes.Cube208.geometry} material={materials.material} />
					<mesh geometry={nodes.Cube208_1.geometry} material={materials.TabletEmission} />
					<group ref={hologramg} name="SluisUnionWirefreame" rotation={[0, -Math.PI / 2, 0]} scale={0}>
						<mesh geometry={nodes.Cube005.geometry} material={materials.HologramWire} />
						<mesh geometry={nodes.Cube005_1.geometry} material={materials.HologramFlat} />
					</group>
				</group>
			</group>
		</>		
	);
}

useGLTF.preload(hologram);


const measureDots = [  		
	{	
		first: {
			selected: false,
			left: 22,
			top: 15,
			id: '0',
			place: 0,
		},   		
		second: {
			selected: false,
			left: 68,
			top: 15,
			id: '0',
			place: 1,
		},
		afmeting: '14.8m',
		horizontal: true,
		completed: false
	},  
	{ 		
		first:{
			selected: false,
			left: 10,
			top: 40,
			id: '1',
			place: 0,
		}, 		
		second:{
			selected: false,
			left: 43.5,
			top: 35,
			id: '1',
			place: 1,
		},
		rotate: -5.3,
		afmeting: '7.40m',
		horizontal: true,
		completed: false

	},
	{
		first:{
			selected: false,
			left: 46,
			top: 27,
			id: '2',
			place: 0,
		},
		second:{
			used: false,
			selected: false,
			left: 79,
			top: 32,
			id: '2',
			place: 1,
		},
		rotate: 5.3,
		afmeting: '7.80m',
		horizontal: true,
		completed: false
	},
	{	
		first:{
			used: false,
			selected: false,
			left: 45,
			top: 30,
			id: '3',
			place: 0,
		},
		second:{
			used: false,
			selected: false,
			left: 45,
			top: 75,
			id: '3',
			place: 1,
		},
		afmeting: '9.13m',
		horizontal: false,
		completed: false
	},
	{	
		first:{
			used: false,
			selected: false,
			left: 81,
			top: 35,
			id: '4',
			place: 0,
		},
		second:{
			used: false,
			selected: false,
			left: 80.5,
			top: 45,
			id: '4',
			place: 1,
		},
		rotate :7,
		afmeting: '1.70m',
		horizontal: false,
		completed: false
	}
];

const answers = ['12', '34', '56', '78']; 

export {answers, measureDots};
import  { useEffect, useRef} from 'react';

import VOP from '../components/VOP';
import { useGlobalContext } from 'context/global';
import MonitorBorder from 'components/UniversalComponent/Borders/monitorborder';


const VopMonitor1 = () => {
	
	const {setCloseButtonActive, setActiveDialogueChunk} = useGlobalContext();

	useEffect(() => {
		// setActiveDialogueChunk && setActiveDialogueChunk('l_06_monitor1');
		setCloseButtonActive && setCloseButtonActive(false);

	},[]);

	return (
		<>
			<MonitorBorder>
				<VOP id={1}/>
			</MonitorBorder>
		</>
	);
};

export default VopMonitor1;
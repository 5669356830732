import { Html } from '@react-three/drei';
import { HtmlProps } from '@react-three/drei/web/Html';
import { AudioContext } from 'context/audio';
import { GlobalContext } from 'context/global';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

interface BasicHtmlProps {
	contentHeight?: number;
	contentWidth?: number; 
	scale?: number
}

const BasicHtml = (props : HtmlProps & BasicHtmlProps) => {

	const [distanceFactor, setDistanceFactor] = useState<number>(0);
	const globalContextValues = useContext(GlobalContext);
	const audioContextValues = useContext(AudioContext);

	useEffect(() => {
		setTimeout(() => {
			setDistanceFactor(1);
		}, 1);
	},[]);

	return(
		<Html					
			as='div'
			distanceFactor={distanceFactor}
			transform
			occlude
			position={props.position}
			scale={props.scale? props.scale : 1}	
			rotation={props.rotation}		
		>
			<GlobalContext.Provider value={globalContextValues}>
				<AudioContext.Provider value={audioContextValues}>
					<Content
						height={props.contentHeight}
						width={props.contentWidth}
					>
						{props.children}
					</Content>
				</AudioContext.Provider>
			</GlobalContext.Provider>
		</Html>
	);
};

interface ContentProps {
	height?: number;
	width?: number;
	fontSize?: number;
}

const Content = styled.div<ContentProps>`
    height: ${p => p.height ? p.height : 1020}px;
    width: ${p => p.width ? p.width : 1680}px;

	user-select: none;

    & * {
        font-size: 2.2rem;
    }
	
`;

const PaddingWrapper = styled.div`

	width: 100%;
	height: 100%;
	
    background-color: white;
    border-radius: 2%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

	overflow: hidden;
`;

const ActualWrapper = styled.div`

	width: 100%;
	height: 100%;
`;

export {PaddingWrapper, ActualWrapper};

export default BasicHtml;
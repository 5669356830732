import { Vrachtschip_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Vrachtschip_01,
	pitch: 0,
	yaw: 10
};

let customHotspots  =	[
	// {
		
	// 	'pitch': -36.200,
	// 	'yaw': -14.735,
	// 	'action': 'changeScene',
	// 	'createTooltipArgs': {
	// 		sceneId: 6,
	// 	}
	// },
	{
		'pitch': -14.3915,
		'yaw': -53.908,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'Monitor: Wolkendek',
			'param': 'zeescherm1'
		}
	},
	{
		'pitch': -14.9915,
		'yaw': -42.4120,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'Monitor: Golfhoogte',
			'param': 'zeescherm2'
		}
	},
	{
		'pitch': -16.6887,
		'yaw': -14.123,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'Monitor: Zeevaart',
			'param': 'zeescherm3'
		}
	},
	{
		'pitch': -16.1887,
		'yaw': 21.316741,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'Monitor: Weerrisico',
			'param': 'zeescherm4'
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene5 = {
	sceneId: '5',
	config: getConfig(customConfig),

};	

export default Scene5;
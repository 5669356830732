import { theme } from 'style';
import styled from 'styled-components';

type ContinueButtonProps = {
	left?: string;
	right?: string;
	bottom?: string;
	top?: string;

}

const ContinueButton = styled.button<ContinueButtonProps>`

	position: relative;

	left: ${p => p.left ? p.left : ''};
	right: ${p => p.right ? p.right : ''};
	bottom: ${p => p.bottom ? p.bottom : ''};
	top: ${p => p.top ? p.top : ''};

	//padding: 1%;
	color: ${theme.colors.accent_2};
	background-color: ${theme.colors.primary};
	width: 12%;
	height: 5%;
	/* pointer-events: all;
	user-select: auto; */

	font-weight: bold;
	cursor: pointer;

	border: none;

	box-shadow: 0px 2px 10px grey;

	&:hover, :focus {
		background-color: ${theme.colors.hover_bg};
		color: ${theme.colors.hover};
		border: none;
		outline: none;
	}

`;

export default ContinueButton;
import { useGlobalContext } from 'context/global';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Objects from 'components/Three/InteractiveObject/Objects/index';
import { ConsoleLabel, ConsoleRow } from './Styles';

const ConsoleModelSelect = () => {

	const { t } = useTranslation();

	const { activeIntObj, setActiveIntObj } = useGlobalContext(); // vervangen met global context variabel. ook in de render.
	const selectTabRef = useRef<HTMLSelectElement>(null);
	const [dropdownItems, setDropdownItems] = useState<string[]>();

	
	const fillDropdownItems = () => {
		const a: string[] = [];
		for (const [key] of Object.entries(Objects)) {
			a.push(key);
		}

		setDropdownItems(a);

	};
	
	useEffect(() => {
		fillDropdownItems();
	}, []);
	
	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.5')}</ConsoleLabel>
			<select onChange={(e) => setActiveIntObj && setActiveIntObj(e.target.value)} ref={selectTabRef} value={activeIntObj}>
				<option label={'none'} value={''}/>
				{dropdownItems && dropdownItems.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
			</select>
		</ConsoleRow>
	);
};

export default ConsoleModelSelect;
import { lookAt, getPitch, getYaw, getConfig} from 'react-pannellum';

const zoomedFov = 45;
const standardFov = 100;
const moveDuration = 1500; // miliseconds

function moveCamera(target, callback){
	getConfig().draggable = false;
	lookAt(target.pitch, target.yaw, zoomedFov, moveDuration, callback);
}

function resetCamera(){
	lookAt(getPitch, getYaw, standardFov, moveDuration, onResetComplete);
}

function onResetComplete(){
	getConfig().draggable = true;
}

export { moveCamera, resetCamera };
import React, { useEffect, useRef, useState, } from 'react';
import { useThreeContext } from 'context/three';
import styled from 'styled-components';
import PubSub from 'pubsub-js';
import Theme from 'style/theme';
import { messages } from './data';
import { useGlobalContext } from 'context/global';
import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';
import { useResponsiveFontSize } from 'react-responsive-font-size';


const ContentWrapper = styled.div`
	background-color: ${Theme.colors.accent_4};
	display: flex;
	flex-direction: column;

	justify-content: center;

	width: 100%;
	height: 100%;
	user-select: none;
	border-radius: 1.5%;

	font-size: 2rem;


	/* transform: translate(-50%, -50%); */
`;

const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;


const Button = styled.button`

	position: absolute;
	right: 0;
	transform: translate(10%, 10%);
	width: 20%;
	height: 20%;
	//margin-top:25%;
	background-color: white;
	color: ${Theme.colors.accent_2};
font-size: 60%;
	border: none;

	font-weight: bold;

	&:hover, :focus{
		color: ${Theme.colors.hover};
		background-color: ${Theme.colors.hover_bg};
	}

`;
interface boxProps {
	img?: string
}
const Box = styled.div<boxProps>`
	width:80%;
	height:55%;
	background-color: yellow;
	background-image: url(${p => p.img});
	background-size: cover;
	padding: 5%;
	position: relative;
	display: flex;
	flex-direction: column;
	//justify-content: center;
	align-items: center;
`;
const ButtonRow = styled.div`
	width:100%;
	height:50%;
	bottom: 0;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
`;
const TitleBox = styled.div`
	width:80%;
	height:10%;
	padding: 2%;
	position: relative;
`;

const Tutorial = () => {
	const { setActivePannellumScene, setCloseButtonActive } = useGlobalContext();
	const responsiveFont = useResponsiveFontSize(0.08, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });
	const responsiveFont2 = useResponsiveFontSize(0.6, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });

	const [activeMessage, setActiveMessage ] = useState<number>(0);
	const [ clicked, setClicked] = useState<boolean>(false);

	useEffect(() => {
		setCloseButtonActive && setCloseButtonActive(false);	

		return () => {
			setActivePannellumScene && setActivePannellumScene(21);
		};
	},[]);

	const next = () => {
		if(messages[activeMessage+1]){
			setActiveMessage(activeMessage+1);
		}else{
			PubSub.publish('tutorialtabletclick'); 
			setClicked(true);
		}
	};

	return (
		<TabletBorder>
			<ContentWrapper>
				<Content>
					{!clicked &&
				<>
					<TitleBox ref={responsiveFont2}>
						{messages[activeMessage].title}
					</TitleBox>
					<Box ref={responsiveFont} img={messages[activeMessage].img}>
						{messages[activeMessage].text}
						<ButtonRow>
							<Button onClick={next}>Verder</Button>
						</ButtonRow>
					</Box>
				</>}
				</Content>										
			</ContentWrapper>
		</TabletBorder>
	);
};

export default Tutorial;
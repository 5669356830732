import Scene1 from './Scenes/scene1';
import React, { useEffect, useRef } from 'react';
import ReactPannellum, { addScene, loadScene, getAllScenes, isLoaded} from 'react-pannellum';
import { sceneList } from './Scenes/index';
import PubSub from 'pubsub-js';
import { useGlobalContext } from 'context/global';
import BackToMap from 'components/Buttons/BackToMapButton';
import { resetCamera } from './CameraControls';
import { useAudioContext } from 'context/audio';
import { background_highway, background_nearwater, background_office, background_sea, footsteps } from 'assets/sounds';


const Pannellum = () => {

	const subscribeToken = useRef();

	useEffect(() => {

		//pubsub
		subscribeToken.current = PubSub.subscribe('changeScene', (name, val) => onNavigation(name, val));
		subscribeToken.current = PubSub.subscribe( 'openModel', (name, val) => onAction(name, val));


		return () => {
			if(subscribeToken.current){PubSub.unsubscribe(subscribeToken.current);} 
		};
	}, []);

	const {levelSelectActive, setPoster, activeIntObj, setActiveIntObj, activePannellumScene, setActivePannellumScene} = useGlobalContext();
	const {playSound, playBackgroundSound} = useAudioContext();

	//function for scene loaded check
	const isSceneLoaded = (sceneId) => {
		const scenes = getAllScenes();
		scenes.map((scene) => {
			if(scene[sceneId] != null){
				return true;
			}
		});
		return false;
	};

	const onChangeScene = (newScene) => {
		const scene =  sceneList[newScene - 1];
		// Check if scene is already loaded in
		if ( isSceneLoaded(scene.sceneId)) { 
			loadScene();
		} else{
			addScene(scene.sceneId, scene.config, () => loadScene(
				scene.sceneId, 
				scene.config.yaw ? scene.config.yaw : 0,
				scene.config.pitch ? scene.config.pitch : 0,
				120, 
				false		
			));
		}
 
	};
	const onAction = (name, val) => {
		// Activate hotspot action
		if(setActiveIntObj){
			setActiveIntObj(val);
		}
		
	};

	const onNavigation = (name, val) => {
		// Load Pannellum Scene from value
		if(setActivePannellumScene){
			setActivePannellumScene(val);
		}
		// if (val != 16) {
		// 	playSound && playSound(footsteps);
		// }
		
		PubSub.publish('navigate');

	};
	useEffect(() => {
		if(!activeIntObj) {
			resetCamera();
		}
	},[activeIntObj]);

	useEffect(() => {
		if(activePannellumScene == 20) {
			setTimeout(()=>{
				setPoster && setPoster(true);
			}, 2000 );
		}
	},[activePannellumScene]);

	useEffect(() => {

		isLoaded() && activePannellumScene && onChangeScene(activePannellumScene);
		
	}, [activePannellumScene]);

	const style = {
		width: '100vw',
		height: '100vh',
		background: '#000000'
	};

	const renderPannellum = activePannellumScene ? true : false;

	return (
		<>
			{/* <BackToMap/> */}
			{renderPannellum && activePannellumScene && (
				<ReactPannellum     
					config= {sceneList[activePannellumScene - 1].config}
					imageSource={sceneList[activePannellumScene - 1].config.imageSource}
					id = {sceneList[activePannellumScene - 1].sceneId}
					yaw = {sceneList[activePannellumScene - 1].config.yaw}
					pitch = {sceneList[activePannellumScene - 1].config.pitch}
					sceneId = {sceneList[activePannellumScene - 1].sceneId}
					style={style}>
				</ReactPannellum>

			)}
		</>

	);
};
    
export default Pannellum;

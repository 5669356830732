import { Kantoor_02 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Kantoor_02,
	yaw: -16.9648,
	pitch: 80.6668
};

const Scene = {
	sceneId: '15',
	config: getConfig(customConfig),
};	

export default Scene;

import { tabletBorder } from 'assets/images';
import Close from 'components/Buttons/CloseButton';
import { Allowed } from 'components/Three/ThreeCanvas';
import { useGlobalContext } from 'context/global';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import styled from 'styled-components';
type UniversalCanvasProps = {
    children: ReactNode;
}
const UniversalCanvas = ({children}: UniversalCanvasProps) => {

	const {activeIntObj} = useGlobalContext();

	return (<>
		{activeIntObj && !Allowed.includes(activeIntObj) && <motion.div   
			animate={{ opacity: [0,0,1] }}
			transition={{ duration: 1.2 }}>
			<Container>
				<Close/>
				{children}
				{/* <ActiveComponent>
                    <>
					<ZeeBorder>
						<GameLogicAndflubbels>

						</GameLogicAndflubbels>
					</ZeeBorder>
                    </>
				</ActiveComponent> */}
			</Container>         	
		</motion.div>}
	</>);
};


//styled components
const Container = styled.div`
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    text-align: center;
    display: flex;
    justify-content:center;
    align-items: center;
    z-index: 2;
`;

const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    height: 80%;
    width: 60%;

`;

export default UniversalCanvas;
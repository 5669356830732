import { CrossIconHover, CrossIconNormal, info, infoHover } from 'assets/icons';
import { floatingcardata, gladheidbestrijding, hoogtedetectie, Kaart0, ongevallenvoorspellen, slimmebruggen, slimmeverkeerslichten } from 'assets/images';
import { useGlobalContext } from 'context/global';
import { useEffect, useState } from 'react';
import { theme } from 'style';
import styled, { css } from 'styled-components';

 




const Keuze = (props: { onComplete: () => void}) => {
	const [showHelp, setShowHelp] = useState<boolean>(false);

	const [answer, setAnswer] = useState<string>('000000');
	const {setActiveDialogueChunk} = useGlobalContext();

	const changeAnswer = (id:number) => {
		setAnswer( a => replaceAt(a, id, a[id] === '1' ?  '0' : '1'));
	};


	const checkAnswer = () => {
		if(answer === '110010'){
			props.onComplete();
			setActiveDialogueChunk && setActiveDialogueChunk('l_04_finalquestion_04');
		}
		else{
			// call dialogue
			setActiveDialogueChunk && setActiveDialogueChunk('l_04_finalquestion_03');
			setAnswer('000000');
		}
	};

	return ( 			
		<>

			<Container flexdirection='row' height={25}>
			Welk type datasoort moet gebruikt worden om een juiste route en moment te  <br/>bepalen voor het vervoeren van de sluisdeur. Selecteer de drie juiste antwoorden.
				{showHelp? <CloseHelp onClick={() => setShowHelp(false)}></CloseHelp> : <OpenHelp onClick={() => setShowHelp(true)}/>}
			</Container>
			<Container flexdirection='column' height={75} style={{backgroundColor:theme.colors.accent_4, borderRadius: '2% 2% 0 0' }}>			
				{showHelp ? 
					<Open>
						<Row2>
							<Group>
								<Kopje>Slimme reisadviezen</Kopje>
				Met behulp van floating car data kunnen routes worden aangepast aan de hand van actuele reisadviezen. </Group>
							<Group>
								<Kopje>Slimme bruggen</Kopje>
				Met slimme bruggen kan er gecommuniceerd worden tussen bruggen en het verkeer. Het verkeer kan zo rekening houden met openstaande bruggen en preventief de route aanpassen.</Group>
							<Group>
								<Kopje>Slimme verkeerslichten</Kopje>
				Met behulp van slimme verkeerslichten krijgen automobilisten informatie over wanneer het stoplicht op groen springt en kan er een snelheidsadvies worden gegeven.</Group>
						</Row2>
						<Row2>
							<Group>
								<Kopje>Gladheidbestrijding</Kopje>
				Met behulp van data van autos kan er gladheid geconstateerd worden en kunnen autos daarop anticiperen. Dit zorgt voor veiliger verkeer en minder ongelukken.</Group>
							<Group>
								<Kopje>Doorrijhoogte</Kopje>	
								Als de vrachtwagen te hoog is voor de tunnels op de route, komt er vanuit hoogtedetectiesystemen een seintje om een andere route te nemen.</Group>					
							<Group>
								<Kopje>Ongevallen voorspellen</Kopje>
				Met behulp van een algoritme kan worden voorspeld welke locaties een grotere kans hebben op ongevallen. Op basis van deze informatie kunnen er beter weginspecteurs worden geplaatst.</Group>
						</Row2>
					</Open>
					: 
					<>
						<Row>
							{ images1.map((x, index) => 
								<Gridslot key={`${index}-egsdl`} img={x.img} onClick={(e) => {changeAnswer(index); e.currentTarget.blur(); }} selected={answer[index] === '1' ? true : false}>
									<div>{x.text}</div>
								</Gridslot>
							)}
						</Row>
						<Row>
							{ images2.map((x, index) => 
								<Gridslot key={`${index}-egsdwsl`} img={x.img} onClick={(e) => {changeAnswer(index + 3); e.currentTarget.blur(); }} selected={answer[index + 3] === '1' ? true : false}>
									<div>{x.text}</div>
								</Gridslot>
							)}
						</Row>
						<Button disabled={answer === '000000' ? true :  false} onClick={(e)=> {e.currentTarget.blur(); checkAnswer();}}> verzend </Button>
					</>}
			</Container>
		</>
	);
};

//#region Styled Components
const OpenHelp = styled.button` 
	position: relative;
	right:0;
	top:0;

	height: 50%;
	width: 8%;
	margin: 5% 0% 5% 2%;

	border-radius: 100%;

	background-image: url(${info});
	background-size: 60%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;

	border: none;

	z-index: 5;

	&:hover, :focus{
		background-color: #1cb1e0;
		background-image: url(${infoHover});
	}

`;
const Group = styled.div`
width: 50%;
height: 50%;
margin: 1%;
	display: flex;
	flex-direction: column;	
	font-weight: normal;
	color: black;
	font-size: 2vh;

`;
const Kopje = styled.div`
	font-weight: bold;
	color: black;
`;
const Open = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width:100%;
	height:100%;
	font-weight:normal;
	color: black;
	text-align: left;
`;
const CloseHelp =  styled.button`

	position: relative;
	right:0;
	top:0;

	height: 50%;
	width: 8%;
	margin: 5% 0% 5% 2%;

	background-image: url(${CrossIconNormal});
	background-size: 60%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;

	border: none;

	z-index: 5;

	&:hover, :focus{
		background-color: #1cb1e0;
		background-image: url(${CrossIconHover});
	}

`;
const Container = styled.div<{height:number, flexdirection: string}>`
    /* background-color: ${theme.colors.accent_1}; */

    height: ${p => p.height}%;

    width: 100%;

    display: flex;
    flex-direction: ${p => p.flexdirection};
    justify-content: center;
    align-items: center;

    color: ${p => theme.colors.accent_2};
    text-align: center;

    font-weight: bold;
	font-size: 2.5vh;
`;

const Row = styled.div`

    position: relative;
    
    height:35%;
    width: 80%;

    margin-bottom: 2%;

    display: flex;
    justify-content: center;
`;

const Row2 = styled.div`

    position: relative;
    
    //height:35%;
    width: 80%;

    margin-bottom: 2%;
	margin-left: 10%;
    display: flex;
    justify-content: center;
`;
const Gridslot = styled.button<{selected:boolean, img:string}>`

    position: relative  ;

    width: 100%;
	height: 100%;
    margin-left: 2%;
    margin-right: 2%;
	font-size: 2.5vh; 

    border: none;

    &:hover, :focus{
        filter: grayscale(0);
        outline: solid 2px ${theme.colors.accent_2}

	}

    & > div {
        position: absolute;
        left:0;
        top:0;
        right:0;

        background-color: white;
        padding: 2%;

        color: ${theme.colors.accent_2}
    }

    background-image: url(${p => p.img});
    background-position: center;
    background-size: cover;

    ${ p => !p.selected ? css`
        filter: grayscale(100%);

        `: css`
            
        outline: solid 2px ${theme.colors.accent_2}
            
        `}



`;

const Button = styled.button`

    height:10%;

    background-color: ${theme.colors.primary};
    color: ${theme.colors.accent_2};
    margin: 0;

	cursor: pointer;

    font-weight: bold;
	font-size: 3vh;
    line-height: 100%;

	border: none;
	height: 15%;
	width: 20%;
	//padding: 15px 20px 15px 20px;

    box-shadow: 0 0 10px grey;

	&:hover, :focus{
		color: ${theme.colors.hover};
		background-color: ${theme.colors.hover_bg};
	}

    &:disabled {
        pointer-events: none;
        filter: grayscale(100%);
    }

`;

//#endregion

//#region Data

const images1 = [
	{
		img:floatingcardata,
		text: 'Slimme reisadviezen'
	},
	{
		img:slimmebruggen,
		text: 'Slimme bruggen'
	},
	{
		img:slimmeverkeerslichten,
		text: 'Slimme verkeerslichten'
	}
	
	
];

const images2 = [
	{
		img: gladheidbestrijding,
		text: 'Gladheidbestrijding'
	},        
	{
		img: hoogtedetectie,
		text: 'Doorrijhoogte'
	},
	{
		img: ongevallenvoorspellen,
		text: 'Ongevallen voorspellen'
	}
];

//#endregion

//#region Helper Functions

const replaceAt = (string, index, replacement) => {
	return string.substring(0, index) + replacement + string.substring(index + 1);
};

//#endregion
 
export default Keuze;
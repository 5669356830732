import { theme } from 'style';
import styled, { css } from 'styled-components';

interface GridProps{
	onSelectSpace : (index: number) => void;
	selectedSpaces : string;
	answer: string;
	wrong: boolean;
}

const Grid = (props: GridProps) => {
	return(
		<GridWrapper>
			<_Grid>
				{ [...Array(16)].map((x, index) => {
					return(
						<GridSpace key={`${index}-grid-spaces`} >
							<GridButton
								onClick={(e) => {props.onSelectSpace(index); e.currentTarget.blur(); }} 
								selected={props.selectedSpaces[index] === '1' ? true : false}
								wrong={props.wrong}
								correct={props.answer[index] === '1'}
							/>
						
						</GridSpace>
					);
				})}
			</_Grid>
		</GridWrapper>
	);
};

//#region Styled Components

const GridSpace = styled.div`
	/* height: 100%;
	width: 100%; */

	display: flex;
	justify-content: center;
	align-items: center;

	flex-basis: 25%;
	
`;

interface GridButtonProps {
	selected: boolean;
	wrong: boolean;
	correct: boolean;
}

const GridButton = styled.button<GridButtonProps>`

	height: 75%;
	width: 75%;

	border: none;

	// if select background colored
	background-color: rgba(255,255,255,0.6);

	border-radius: 100%;
	cursor: pointer;

	transition: background-color 0.5s;


	&:hover, :focus {
		background-color: rgba(255,255,255,1);
	}

	${p => p.selected && css`
	
		/* outline: 2px solid ${theme.colors.accent_2}; */
		/* transition: background-color 0s; */
		background-color: ${theme.colors.accent_2};

	`}

	${p => p.wrong && p.selected && css`
	
		background-color: ${ p.correct ? theme.colors.accent_5 : theme.colors.accent_3}!important;

	`}



`;

const GridWrapper = styled.div`

	position: absolute;

	left:52%;
	top:16%;

	transform: translateX(-50%);

	width: 30%;
	height: 50%;
`;

const _Grid = styled.div`


	height: 100%;
	width: 100%; 

	display: flex;
	flex-wrap: wrap;
	/* grid-template-columns: repeat(4, 25%);
	grid-template-rows: repeat(4, 25%); */
`;

//#endregion

export default Grid;
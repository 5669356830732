import { FootstepsIconHover, FootstepsIconNormal, GlassesHover, GlassesNormal } from 'assets/icons';
import { createGlobalStyle } from 'styled-components';

// eslint-disable-next-line
const fonts = require('assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

    /* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */ // Lato seems to be a default font?????
    ${fonts}

    body{
        /* background-color: ${props => props.theme.colors.primary}; */
        color: ${props => props.theme.colors.secondary};
        margin: 0;
        font-family: 'Rijksoverheidsans';
        overflow: hidden;
        background-size: cover;
        background-image: linear-gradient(to bottom right, ${props => props.theme.colors.accent_2}, ${props => props.theme.colors.accent_4});
        user-select: none;
    }

    button {
      font-family: 'Rijksoverheidsans';
      font-size: 1.2rem;
    }
// #region pannellum basics

    .pnlm-container{
      z-index: 0;
      position: relative;
    }

      .pnlm-panorama-info {
        display: none!important;
    }

    .pnlm-load-box{
        display: none!important;
    }

    .pnlm-about-msg {
        display: none!important;
    }

    .wrapperThing {
      max-height: 100%;
      max-width: 100%;
    }

    //#region Custom Hotspot for level weg

    .custom-hotspot {
      height: 20px;
      width: 20px;
      background-color: ${p => p.theme.colors.primary};
      border-radius: 100%;

      color: white;
      font-weight: bold;
      font-family: 'Rijksoverheidsans';
      font-size: 1.5em;

      animation: fadeIn 1s;
    }

    @keyframes fadeIn {
      from{ opacity: 0}
      to{ opacity: 1}
    }

    .custom-hotspot > div {
      height: 200px;

      position: absolute;
      left:50%;
      top:0;

    }

    .beneath > div {
        transform: translate(-2px);
    }

    .above > div {
        transform: translate(-2px, -100%);
    }

    .beneath > div .custom-hotspot-wrapper {
      transform-origin: top center;
      justify-content: end;
    }

    .above > div .custom-hotspot-wrapper {
      transform-origin: bottom center;
    }

    .custom-hotspot-wrapper {

      border-left: solid 4px white;
      height: 100%;
      padding-left: 15px;


      position: relative;

      animation: example 2s;

      display: flex;
      flex-direction: column;
    }

    @keyframes example {
      from {transform: scale(1,0)};
      to {transform: scale(1,1)};
    }

    .custom-hotspot-wrapper > button {
      border: none;
      outline: none;
      width: 200px;
      padding-top: 56.25%;

      background-color: white;
      background-position: center;
      background-size: cover;
    }

    .custom-hotspot-wrapper > button:hover, .custom-hotspot-wrapper > button:focus{
      outline: solid 3px ${p => p.theme.colors.accent_2};
    }

    //#endregion

    .custom-filter-hotspot {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: 5px solid ${props => props.theme.colors.accent_2};
      cursor: pointer!important;
      background-color: ${props => props.theme.colors.primary};
      background-image: url(${GlassesNormal});
      background-position: center;
      background-size: cover;
	    background-repeat: no-repeat;

    }

    .custom-filter-hotspot:hover  {
      background-image: url(${GlassesHover});
      background-color: ${p => p.theme.colors.hover_bg};
      border-color: ${props => props.theme.colors.primary};
    }

    .custom-nav-hotspot {
      height: 50px;
      width: 50px;
      /* background: green; */
      border-radius: 50%;
      border: 3px solid ${props => props.theme.colors.accent_2};

      cursor: pointer!important;

      background-color: ${props => props.theme.colors.primary};
      background-image: url(${FootstepsIconNormal});
      background-position: center;
      background-size: cover;
	    background-repeat: no-repeat;

    }

    .custom-nav-hotspot:hover  {
      background-image: url(${FootstepsIconHover});
      background-color: ${p => p.theme.colors.hover_bg};
      border-color: ${props => props.theme.colors.primary};
    }

      .custom-act-hotspot {
        font-family: 'Rijksoverheidsans';
        height: 25px;
        width: 25px;
        background-color:  ${props => props.theme.colors.primary};
        border-radius: 50%;
        border: 3px solid ${props => props.theme.colors.accent_2};
        cursor: pointer!important;
        user-select: none;
      }

      .custom-act-hotspot:hover {
        background-color:  ${props => props.theme.colors.hover_bg};
        border-color: ${p => p.theme.colors.hover};

      }
    
      .custom-act-hotspot span {
        visibility: hidden;
        position: absolute;
        background-color: #fff;
        color: #000;
        text-align: center;
        width: max-content;
        padding: 10px 12px 6px 12px;
        transform: translate(-50%, -100%);
        top: -15px;
        margin-left: 50%;
        border-radius: 20px;
        font-size: 1.5rem;
        cursor: default;
      }
    
      .custom-act-hotspot span::after{
        content: '';
        position: absolute;
        left:50%;
        top: 98%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid white;
        clear: both;
      }
    
      .custom-act-hotspot:hover span{
        visibility: visible;
      }

      .radios{
        width: 30px;
        height: 30px;
        color: black;
        border: none;
        margin: auto;        
      }
      .selectBox {
        text-decoration: line-through; 
        color: black;
        border: none!important;
        opacity: 0.5;
      }
`;

export default GlobalStyle; 
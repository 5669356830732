import { floatingcardata, hoogtedetectie } from 'assets/images';
import { Snelweg_02filter } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Snelweg_02filter,
	pitch: 0,
	yaw: 0
};

let customHotspots  =	[
	{
		'pitch': 21.7949,
		'yaw': -17.52405,
		'action': 'custom',
		'createTooltipArgs': {
			'titel': 'Slimme reisadviezen',
			'thumbnail': floatingcardata,
			'position' : 'above',
			'index': 6,
			// 'text':'content.hoveritems.wegtablet1',
			// 'param': 'wegtablet1'
		}
	},	
	{
		'pitch': 4.9685,
		'yaw': -6.2013,
		'action': 'custom',
		'createTooltipArgs': {
			'titel': 'Doorrijhoogte',
			'thumbnail': hoogtedetectie,
			'position' : 'beneath',
			'index': 1,
			// 'text':'content.hoveritems.wegtablet1',
			// 'param': 'wegtablet1'
		}
	},	
	{
		
		'pitch': -0.98581,
		'yaw':  176.345,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 16,
		}
	},	
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene111 = {
	sceneId: '111',
	config: getConfig(customConfig),

};	

export default Scene111;
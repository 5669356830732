import { useGlobalContext } from 'context/global';
import { useEffect, useRef, useState } from 'react';
import PubSub from 'pubsub-js';
import DataOverlay from './DataOverlay';
import { Objectives, setSubCompleted } from 'data/objectives';


//#region Data

const data = [
	{
		id:2,
		video: '652421544',
		title: 'Doorrijhoogte',
		description: 'Europese regelgeving schrijft voor dat vrachtwagens niet hoger mogen zijn dan 4 meter. Toch ontstaat er geregeld schade aan tunnels doordat hogere vrachtwagens er doorheen proberen te rijden. Om dit te voorkomen maakt Rijkswaterstaat gebruik van een hoogtedetectiesysteem. Hoe werkt zoiets?  ',
		//data
	},
	{
		id:5,
		video: '652421797',
		title: 'Gladheidbestrijdng',
		description: 'In de toekomst zijn nieuwe auto’s verbonden met het internet en zenden ze data uit. Deze data wordt gedeeld als jij je alarmlichten aanzet, als de airbag uitklapt of als je slipt. De data wordt verzameld door sensoren in de auto en gestuurd naar Nationaal Dataportaal Wegverkeer (NDW). In de toekomst wil Rijkswaterstaat deze data gebruiken voor bestrijding van gladheid. Maar hoe werkt de gladheidsbestrijding nu? '
	},
	{
		id:3,
		video: '652421289',
		title: 'Ongevallen voorspellen',
		description: 'Rijkswaterstaat heeft een model ontwikkeld dat ervoor zorgt dat men sneller kan reageren bij ongevallen. Weginspecteurs kunnen sneller bij incidenten zijn doordat ze op basis van algoritmes steeds op de hoogte worden gehouden van de beste standby locaties. Daarnaast is Rijkswaterstaat ook bezig met het voorkomen van incidenten. Hoe kan dit gedaan worden op basis van data?',
	},
	{
		id:4,
		video: '652423430',
		title: 'Slimme verkeerslichten',
		description: 'Rijkswaterstaat heeft op dit moment verschillende slimme verkeerslichten geplaatst om te testen. Deze lichten communiceren met voertuigen en navigatie-apps. Maar hoe werken die slimme verkeerslichten en wat kun je ermee?'
	},
	{
		id:6,
		video: '652422809',
		title: 'Slimme bruggen',
		description: 'Schippers willen zo vlot mogelijk door Nederland varen, maar de bruggen en sluizen kunnen niet altijd meteen open. Bijvoorbeeld omdat er rekening wordt gehouden met het wegverkeer dat over de brug rijdt. Rijkswaterstaat werkt aan slimme oplossingen om het verkeer op het water en op de weg te combineren. Wat zijn die oplossingen?',
	},
	{
		id:1,
		video: '652422480',
		title: 'Slimme reisadviezen',
		description: 'Het navigatiesysteem en de apps op je telefoon delen om de paar seconden GPS-data over je locatie. Dat heet floating car data. NDW (Nationaal Dataportaal Wegverkeer) ontvangt deze data en laten er algoritmes op los. Zo berekenen ze bijvoorbeeld reistijden op reistijdtrajecten. Wat kun je nog meer met floating car data?'
	},
];

//#endregion

const SnelwegManager = () => {

	const { setActiveDialogueChunk, activeDialogueChunk,updateObjectiveData, activePannellumScene, setActiveIntObj, activeLevel, levelSelectActive, setActivePannellumScene } = useGlobalContext();

	const [checkDiaClose, setCheckDiaClose] = useState<boolean>(false);
	const [dialog, setDialog] = useState<boolean>(false);

	const subscribeToken = useRef();

	useEffect(()=> {
		subscribeToken.current = PubSub.subscribe( 'customHotspot', (name, val) => onClickHandler(val));
		return () => {
			if(subscribeToken.current){PubSub.unsubscribe(subscribeToken.current);} 
		};
	}, []);

	useEffect(() => {
		if(activePannellumScene == 16 && dialog == false){
			setActiveDialogueChunk && setActiveDialogueChunk('l_04_first_07');	
			setDialog(true);	
		}
	}, [activePannellumScene]);

	useEffect(() => {
		if(checkDiaClose && !activeDialogueChunk){
			setActiveIntObj && setActiveIntObj('wegtabletfinal');
			setActiveDialogueChunk && setActiveDialogueChunk('l_04_finalquestion_01');
			setCheckDiaClose(false);
		}
		
	},[activeDialogueChunk]);

	const [dataActive, setDataActive] = useState<number>(0);
	const [progression, setProgression] = useState<string>('000000');


	const onClickHandler = (id: number) => {
		setProgression(a => replaceAt(a, id - 1, '1'));
		setDataActive(id);
	};

	const onClose = () => {	
		if(progression === '111111'){
			setSubCompleted(5,0);
			updateObjectiveData && updateObjectiveData();
			setCheckDiaClose(true);
		}	
		setDataActive(0);
	};
	return (
		
		<> 
			
			{ !levelSelectActive && dataActive ? 
				<DataOverlay
					data={data[dataActive - 1]}
					onClose={()=> onClose()}
				/>
				: null }
		</>
	);

};

//#region Styled Components

//#endregion

//#region Helper Functions

const replaceAt = (string, index, replacement) => {
	return string.substring(0, index) + replacement + string.substring(index + 1);
};

//#endregion

export default SnelwegManager;


import React, { useEffect, useRef, useState, } from 'react';

import { Objectives, setSubCompleted } from 'data/objectives';
import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';

import WaterDroneView from '../Screens/WaterDroneView';
import { waterdrone } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';



const WaterDroneTablet = () => {
	const {setControlsActive} = useThreeContext();
	const { updateObjectiveData, activeDialogueChunk, setActiveDialogueChunk, setCloseButtonActive, setActiveIntObj, setActivePannellumScene} = useGlobalContext();
	const {playSound} = useAudioContext();

	useEffect(() => {
		setControlsActive && setControlsActive(false);
		playSound && playSound(waterdrone);
		setActiveDialogueChunk && setActiveDialogueChunk('l_01_WDfilter_open');

		return () => {

			if(!Objectives[2].subObjectives[0].completed){
				setActiveDialogueChunk && setActiveDialogueChunk('l_01_LDfilter_05');
			}

			onFinalFilterClose();
		};
	},[]);
	

	const onFinalFilterClose = () => {
		if (Objectives[2].subObjectives[0].completed == true && Objectives[2].subObjectives[1].completed == true && !Objectives[2].subObjectives[2].completed) {
			// setActiveDialogueChunk && setActiveDialogueChunk('l_01_final_01');	
			setActivePannellumScene && setActivePannellumScene(17);	
			setActiveIntObj && setActiveIntObj('sluishologram');
		}
	};

	return (
		<>
			<TabletBorder>
				<WaterDroneView/>
			</TabletBorder>
			

			{/* <Html					
				as='div'
				distanceFactor={distanceFactor}
				transform
				occlude
				position={[0,0,0.07]}
				scale={1}			
			>
				<ContentWrapper img={activeImage}>
					<Content>
						{activeFilterText && <Title>{activeFilterText}</Title>}
						<Box>
							{WaterDroneFilters.map((filter, index) => (
								<FilterButton 
									selected={filter.selected} 
									key={`${index}-${filter}-filter`} 
									hover={filter.hover} 
									selectedImg={filter.selectedImg} 
									img={filter.img} 
									onClick={() => setFilter(filter.id)}>
								</FilterButton>
							))}
						</Box>
						{/* <ScanButton onClick={() => setScanComplete(0)}>Scan onder water uitvoeren</ScanButton>				 */}
			{/* </Content>										
				</ContentWrapper>
			</Html> */} 
		</>
	);
};

export default WaterDroneTablet;
import {Karl,
	CommunicatieAdviseur, 
	MobielVerkeersleider,
	RegiseurCrisisCommunicatie,
	Schipper,
	Sluisbeheerder,
	Transporteur,} from 'assets/images';

const avatars = [
	{
		image: Karl,
		name: 'Karla'
	},	
	{
		image: Sluisbeheerder,
		name: 'Sluisbeheerder'
	},		
	{
		image: RegiseurCrisisCommunicatie,
		name: 'Data Specialist'
	},	
	{
		image: Schipper,
		name: 'Kapitein'
	},	
	{
		image: Transporteur,
		name: 'Transporteur'
	},	
	{
		image: MobielVerkeersleider,
		name: 'Mobiel verkeersleider'
	},
	{
		image: CommunicatieAdviseur,
		name: 'Communicatieadviseur'
	},
	{
		image: '',
		name: 'Hint'
	},
	{
		image: '',
		name: 'Goede antwoord'
	}
];

export {avatars} ;
import { CrossIconHover, CrossIconNormal } from 'assets/icons';
import styled from 'styled-components';
import { proportional } from 'util/proportional';

const Button = styled.button.attrs({})`

    ${proportional};

    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.accent_2};
    margin: 0;

	cursor: pointer;

    display: flex;
    align-items: center;

    font-weight: bold;
    line-height: 100%;

	border: none;

	padding: 5px 10px 5px 10px;

	&:hover, :focus{
		color: ${p => p.theme.colors.hover};
		background-color: ${p => p.theme.colors.hover_bg};
	}

`;

const CloseButton =  styled.button`

	position: absolute;
	right:0;
	top:0;
	/* margin: 15px; */

	height: 50px;
	width: 50px;

	border: none;

	cursor: pointer;


	background-image: url(${CrossIconNormal});
	background-size: 50%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;

	@media ${p => p.theme.devices.mobile} {
		margin: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		margin: 30px;
	};

	&:hover, :focus{

		background-color: #1cb1e0;

		background-image: url(${CrossIconHover});



	}

`;

const RoundButton = styled.div.attrs({})`
    
`;

export {RoundButton, CloseButton};

export default Button;


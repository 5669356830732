import PubSub from 'pubsub-js';
import i18next from 'i18next';
import { moveCamera } from './CameraControls';

const setScene = (id) => {
	PubSub.publish('openModel', id);
};

function onHotspotClick(event){
	if(event.type === 'scene') {
		moveCamera(event, () => setScene(event.param));
	}
}

function onNavHotspotClick(event){
	PubSub.publish('changeScene', event.sceneId);
}

function onCustomHotspotClick(e){
	PubSub.publish('customHotspot', e.index);
}

const createHotspot = function(props, args){
	props.addEventListener('click', function(){	onHotspotClick(args);});
	const span = document.createElement('span');
	span.innerHTML = args.text;
	props.appendChild(span);
}; 

const createNavHotspot = function(props, args){
	props.addEventListener('click', function(){onNavHotspotClick(args);});
};

const createCustomHotspot = (props, args) => {
	props.addEventListener('click', function(){onCustomHotspotClick(args);});

	const div = document.createElement('div');
	const wrapper = document.createElement('div');
	wrapper.classList.add('custom-hotspot-wrapper');

	div.appendChild(wrapper);

	const title = document.createElement('div');
	title.innerHTML = args.titel;

	const thumbnail = document.createElement('button');
	thumbnail.style.backgroundImage = `url(${args.thumbnail})`;

	
	wrapper.appendChild(title);
	wrapper.appendChild(thumbnail);

	props.appendChild(div);
	
	props.classList.add(args.position);

};

const NavHotspotConfig = {
	'cssClass': 'custom-nav-hotspot',
	'createTooltipFunc': createNavHotspot,
};

const HotspotConfig = {
	'cssClass': 'custom-act-hotspot',
	'createTooltipFunc': createHotspot,
};

const FilterHotspotConfig = {
	'cssClass': 'custom-filter-hotspot',
	'createTooltipFunc': createNavHotspot,
};

const customHotspotConfig = {
	'cssClass': 'custom-hotspot',
	'createTooltipFunc': createCustomHotspot,
};

function getHotspots(customHotspots){

	const newHotspots = [customHotspots.length];

	for(let i = 0; i < customHotspots.length; i++){
		switch(customHotspots[i].action){
		case 'changeScene':
			newHotspots[i] = {
				...NavHotspotConfig,
				...customHotspots[i]
			};
			break;
		case 'openModel':
			newHotspots[i] = {
				...HotspotConfig,
				...customHotspots[i]
			};
			break;
		case 'filter':
			newHotspots[i] = {
				...FilterHotspotConfig,
				...customHotspots[i]
			};
			break;
		case 'custom':
			newHotspots[i] = {
				...customHotspotConfig,
				...customHotspots[i]
			};
			break;
		}

		const hotspotArgs = {
			'pitch' : newHotspots[i].pitch,
			'yaw': newHotspots[i].yaw
		};


		newHotspots[i].createTooltipArgs = {
			...newHotspots[i].createTooltipArgs,
			...hotspotArgs
		};
	}
	return newHotspots;
}

export { getHotspots };
import styled from 'styled-components';

interface AvatarProps {
	img : string
}

const Avatar = styled.div<AvatarProps>`

	background-image: url(${p => p.img});
	background-size: cover;

	height: 150px;
	width: 150px;
`;

export default Avatar;
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useContext, useEffect, useRef, useState } from 'react';
import TextMining from './screen';
import { useGlobalContext } from 'context/global';
import MonitorBorder from 'components/UniversalComponent/Borders/monitorborder';

const TextMiningMonitor = () => {

	const { updateObjectiveData, setCloseButtonActive, setActiveDialogueChunk } = useGlobalContext();

	useEffect(() => {
		setCloseButtonActive && setCloseButtonActive(false);
		setActiveDialogueChunk && setActiveDialogueChunk('l_02_open');
	},[]);

	return (
		<>
			<MonitorBorder>
				<TextMining/>	
			</MonitorBorder>
		</>
	);
};

export default TextMiningMonitor;
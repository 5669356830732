import { s_scanning } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import { useEffect, useState } from 'react';
import Theme from 'style/theme';
import styled, { keyframes } from 'styled-components';
import { FilterProps } from '..';

interface SearchFilterProps {
    img: string;
}
 
const WaterSearchFilter = (props: SearchFilterProps & FilterProps) => {
	const [scanStep, setScanStep] = useState<number>(props.complete ? 2 : 0); // 0 == not started, 1 == scanning, 2 scan complete 

	const {playSound} = useAudioContext();
	
	useEffect(() => {
		if(scanStep === 1 && !props.complete){
			setTimeout(() => {
				props.onCompletion();
			}, 2000);
		}

	}, [scanStep]);

	return (
		<Container>		
			{ scanStep === 0 ? 
				<Invisible border={'none'} top={'3%'} left={'59%'}>
					<Button onClick={() => setScanStep(1)}></Button> 
				</Invisible>				
				: scanStep === 1 ?
					<Result img={props.img}>
						<Invisible border={'5px solid orange'} top={'2%'} left={'57%'}></Invisible>
					</Result>
				// Resultaat
					: ''
			}
		</Container>
	);

};

//#region Animations

const ScanAnimation = keyframes`
	0% {
		top: -10%;	
	}
	50% {
		top: 110%;
	}
	100% {
		top: -10%;
	}
`;

const DotAnimation = keyframes`
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const FadeInAnimation = keyframes`
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
`;

//#endregion

//#region Styled Components


const Button = styled.button`
	position: absolute;
	top:50%; 
	height: 15%;
	width: 15%;
	opacity: 0.1;
	border: none;
	border-radius:50%;
	cursor: pointer;
`;
interface materialprops {
	top: string
	left: string
	border: string
}
const Invisible = styled.div<materialprops>`
	display:flex;
	justify-content: center;
	position: absolute;
	left: ${p => p.left};
	top: ${p => p.top};
	border: ${p => p.border};
	border-radius:50%;
	height: 30%;
	width: 20%;
	&:hover {
		${Button} {
		opacity: 1;
		}
	}
`; 

const Container = styled.div`

	position: absolute;

	left:0;
	top:0;
	bottom: 0;
	right:0;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}

	/* background-color: honeydew; */

`;

const StartScanButton = styled.button`

	position: absolute;

	left:50%;
	top:50%;

	border: none;
	outline: none;

	padding: 10px 15px;

	color: ${Theme.colors.accent_2};

	transform: translate(-50%,-50%);

	& :hover, :focus {
		background-color: ${Theme.colors.hover_bg};
		color: ${Theme.colors.hover};
	}

`;

const ScanningStatus = styled.div`

	position: absolute;
	color: white;

	left:50%;
	top:50%;

	transform: translate(-50%,-50%);

	font-size: 2em;

	& > span {
		font-size: 1.2em;
		animation: ${DotAnimation} 2s infinite;
	}

	& :nth-child(2){
		animation-delay: 400ms;
	}

	& :nth-child(3){
		animation-delay: 600ms;
	}
	
`;

const ScanLine = styled.div`
	position: absolute;
	left:0;
	right:0;
	height: 5px;
	top: -10%;

	background-color: red;

	animation: ${ScanAnimation} 4s ease;
	animation-iteration-count: 2;
	animation-delay: 500ms;

	box-shadow: 0 0 30px red;

`;

const ScanBackground = styled.div`
	position: absolute;
	left:0;
	top:0;
	bottom: 0;
	right:0;

	background-color: rgba(0,50,0,0.5);

	animation: ${FadeInAnimation} 1000ms;
`;

const Result = styled.div<{img:string}>`
	position: absolute;
	left:0;
	top:0;
	right:0;
	bottom: 0;

	animation: ${FadeInAnimation} 1s;

	background-image: url(${p => p.img});
	background-position: center;
	background-size: cover;
`;

//#endregion


export default WaterSearchFilter;
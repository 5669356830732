const config = {
	autoLoad: true,
	showZoomCtrl:  false,
	showFullscreenCtrl: false,
	disableKeyboardCtrl: true,
	KeyboardZoom: false,
	doubleClickZoom: false,
	mouseZoom: false,
	uiText: {
		loadButtonLabel: 'Click to<br>Load<br>Panorama',
		loadingLabel: 'Loading...',
		bylineLabel: '',
		noPanoramaError: 'No panorama image was specified.',
		fileAccessError: 'The file %s could not be accessed.',
		malformedURLError: 'There is something wrong with the panorama URL.',
		iOS8WebGLError: 'Due to iOS 8\'s broken WebGL implementation, only progressive encoded JPEGs work for your device (this panorama uses standard encoding).',
		genericWebGLError: 'Your browser does not have the necessary WebGL support to display this panorama.',
		textureSizeError:  'This panorama is too big for your device! It\'s %spx wide, but your device only supports images up to %spx wide. Try another device. (If you\'re the author, try scaling down the image.)',
		unknownError: 'Unknown error. Check developer console.'
	},
	hotSpotDebug: false,
	sceneFadeDuration: 1000,
	compass: false,
};

function getConfig(customConfig){
	const newConfig = {
		...config,
		...customConfig
	};

	return newConfig;
}

export default getConfig;
import { Sluis_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Sluis_01,
	yaw: -10,
	pitch: -2.27172
};


let customHotspots  =	[
	{
		'pitch': -3.96,
		'yaw': -20.857,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 7,
		}
	},
	{
		'pitch': -35.664,
		'yaw':  -42.276,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'Onderwaterdrone',
			'param': 'waterdronetablet'
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene2 = {
	sceneId: '8',
	config: getConfig(customConfig),
};	

export default Scene2;
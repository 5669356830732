/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { Ball_m } from 'assets/models';
import { useGlobalContext } from 'context/global';
import { setSubCompleted } from 'data/objectives';
import { checkLevelsForUnlock } from 'data/levels';

type GLTFResult = GLTF & {
  nodes: {
    voetbal: THREE.Mesh
  }
  materials: {
    material: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
	const { updateObjectiveData } = useGlobalContext();

	useEffect(() => {
		// setSubCompleted(0, 0); 
		// setSubCompleted(1, 0); 
		// updateObjectiveData && updateObjectiveData();
	},[]);

	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(Ball_m) as GLTFResult;
	return (
		<group scale={2} ref={group} {...props} dispose={null}>
			<mesh geometry={nodes.voetbal.geometry} material={materials.material} />
		</group>
	);
}



useGLTF.preload(Ball_m);

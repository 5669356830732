import { useState } from 'react';
import { goedAntwoord } from 'assets/images';
import styled from 'styled-components';
import Theme from 'style/theme';
import Assignment from './Assignment';

const Algorithm = () => {

	const [questionState, setQuestionState] = useState<number>(1);

	return(
		questionState != 3 ? 
			<>
				<Yellow>
					{questionState == 1 ? 
						<Title>Schrijf het eerste deel van het algoritme. Hoe diep moet de vaargeul minimaal zijn?</Title>
						:
						<Title>Schrijf het tweede deel van het algoritme.</Title>}
				</Yellow>
				<Blue>
					<Assignment questionState={questionState} setQuestionState={setQuestionState}/>
				</Blue>	
			</>
			:
			<EndScreen/> 
	);
};
//#region styled components

const EndScreen = styled.div`
	background-image: url(${goedAntwoord});
	background-position: center;
	background-size: 100% 100%;
	background-color: white;
	background-repeat: no-repeat;
	border-radius: 2%;
	width: 100%;
	height: 100%;
`;

const Yellow = styled.div`
	background-color: ${Theme.colors.accent_1};
	height: 15%;
    border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	padding: 0% 4% 0% 0%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Blue = styled.div`
	background-color: ${Theme.colors.accent_4};
    border-radius: 3% 3% 2% 2%;
    height:85%;
	padding:2% 4% 0% 4%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const Title = styled.div`
	color: black;
	text-align: center;
	padding:5%;
	font-size: 2.5vh;
	font-weight: bold;
	position: relative;
`;



//#endregion
export default Algorithm;
import { Objectives, setSubCompleted } from 'data/objectives';
import { useEffect, useState } from 'react';
import { theme } from 'style';
import { PaddingWrapper } from '../../components/BasicHtml';
import Keuze from './Keuze';
import Resultaat from './Resultaat';

// interface FinalDataScreenProps {
    
// }
 
const FinalDataScreen = (props) => {

	const [step, setStep] = useState<number>(0);

	return (  
		<PaddingWrapper style={{padding: 0, backgroundColor: theme.colors.accent_1}}>
			{ step === 0 ?  
				<Keuze onComplete={() => setStep(1)}/> 
				: step === 1 ?
					<Resultaat/>
					: null }
		</PaddingWrapper>
	);
};
 
export default FinalDataScreen;
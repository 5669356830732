import { Kantoor_03 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Kantoor_03,
	yaw: -10,
	pitch: -2.27172
};


let customHotspots  =	[
	{
		'pitch': -4.3,
		'yaw': -134,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 19,
		}
	},
	// {
	// 	'pitch': -7.4,
	// 	'yaw': 1.4,
	// 	'action': 'openModel',
	// 	'createTooltipArgs': {
	// 		'type': 'scene',
	// 		'text': 'poster',
	// 		'param': 'poster',			
	// 	}
	// },
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene3 = {
	sceneId: '9',
	config: getConfig(customConfig),
};	

export default Scene3;
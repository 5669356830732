import { useEffect, useRef } from 'react';
import { Objectives  } from 'data/objectives';
import { useGlobalContext } from 'context/global';
import LuchtDroneView from '../Screens/LuchtDroneView';
import { useAudioContext } from 'context/audio';
import { luchtdrone } from 'assets/sounds';
import { useThreeContext } from 'context/three';
import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';

const LuchtDroneTablet = () => {

	const {setControlsActive} = useThreeContext();

	const { setActiveDialogueChunk, setActiveIntObj, setActivePannellumScene } = useGlobalContext();
	const {playSound} = useAudioContext();

	useEffect(() => {
		setControlsActive && setControlsActive(false);

		playSound && playSound(luchtdrone);
		return () => {
			if(!Objectives[2].subObjectives[1].completed){
				setActiveDialogueChunk && setActiveDialogueChunk('l_01_LDfilter_05');
			}
			onFinalFilterClose();
		};
	},[]);

	// Called upon unmount. On close object, open object if objectives 0 and 1 are complete.
	const onFinalFilterClose = () => {
		if (Objectives[2].subObjectives[0].completed == true && Objectives[2].subObjectives[1].completed == true && !Objectives[2].subObjectives[2].completed) {			
			// setActiveDialogueChunk && setActiveDialogueChunk('l_01_final_01');	
			setActivePannellumScene && setActivePannellumScene(17);	
			setActiveIntObj && setActiveIntObj('sluishologram');
		}
	};

	return (
		<>
			<TabletBorder>
				<LuchtDroneView/>
			</TabletBorder>
		</>
	);
};

export default LuchtDroneTablet;
import { MapIconHover, MapIconNormal } from 'assets/icons';
import { useGlobalContext } from 'context/global';
import { Objectives } from 'data/objectives';
import styled from 'styled-components';
import { proportional } from 'util/proportional';

const BackToMapButton = styled.button`
    background-color: ${props => props.theme.colors.primary};
	/* border-radius: 100%; */
	position: absolute;
	bottom:0;
	right:0;

	transform: translate(65px, 50%);

	padding: 15px;
	color: ${p => p.theme.colors.accent_2};
	font-weight: bold;

	z-index: 1;
	text-align: center;

	/* background-image: url(${MapIconNormal});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 70%; */

	cursor: pointer;
	border: none;

	&:hover, :focus{
		background-color: ${p => p.theme.colors.hover_bg};
		color: ${p => p.theme.colors.hover};
	}
    
`;

const BackToMap = () => {
    
	const {activeObjective,setDisplayEnd, activeIntObj, activePannellumScene, setActiveIntObj ,setActiveObjective, setActivePannellumScene, setLevelSelectActive , setActiveDialogueChunk} = useGlobalContext();

	const onBackToMap = () => {
		//close pannellum
		setActivePannellumScene && setActivePannellumScene(undefined);
		//enable map
		setLevelSelectActive && setLevelSelectActive(true);
		//set objectives to main objectives (levels)
		setActiveObjective && setActiveObjective(1);
		setActiveDialogueChunk && setActiveDialogueChunk('');
		setActiveIntObj && setActiveIntObj('');
		if(Objectives[0].subObjectives[6].completed == true){
			setDisplayEnd && setDisplayEnd(true);
		}
	};
	return (<>
		{activeObjective && Objectives[activeObjective-1].completed == true && activePannellumScene && 
		<BackToMapButton onClick={onBackToMap}>Terug naar hoofdmenu</BackToMapButton>
		}
	</>
	);
};

export default BackToMap;
import { CloseButton } from 'components/Buttons/Button';
import LevelSelect from 'components/LevelSelect';
import { useGlobalContext } from 'context/global';
import { avatars } from 'data/avatars';
import { DialogueChunk, dialogueChunks } from 'data/dialogues';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import Avatar from './Avatar';
import { ContinueDialogueButton, LevelStartButton } from './Buttons';
import Options from './Options';
import Textbox from './Textbox';

//#region Styled Components

const DialogueWindow = styled.div`
	${proportional}

	position: relative;

	padding: 30px;

    background-color: ${props => props.theme.colors.accent_1};
	z-index: 5;
`;

const DialogueContainer = styled.div`


	z-index: 5;
	user-select: none; 



	@media ${p => p.theme.devices.mobile} {
		max-width: 50vw;
		margin: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		max-width: 28vw;
		margin: 30px;

	};

`;

const DialogueWrapper = styled.div `
	position: absolute;
	left:0;
	top: 0;

	height: 100%;
	width: fit-content;

	overflow-y: auto;
	overflow-x: visible;

	/* background-color:  rgba(0,0,0,0.1); */

	z-index: 6;

	//pointer-events: none;

	& > * {
		pointer-events: all;
	}
`;

const BlockInput =  styled.div`
	position: absolute;
	left:0;
	top:0;
	right:0;
	bottom:0;

	width:100vw;
	height: 100vh;

	background: rgba(0,0,0,0.7);

	z-index: 5;
`;

//#endregion

const OverlappingContent = styled.div`
	position: absolute;
	left:0;
	top:0;
	right:0;
	bottom:0;

	z-index: 5;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}

`;

const CloseButtonStyled = styled(CloseButton)`
	transform: translateY(-120%);
	right:0;
	top:0;
	margin: 0;
`;

const Dialogue = () => {

	const {optionsActive, activeLevel, activeDialogueChunk, setActiveDialogueChunk, setDialogueBlocksInteraction, dialogueBlocksInteraction } = useGlobalContext();

	// on click dialogue component

	const proceed = () => {
		if(activeChunk){
			if(activeChunk.followUpChunk){
				setActiveDialogueChunk && setActiveDialogueChunk(activeChunk.followUpChunk);
			}
			else{
				close();
			}
		}
	};

	const proceedWithOption = (id) => {
		if(activeChunk && activeChunk.options){
			if(activeChunk.options[id].followUpChunk){
				setActiveDialogueChunk && setActiveDialogueChunk(activeChunk.options[id].followUpChunk);
			} else {
				setActiveDialogueChunk && setActiveDialogueChunk('');
			}
		}
	};

	// on close dialogue component

	const close = () => {
		setActiveDialogueChunk && setActiveDialogueChunk(undefined);
	};

	const [activeChunk, setActiveChunk] = useState<DialogueChunk | undefined>();

	useEffect(() => {
		if(activeDialogueChunk){
			setActiveChunk(dialogueChunks[activeDialogueChunk]);
		}
		else{
			setActiveChunk(undefined);
		}

	}, [activeDialogueChunk]);

	useEffect(() => {
		if(activeChunk){
			if(activeChunk.levelSelectChunk){
				setDialogueBlocksInteraction && setDialogueBlocksInteraction(false);
			}
			else if(!dialogueBlocksInteraction){
				setDialogueBlocksInteraction && setDialogueBlocksInteraction(true);
			}
			return () => {
				setDialogueBlocksInteraction &&  setDialogueBlocksInteraction(false);
			};
		}
	}, [activeChunk]);

	return(
		<>
			{/* Only renders when activeDialogueChunk is defined */}

			{ activeChunk != undefined && 
				<>
					{ activeChunk.blockInput &&
					<BlockInput/>
					}
					<DialogueWrapper>
	
						<DialogueContainer>



							{/* Avatar */}

							{ activeChunk.avatarId != null && avatars[activeChunk.avatarId].image != '' &&
					<Avatar img={avatars[activeChunk.avatarId].image}/>
							} 

							{/* Dialogue Window (yellow box) */}

							<DialogueWindow>
								{ activeChunk.levelSelectChunk &&
								<CloseButtonStyled onClick={() => {setActiveDialogueChunk && setActiveDialogueChunk('');}}/>
								}	
								{/* Text Box (name, dialogue and footer) */}

								<Textbox chunk={activeChunk} proceed={proceed} />

								{/* Options */}
								{ activeChunk.options &&
							<Options 
								options={activeChunk.options}
								onSelectOption={(id) => proceedWithOption(id)}
							/>
								}
								<OverlappingContent>
									{ activeChunk.levelSelectChunk ?
										<LevelStartButton/> : !activeChunk.options && !activeChunk.blockContinue ?
											activeChunk.avatarId !== 7 && <ContinueDialogueButton proceed={proceed}/> : ''
									}
								</OverlappingContent>

							</DialogueWindow>

						</DialogueContainer>

					</DialogueWrapper>
				</>
			}
		</>
	);
};

export default Dialogue;


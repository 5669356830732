import { gladheidbestrijding, hoogtedetectie, ongevallenvoorspellen, slimmebruggen, slimmeverkeerslichten } from 'assets/images';
import { Snelweg_01filter } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Snelweg_01filter,
	pitch: 0,
	yaw: 0
};

let customHotspots  =	[
	{
		
		'pitch': -2.24960,
		'yaw': 90.50219,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 18,
		}
	},
	
	{
		'pitch': -7.702028,
		'yaw': 134.44004,
		'action': 'custom',
		'createTooltipArgs': {
			'titel': 'Slimme verkeerslichten',
			'thumbnail': slimmeverkeerslichten,
			'position' : 'above',
			'index': 4,
			// 'text':'content.hoveritems.wegtablet1',
			// 'param': 'wegtablet1'
		}
	},	{
		'pitch': 11.6636,
		'yaw': 39.42016,
		'action': 'custom',
		'createTooltipArgs': {
			'titel': 'Ongevallen voorspellen',
			'thumbnail': ongevallenvoorspellen,
			'position' : 'beneath',
			'index': 3,
			// 'text':'content.hoveritems.wegtablet1',
			// 'param': 'wegtablet1'
		}
	},	{
		'pitch': -5.868,
		'yaw': -45.149,
		'action': 'custom',
		'createTooltipArgs': {
			'titel': 'Gladheidbestrijdng',
			'thumbnail': gladheidbestrijding,
			'position' : 'above',
			'index': 2,
			// 'text':'content.hoveritems.wegtablet1',
			// 'param': 'wegtablet1'
		}
	},
	{
		'pitch': 9.89442,
		'yaw': 83.4866,
		'action': 'custom',
		'createTooltipArgs': {
			'titel': 'Slimme bruggen',
			'thumbnail': slimmebruggen,
			'position' : 'above',
			'index': 5,
			// 'text':'content.hoveritems.wegtablet1',
			// 'param': 'wegtablet1'
		}
	},


];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene111 = {
	sceneId: '111',
	config: getConfig(customConfig),

};	

export default Scene111;
import { CloseButton } from 'components/Buttons/Button';
import Close from 'components/Buttons/CloseButton';
import { useGlobalContext } from 'context/global';
import { useEffect, useState } from 'react';
import { theme } from 'style';
import styled, { keyframes } from 'styled-components';

interface DataOverlayProps {
    data: {
        id: number
        video: string
        title: string
        description: string
        info?: string
    }
    onClose: () => void;
}


 
const DataOverlay = (props: DataOverlayProps) => {

	const [closing, setClosing] = useState<boolean>();
	const {setActiveDialogueChunk} = useGlobalContext();

	useEffect(()=>{
		return(() => {
			setActiveDialogueChunk && setActiveDialogueChunk(`l_04_question_0${props.data.id}`);
		});
	},[]);


	return (  
		<Container
			opacity={closing ? 0 : 1}
			onTransitionEnd={() => props.onClose()}
		>
			<Content>
				<Title>
					{props.data.title}
				</Title>
				<Description>
					{props.data.description}
				</Description>
				<iframe src={'https://player.vimeo.com/video/'+props.data.video}  width="100%" height="100%" frameBorder="0" allow="autoplay" allowFullScreen></iframe>
			</Content>
			<CloseButton onClick={() => setClosing(true)}/>
			{ props.data.info &&
				<InfoButton onClick={()=> { setActiveDialogueChunk && setActiveDialogueChunk(props.data.info);}}>?</InfoButton>
			}
		</Container>
	);
};

//#region Styled Components

const InfoButton = styled.button`

    color: ${theme.colors.accent_2};
	font-weight: bold;
    font-size: 2rem;

    position: absolute;
    right:0;
    bottom:0;
    margin:25px;

    border: none;

    height: 50px;
    width: 50px;

    background: white;

    display:flex;
    justify-content: center;
    align-items: center;

    &:hover, :focus {
		background-color: ${theme.colors.hover_bg};
		color: ${theme.colors.hover};
		border:none;
		outline:none;
	}
    cursor:pointer;

`;

const Title = styled.div`
    font-size: 4vh;
    color:white;
    font-weight: bold;
    margin-bottom: 2%;
    width: 100%;
`;

const Description = styled.div`
    color: white;
    font-size: 3vh;
    margin-bottom: 3%;
    width: 100%;

`;

const ContainerKF = keyframes`
    0% {
        opacity: 0;
        pointer-events: none;
    }
    99%{
        pointer-events: none;
    }
    100% {
        opacity: 1;
        pointer-events: all;
    }
`;

const Container = styled.div<{opacity: number}>`
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom: 0;
    opacity: ${p => p.opacity};

    background: rgba(0,0,0,0.5);
    text-align: center;
    /* margin-left: 130px;
    margin-right: 130px; */


    @media ${p => p.theme.devices.mobile} {
		padding: 15px;
	};

	@media ${p => p.theme.devices.desktop} {
		padding: 30px;
	};

    z-index: 6;

    transition: opacity 1s;
    animation: ${ContainerKF} 1s;
`;



const Content = styled.div`

    margin-left: 130px;
    margin-right: 130px;   
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    height: 100%;

    & > iframe {
        /* background: rgba(0,0,0,0.5); */
        /* margin-top: 15px; */
    }

`;

//#endregion
 
export default DataOverlay;
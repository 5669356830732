import { EndScreenImage, LogoImage } from 'assets/images';
import { useGlobalContext } from 'context/global';
import { useEffect } from 'react';
import styled from 'styled-components';
import Pdf from '../assets/flyer/flyer.pdf';
import {SCORM} from 'pipwerks-scorm-api-wrapper';


const EndScreen = (props) => {

	const {displayEnd} = useGlobalContext();

	useEffect(() => {
		if(displayEnd){
			// SCORM.set('cmi.completion_status', 'completed');
			SCORM.set('cmi.core.lesson_status', 'completed');
			SCORM.save();
		}
	},[displayEnd]);

	return(
		<>
			{ displayEnd &&
				<Container>
					<Image/>
					<ContentContainer>
						<Logo/>
						<ScrollView>
							<Content>
								<Header>
									Gefeliciteerd! <br/> Je hebt de game: Ontdek data! succesvol doorlopen.
								</Header><br/>
								<Paragraph>
									{'Je hebt vanuit verschillende perspectieven en rollen met het gebruik van data kennis gemaakt. Je hebt zelf ervaren wat er allemaal met data kan. Dit is slechts een klein onderdeel van alle toepassingsmogelijkheden die er zijn met data. De meest waardevolle grondstof is niet langer olie, maar data! Ben jij (nog) enthousiast(er) geworden over data? Ga het gesprek eens aan met je collega’s over jouw werk in combinatie met data. Op welke manier kun jij datagedreven gaan werken en hoe kun je data voor jou én je projecten laten werken?'} 
								</Paragraph><br/>
								<Paragraph>
									Wil je je kennis op het gebied van data vergroten kijk dan op het leerportaal. Hier worden diverse data-opleidingen, trainingen, webinars etc. aangeboden. Hier kun je ook alle opleidingen vinden die worden aangeboden via de leerlijn IV (Informatievoorziening), <a href = {Pdf} target = "_blank" rel="noreferrer">zie flyer</a>. Heb je vragen over je opleidings- of ontwikkelbehoefte maak dit bespreekbaar bij je leidinggevende. Je kunt je vragen ook stellen aan het KCC (088 - 797 07 77 of kcc@rws.nl). 
								</Paragraph><br/>
								<Paragraph dangerouslySetInnerHTML={{__html: 'Kijk ter afsluiting en inspiratie nog deze <a href="https://vimeo.com/661961501/e1b6ab49d0" target="_blank">video</a> en weet: data is er altijd én overal. Leer hoe je data kunt inzetten en gebruiken.'}}>	
								</Paragraph><br/>
								<Paragraph>
									{'Bedankt voor het spelen. Sluit het tabblad om de game te beëindigen en terug te keren naar de leeromgeving.'}
								</Paragraph><br/>
							</Content>
						</ScrollView>
					</ContentContainer>
					
				</Container>
			}
		</>
	);
};

// styled 

const Container = styled.div`
    position: absolute;
    left:0;top:0;right:0;bottom:0;

    display:flex;
    /* flex-direction: column; */

	z-index: 9000;

	& > * {
		flex: 1;
	}
`;

const Image =  styled.div`
    width: 50vw;

    background-image: url(${EndScreenImage});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	background-color: ${p => p.theme.colors.accent_2};
`;

const Logo = styled.div`

	position: absolute;
	top:0;
	left:-30px;

	/* width: 30 */
	width: 352.3px;
	height: 123px;

	background-image: url(${LogoImage});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

const ContentContainer = styled.div`

	position: relative;

    width: 50vw;

	padding-top: 183px;
	padding-left: 5%;
	padding-right: 5%;
	padding-bottom: 5%;

    background: ${p => p.theme.colors.accent_1};

	display: flex;

	justify-content: center;
`;

const ScrollView = styled.div`

	text-align: center;
	overflow-y: auto;

	width: 100%;
	padding-right: 10px;

`;

const Content = styled.div`
	
`;

const Header =  styled.h1`
	color: ${p => p.theme.colors.accent_2};
`;

const Paragraph = styled.p`
	font-size: 1.25rem;
`;
 
export default EndScreen;
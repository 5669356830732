import { Snelweg_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Snelweg_01,
	pitch: 0,
	yaw: 10
};

let customHotspots  =	[
	{	
		'pitch': -1.38,
		'yaw': 13.081,
		'action': 'filter',
		'createTooltipArgs': {
			sceneId: 16,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene5 = {
	sceneId: '11',
	config: getConfig(customConfig),

};	

export default Scene5;

import { gif } from 'assets/images';
import { useState } from 'react';
import { useResponsiveFontSize } from 'react-responsive-font-size';
import styled, { keyframes } from 'styled-components';
import { FilterProps } from '..';
import materialScans from './data';

interface SearchFilterProps {
    img: string;
}
 
const SearchFilter = (props: SearchFilterProps & FilterProps) => {
	const [load, setLoad] = useState<boolean>(false);

	const scan = (index) => {
		setLoad(true);
		setTimeout(() => {
			materialScans[index].complete = true;
			setLoad(false);
			checkIfComplete();
		}, 3500);
	};
	const checkIfComplete = () =>{
		if (materialScans[0].complete == true && materialScans[1].complete == true && materialScans[2].complete == true) {
			props.onCompletion();
		}
	};
	return (  
		<Container>{load && <ScanningStatus >Bezig met meten{[...Array(3)].map((x, index) => <span key={`${index}-dots-filter-scan`}>.</span>)}</ScanningStatus>}
			{materialScans.map((materialScan, index) => (
				<Material top={materialScan.top} left={materialScan.left}  key={`${index}-${materialScan}-dot1`}>
					{materialScans[index].complete ? 
						<Image heigth={materialScan.heigth} width={materialScan.width} img={materialScan.img}></Image>
						:
						load == false && <Button onClick={() => scan(index)}></Button> }
				</Material>
			))}	
		</Container>
	);
};
const DotAnimation = keyframes`
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`;

const Button = styled.button`
	position: absolute;
	top:50%; 
	height: 15%;
	width: 10%;
	opacity: 0.1;
	border: none;
	border-radius:50%;
	cursor: pointer;
`;
const Loading = styled.div`
	width: 50px;
	height: 50px;
	background-image:url(${gif});
	background-size: cover;
	cursor: pointer;
	position: absolute;
`;

interface materialprops {
	top:string
	left: string
}
const ScanningStatus = styled.div`
	position: absolute;
	left:50%;
	top:50%;
	transform: translate(-50%,-50%);
	font-size: 5vh;

	& > span {
		font-size: 1.2em;
		animation: ${DotAnimation} 2s infinite;
	}
	& :nth-child(2){
		animation-delay: 400ms;
	}
	& :nth-child(3){
		animation-delay: 600ms;
	}
	
`;
const Material = styled.div<materialprops>`
display:flex;
justify-content: center;
position: absolute;
left: ${p => p.left};
top: ${p => p.top};
height: 30%;
width: 30%;
&:hover {
    ${Button} {
      opacity: 1;
    }
}
`;

interface imageprops {
	img: string
	width: string
	heigth: string

}

const Image = styled.div<imageprops>`
//position: absolute;
background-image: url(${p => p.img});
background-repeat: no-repeat;
background-size: cover;
height: ${p => p.heigth};
width: ${p => p.width};

`;

const Container = styled.div`
position: absolute;
left:0;
top:0;
bottom: 0;
right:0;
pointer-events: none;
& > * {
	pointer-events: all;
}
`;

export default SearchFilter;
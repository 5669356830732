import { OrbitControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useThreeContext } from 'context/three';
import { stat } from 'fs';
import { useEffect, useRef, useState } from 'react';

const Controls = () => {

	const {camera} = useThree();
	
	const controls = useRef<any>(null);

	// const {setControls} = useThreeContext();

	// useEffect(() => {
	// 	if(controls.current){
	// 		setControls && setControls(controls.current);
	// 	}
	// 	return () => {
	// 		setControls && setControls(null);
	// 	};
	// },[]);
	
	const { controlsActive } = useThreeContext();
	
	return (<>
		{ controlsActive && <OrbitControls 
			ref={controls}
			camera={camera}
			addEventListener={undefined} 
			hasEventListener={undefined} 
			removeEventListener={undefined} 
			dispatchEvent={undefined}	
			// minAzimuthAngle={(-Math.PI /2) + 0.2} 
			// maxAzimuthAngle={(Math.PI / 2) - 0.2 }
		/>}
	</>
	);
};


export default Controls;
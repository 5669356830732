import React, { useEffect, useRef, useState} from 'react';

import { Content, ContentWrapper} from '../styles';
import { Water1} from 'assets/images';
import { useGlobalContext } from 'context/global';
import { Objectives, setSubCompleted } from 'data/objectives';
import { dots } from './Data';
import styled from 'styled-components';
import Theme from 'style/theme';
import { Location } from 'assets/icons';
import { motion } from 'framer-motion';
import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';

interface DotsProps {
	top : string
	left : string
	selected: boolean
}

const Dots = styled.div<DotsProps>`
	position: relative;
	left: ${p => p.left};
	top: ${p => p.top};
	height: 3%;
	width: 2%;
	border-radius: 50%;
	background-color: black;
	//border: 10% solid ${Theme.colors.accent_2};
	cursor: pointer!important;
	&:hover {
	background-color: ${Theme.colors.hover};
	}
  `;

const Icon = styled.div`
  	background-image: url(${Location});
	background-position: center;
	background-size: cover;
	transform: translate(-20%,-80%);
	height: 25px;
	width: 25px;
  `;

const WaterScherm = () => {

	const { setActiveDialogueChunk, updateObjectiveData, setActiveIntObj } = useGlobalContext();
	const [activeImage, setActiveImage ] = useState<string>(Water1);
	const [select, setSelect] = useState<string>('AAA');

	const selectSlot = ( index, value ) => {
		setSelect(a => replaceAt(a, value, index));
	};
	
	const replaceAt = (string, replacement, index) => {
		return string.substring(0, index) + replacement + string.substring(index +1);
	};

	useEffect(() => {
		if(!Objectives[6].subObjectives[0].completed ){
			setActiveDialogueChunk && setActiveDialogueChunk('l_05_screen_01');
		}		

		return () => {

			if(Objectives[6].subObjectives[0].completed == true && Objectives[6].subObjectives[1].completed == true && Objectives[6].subObjectives[2].completed == true){
				setTimeout(() => {
					setActiveIntObj && setActiveIntObj('algoritmetablet');
				}, 1);			
			}
		};

	},[]);

	const setDotProps = (img, index) => {
		selectSlot(0, index);
		setActiveImage(img);
		dots[1].props[index].used = true;
		if (!Objectives[6].subObjectives[0].completed && dots[1].props[0].used == true && dots[1].props[1].used == true && dots[1].props[2].used == true) {
			setSubCompleted(6,0);
			updateObjectiveData && updateObjectiveData();
			setActiveDialogueChunk && setActiveDialogueChunk('l_05_screen_011');
		}
	};

	return (
		<>
			<TabletBorder>
				<ContentWrapper img={activeImage}>
					<Content>
						{dots[1].props.map((dot, index) => (
							<Dots 
								key={`${index}-${dot}-dot`} 
								selected={select[0] == index.toString() ? true : false} 
								left={dot.left} 
								top={dot.top} 
								onClick={() => setDotProps(dot.img, index)}>
								{select[0] == index.toString() ? 		
									<motion.div   
										animate={{y: [100,-100,0], opacity: [0,0,1] }}
										transition={{ duration: 0.5 }}><Icon/>
									</motion.div> : false} 
							</Dots>
						))}
					</Content>					
				</ContentWrapper>
			</TabletBorder>
		</>
	);
};

export default WaterScherm;
import { Controlroom_02 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Controlroom_02,
	pitch: 0,
	yaw: 10
};

let customHotspots  =	[
	{
		
		'pitch': -10.973,
		'yaw': -130.0573,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 9,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene4 = {
	sceneId: '10',
	config: getConfig(customConfig),

};	

export default Scene4;
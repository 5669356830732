import { CheckIcon, LockIcon } from 'assets/icons';
import { Karl, LevelSelectImage, LevelSelectImageSimple } from 'assets/images';
import DialogueInterface from 'components/Dialogue';
import { useGlobalContext } from 'context/global';
import { dialogueChunks } from 'data/dialogues';
import {default as _Levels} from 'data/levels';
import React, { createRef, ReactChild, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchProps, ReactZoomPanPinchHandlers, ReactZoomPanPinchRef } from 'components/react-zoom-pan-pinch';
import styled, { css } from 'styled-components';
import LevelButton from './components/LevelButton';
import Lock from './components/Lock';

const LevelSelectBackground = styled.div`
    background-image: url(${LevelSelectImageSimple}) ;
    background-position: center;
    background-size: contain;
	background-repeat: no-repeat;

	position: absolute;
	left:0;
	top:0;
	bottom:0;
	right:0;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;

/* 
	pointer-events: none;

	& > * {
		pointer-events: all;
	} */

	/* transform: translate(-50%, -50%); */
/* 
    height: 100vh;
	width: 100vw;
	max-width: 113.11827957vh;
	max-height: 88.403041825vw; */
`;

const LevelSelectImageComponent = styled.img`

	/* outline: solid 4px red; */

    height: 100vh;
	/* max-height: 88.403041825vw; */
	width: 100vw; // DEPRECATED: Calulated based on aspect ratio and base resolution ( (height / width) * width [which is 150vw])
	/* max-width: 113.11827957vh; */

`;

const LevelIconsFrame = styled.div`

	position: relative;

    height: 100%;
	width: 100%;
	max-width: 113.11827957vh;
	max-height: 88.403041825vw;

	/* outline: solid 2px red */

`;

const BlockTransformWrapper = styled.div`
	position: absolute;
	left:0;
	top:0;
	bottom:0;
	right:0;
	/* background-color: rgba(255,0,0,0.5); */
	z-index: 5;
`;

const style = {
	maxWidth: '100vw',
	maxHeight: '100vh',
	zIndex: 3,
};

interface Size {
    width: number;
    height: number;
}

const zoomAnimationTime = 500;

const LevelSelect = () => {

	const { t } = useTranslation();
	const {activeDialogueChunk, levelSelectActive, setActiveObjective, activeLevel, dialogueBlocksInteraction, setActiveDialogueChunk, activePannellumScene, setActiveLevel } = useGlobalContext();

	const wrapper = createRef<ReactZoomPanPinchRef>();

	const [blockTransform, setBlockTransform] = useState<boolean>(false);
	const [Levels, setLevels] = useState(_Levels);

	useEffect(() => {
		if(!activeDialogueChunk && levelSelectActive){
			wrapper.current?.setTransform(0,0,1,zoomAnimationTime);
			setActiveLevel && setActiveLevel(-1);

			setTimeout(() => {
				setBlockTransform(false);
			}, zoomAnimationTime);
		}
	}, [activeDialogueChunk]);

	const onSelect = (level) => {
		if (Levels[level].locked == false) {

			if(dialogueBlocksInteraction){
				return;
			}
			
			setActiveLevel && setActiveLevel(level);


			// zoom animation to object 

			if(wrapper.current){
				wrapper.current.zoomToElement(`level-${level}`,3,zoomAnimationTime);
				setBlockTransform(true);
			}

			// setActiveObjective && setActiveObjective(level+2);
			if (Levels[level].firstTime == true) {
				setActiveDialogueChunk && setActiveDialogueChunk(Levels[level].levelSelectDetails.firstTimeDialogue);
				Levels[level].firstTime = !Levels[level].firstTime;

			
			}  else if(!Levels[level].completed) {
				setActiveDialogueChunk && setActiveDialogueChunk(Levels[level].levelSelectDetails.otherDialogue);

			}
			else{
				setActiveDialogueChunk && setActiveDialogueChunk(Levels[level].levelSelectDetails.completedDialogue);
			}
		}
	};


	return(<>
		{levelSelectActive &&
		<>
			<TransformWrapper 
				ref={wrapper}
				centerOnInit={true} 
				doubleClick={{disabled:true}} 
				panning={{disabled : true}}
				pinch={{disabled: true}}
				wheel={{disabled: true}}
				zoomAnimation={{disabled:true}}
				alignmentAnimation={{disabled: true}}
				velocityAnimation={{disabled: true}}
			>
				<TransformComponent wrapperStyle={style}>
					{/* The actual image used for zoom pan pinch, but is not visible. This component dictates the size of the pannable area. */}
					<LevelSelectImageComponent src={LevelSelectImageSimple} style={{opacity: '0'}} />
					{/*  */}
					<LevelSelectBackground>
						<LevelIconsFrame>
							{ Levels && Levels.map( (level, index ) => {
								return(							
									<LevelButton
										key={`level-${index}-${level.id}`}
										img={level.levelSelectDetails.image}
										top={level.levelSelectDetails.imageTop} 
										left={level.levelSelectDetails.imageLeft}
										locked={level.locked}
										completed={level.completed}
										selected={activeLevel === level.id}
										hovertext={level.levelSelectDetails.name}
										level={level}
										index={index}
										onSelect={(i) => onSelect(i)}
									>
									</LevelButton>
								);		
							})
							}
						</LevelIconsFrame>

					</LevelSelectBackground>
				</TransformComponent>
			</TransformWrapper>
			{ blockTransform &&
			<BlockTransformWrapper/>
			}
		</>
		}
	</>
	);
};

export default LevelSelect;

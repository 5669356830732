import { CheckIcon, CheckIconBlue } from 'assets/icons';
import { subObjectives } from 'context/global/types';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface SubObjProps {
	completed?: boolean | undefined;
    objective?: subObjectives | undefined;
    children?: ReactNode;
}

const SubObjBox = styled.div<SubObjProps>`
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;

    /* background-color:  */

    outline: solid 2px ${p => p.theme.colors.accent_2};

    display: flex;
    margin-right: 10px;

    & > div {

        ${p => !p.completed && css`
            opacity: 0;
        `}

        margin: auto;
        height: 80%;
        width: 80%;
        background-image: url(${CheckIconBlue});
        background-size: cover;
        background-position: center;

        //background-color: ${p => p.theme.colors.accent_2}; 
    }
`;

const SubObjText = styled.div<SubObjProps>`
    line-height: 100%;

    ${p => p.completed && css`
        //text-decoration: line-through;
        opacity: 0.25;
    `}
`;

const SubObjective = styled.div<SubObjProps>`
	font-size: 1.2rem;
	padding-right: 10px;


	display: flex;
	align-items: center;
	margin-bottom: 15px;
`;

const SubObj = ({completed, objective}: SubObjProps) => {
	return (
		<SubObjective completed={completed}>
			<SubObjBox completed={completed}><div/></SubObjBox>
			<SubObjText completed={completed}>{objective?.text}</SubObjText>
		</SubObjective>
	);
};
export {SubObjBox};
export default SubObj;
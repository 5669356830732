import { Kantoor_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Kantoor_01,
	pitch: -144.779,
	yaw: -6.4657
};

let customHotspots  =	[
	{
		'pitch': -11.9687,
		'yaw': -135.51685,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 15,
		}
	},

	// 	'pitch': -25.5,
	// 	'yaw': 47.9,
	// 	'action': 'openModel',
	// 	'createTooltipArgs': {
	// 		'type': 'scene',
	// 		'text': 'content.hoveritems.1',
	// 		'param': 'ball',
			
	// 	}
	// },
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene1 = {
	sceneId: '14',
	config: getConfig(customConfig),

};	

export default Scene1;
import Theme from 'style/theme';
import styled from 'styled-components';

const ContentWrapper = styled.div`
	background-color: ${Theme.colors.accent_1}; 
	display:flex;
	flex-direction: column;
	width: 1384px;
	height: 826px;

	user-select: none;
`;

const Content = styled.div`
	& > * {
		padding: 10px;
     }
	height: 90%;
	width: 90%;
	margin: auto;
	position: relative;

`;

export {ContentWrapper, Content};
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { scheurkalender } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    Cube291: THREE.Mesh
    Cube291_1: THREE.Mesh
    Cube291_2: THREE.Mesh
    Cube291_3: THREE.Mesh
  }
  materials: {
    Date: THREE.MeshStandardMaterial
    material: THREE.MeshStandardMaterial
    MetalRings: THREE.MeshStandardMaterial
    CalendarDate: THREE.MeshStandardMaterial
  }
}

export default function Scheurkalender({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(scheurkalender) as GLTFResult;
	return (
		<group rotation-y={4.8} scale={4} ref={group} {...props} dispose={null}>
			<group scale={[0.66, 0.66, 0.54]}>
				<mesh geometry={nodes.Cube291.geometry} material={materials.Date} />
				<mesh geometry={nodes.Cube291_1.geometry} material={materials.material} />
				<mesh geometry={nodes.Cube291_2.geometry} material={materials.MetalRings} />
				<mesh geometry={nodes.Cube291_3.geometry} material={materials.CalendarDate} />
			</group>
		</group>
	);
}

useGLTF.preload(scheurkalender);


import styled, { css } from 'styled-components';
import theme from 'style/theme';
import { PaddingWrapper } from '../../components/BasicHtml';
import { CrossIconHover, CrossIconNormal, info, infoHover } from 'assets/icons';
import { ReactNode, useEffect, useState } from 'react';
import{ VOPData } from 'data/vop';
import { useGlobalContext } from 'context/global';
import { Grafiek_sentiment_analysis_stap2 } from 'assets/images';
import { useResponsiveFontSize } from 'react-responsive-font-size';

interface VOPInterfaceProps {
	//children: ReactNode
    step: number
	data: VOPData,
	setStep: (val: number) => void;
	onComplete: () => void;
	id: number;

}
const VOPinterface = (props: VOPInterfaceProps) => {
	const {setActiveDialogueChunk, setActiveIntObj, setCloseButtonActive} = useGlobalContext();
	const [value, setValue] = useState<number>();
	const [showInfo, setShowInfo] = useState<boolean>(true);
	const [end, setEnd] = useState<boolean>(false);
	const responsiveFont = useResponsiveFontSize(0.25, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });
	const responsiveFont2 = useResponsiveFontSize(0.2, { setFontSize: true, globalVariableName: 'responsiveFont2', lockFontSize: false });
	const responsiveFont3 = useResponsiveFontSize(0.1, { setFontSize: true, globalVariableName: 'responsiveFont3', lockFontSize: false });

	const next = () => {
		//next step check
		if (props.data.steps[props.step+1]) {
			//first step active check
			if (props.step == -1) {
				if (value == props.data.firstStepCorrect) {
					setActiveDialogueChunk && setActiveDialogueChunk(`l_06_monitor${props.id + 1}_correct${props.step + 2}`);
					props.setStep(props.step+1);

				} else {
					setActiveDialogueChunk && setActiveDialogueChunk(`l_06_monitor${props.id + 1}_wrong${props.step + 2}`);
				}
			//questions check
			} else {
				if (value == props.data.steps[props.step].correctAnswer) {
					setActiveDialogueChunk && setActiveDialogueChunk(`l_06_monitor${props.id + 1}_correct${props.step + 2}`);
					props.setStep(props.step+1);
				} else {
					setActiveDialogueChunk && setActiveDialogueChunk(`l_06_monitor${props.id + 1}_wrong${props.step + 2}`);
				}
			}			
		}		
		//no steps left	
		else {
			props.onComplete();
		} 	

		setValue(undefined);
		// if(props.step === props.data.steps.length - 2){
		// 	setEnd(true);
		// }
	};

	useEffect(() => {
		if(props.step === props.data.steps.length - 1){
			setEnd(true);
		}
	},[props.step]);

	const handleRadioChange = (i) => {
		setValue(i);
	};

	return(
		<>
			<PaddingWrapper style={{padding: 0, borderRadius:'0', backgroundColor: `${theme.colors.accent_1}`}}>
				<Yellow>
					<Title ref={responsiveFont2}>{props.data.question}</Title>
					{showInfo ? <CloseInfo onClick={() => setShowInfo(false)}/> :
						<OpenInfo onClick={() => setShowInfo(true)}/>}
				</Yellow>
				<Blue>
					<BlueBackdrop/>
					<Container>
						{showInfo ? <InfoPicture img={props.data.info} /> :					
							<>
								{props.step == -1 ?
									<NogEenContainer>	
										<NogEenTitle ref={responsiveFont}>{props.data.firstStepTitle}</NogEenTitle>
										<FirstStepContainer>
											{props.data.firstStepData.map((FirstStep, index) => {
												return (
													<FirstStepBox key={index}>
														<Cirkel selected={value == index? true : false} onClick={() => setValue(index)} img={FirstStep.image}>
														</Cirkel>
														<TextBox>
															{FirstStep.description}
														</TextBox>
													</FirstStepBox>
												);
											})}
										</FirstStepContainer>
										<ButtonRow2><Button margin={true} disabled={value !== undefined ? false: true} onClick={next}>Volgende</Button></ButtonRow2>
									</NogEenContainer>	
									:
									<>
										<LeftColumn>
											<Image img={props.data.steps[props.step].image}/>
										</LeftColumn>
										<RightColumn >
											<Title2 ref={responsiveFont}>{props.data.steps[props.step].title}</Title2>
											{props.data.steps[props.step].options?.map((option, index) => {
												return(
													<Option key={index}>
														<div>{option.param}</div>
														<CustomRadio checked={index == value ? true : false} onClick={(e) => {e.currentTarget.blur(); handleRadioChange(index);}}><div/></CustomRadio>
													</Option>
												);
											})}	
											<ButtonRow><Button margin={false} disabled={end ? false : value !== undefined ? false: true} onClick={next}>Volgende</Button></ButtonRow>
										</RightColumn>
									</>}
							</>}
					</Container>
				</Blue>
			</PaddingWrapper>	
		</>
	);
};
interface InfoPictureProps {
	img : string
}

//#region Styled Components

const CustomRadio = styled.button<{checked:boolean}>`

	position: relative;

	border-radius: 100%;
	background-color: white;

	border: none;
	outline:none;

	height: 20%;
	width: 5%;

	display: flex;
	align-items: center; justify-content: center;

	&:hover > div, :focus > div {
		position: absolute;
		height: 80%;
		width: 80%;

		border-radius: 100%;

		/* opacity: 0.5; */

		background-color: ${theme.colors.accent_2};
	}

	${p => p.checked && css`
		& > div{

			position: absolute;
			height: 80%;
			width: 80%;

			border-radius: 100%;

			/* opacity: 1; */

			background-color: ${theme.colors.accent_2};

		} 	
	`}

`;

const Input = styled.input`
        width: 30px;
        height: 30px;
		outline:none;
        border: none!important;
        margin: auto; 
`;

const NogEenTitle = styled.div`
//position:absolute;
	font-weight: bold;
	text-align: center;
	padding: 2%;
	height:10%;
	z-index: 2;
	top: 0;
`;

const NogEenContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const FirstStepContainer = styled.div`
height:70%;
width:100%;
display: flex;

/* justify-content:center; */
/* align-items: center; */
justify-content: space-evenly;

`;

const Container = styled.div`
	height: 100%;
	width:100%;
	display: flex;
	justify-content:center;
	align-items: center;
`;

const InfoPicture = styled.div<InfoPictureProps>`
	background-image: url('${p => p.img}');
	background-size: cover;
	/* margin-top: 5%; */
	text-align: center;
	width:50%;
	height: 90%;
	z-index: 5;
`;

const Option = styled.div`
	//margin: 15px;
	height: 20%;
	width: 100%;
	display: flex;
	align-items: center;

	& > div {
		width: 100%;
		display: flex;
		/* justify-content: center; */
		align-items: center;
		margin-right: 20px;
		font-size: 2vh;
		text-align: left;
	}
	/* justify-content: stretch;
	align-items: stretch; */
`;

const FirstStepBox = styled.div`

	height: 60%;
	width: 20%;

	z-index:2;

	/* margin-left:100px; */
	//font-size: 0.8em;
`;

interface cirkelProps {
	img : string
	selected : boolean
}

const Cirkel = styled.div<cirkelProps>`

	position: relative;
	background-image: url('${p => p.img}');
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-color: white;
	outline: 4px solid ${p => p.selected? theme.colors.accent_2 : 'none'};
	//margin: 15px;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	display: flex;
	//font-size: 0.8em;

	& > div {
		position: absolute;
		bottom: 10%;
		left:0;
		right:0;
		font-size: 0.6em;

		text-align: center;
		font-weight: bold;
		//margin: 0 35px;
	}
`;
const TextBox = styled.div`

	/* display: flex; */
	text-align: center;
	margin-top: 5%;
	font-size: 1.5vh;
`;

const ButtonRow = styled.div`
	z-index:2;
	width:100%;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: right;

	& :disabled {
		filter: grayscale(100%);
		pointer-events: none;
	}
`;

const ButtonRow2 = styled.div`
	z-index:2;
	width:100%;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;

	& :disabled {
		filter: grayscale(100%);
		pointer-events: none;
	}
`;

const CloseInfo =  styled.button`

	height: 50%;
	width: 7%;
	margin: 2%;
	border: none;

	background-image: url(${CrossIconNormal});
	background-size: 50%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;
	cursor: pointer;

	&:hover, :focus{
		background-color: #1cb1e0;
		background-image: url(${CrossIconHover});
	}

`;

const Button = styled.button<{margin: boolean}>`

	background-color: ${theme.colors.primary};
    color: ${theme.colors.accent_2};
    margin: 0;

	cursor: pointer;
    text-align: center;

    font-weight: bold;
	font-size: 3vh;
	border: none;

	width: 30%;
	height: 100%;
	margin-bottom: ${p => p.margin ? '5%' : ''} ;
	&:hover, :focus{
		color: ${theme.colors.hover};
		background-color: ${theme.colors.hover_bg};
	}

	& :disabled {
		filter: grayscale(100);
	}

`;
const LeftColumn = styled.div`
///background-color: blue;
	position: relative;
	height: 100%;
	width: 50%;
	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const RightColumn = styled.div`
//background-color: red;
	position: relative;
	width: 45%;
	height: 80%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

interface ImageProps {
img : string
}

const Image = styled.div<ImageProps>`
	background-image: url('${p => p.img}');
	background-size: contain;
	background-position: top left;
	background-repeat: no-repeat;
	position: relative;
	width: 80%;
	height: 80%;
`;

const Title2 = styled.div`
	font-size: 1em;
	color: black;
	font-weight: bold;
	height: 20%;
	text-align: left;
	display: flex;
	justify-content: right;
`;

const Title = styled.div`
	color: ${theme.colors.accent_2};
	text-align: center;
	font-weight: bold;
	width: 90%;
	//position: relative;
	//font-size:0.8em;
	//padding-bottom: 2%;
	
	padding: 1% 2% 2% 2%;
`;

// Top Wrapper
const Yellow = styled.div`
	height: 20%;
//margin-right: 150px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* align-items: center; */
`;

// Bottom Wrapper
const Blue = styled.div`

	position: relative;

	height:80%;
	
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const BlueBackdrop = styled.div`
	position: absolute;
	left:0;
	top:2%;
	bottom:0;
	right:0;

	background-color: ${theme.colors.accent_4};
	border-top-left-radius: 2%;
	border-top-right-radius: 2%;

	opacity: 0.5s;

	z-index: 0;
`;
//help icon
const OpenInfo = styled.button` 
	height: 60%;
	width: 7%;
	margin: 2%;

	border-radius: 100%;

	background-image: url(${info});
	background-size: 60%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;

	border: none;
	cursor: pointer;

	z-index: 5;

	&:hover, :focus{
		background-color: #1cb1e0;
		background-image: url(${infoHover});
	}

`;
//#endregion

export default VOPinterface;

import { GlobalContext } from 'context/global';
import { createContext, useContext, useRef, useState } from 'react';
import { contextProps, threeContextProps } from './types';

const ThreeContext = createContext<Partial<threeContextProps>>({});

const ThreeContextProvider = (props: contextProps) => {

	//#region useStates

	const [controlsActive, setControlsActive ] = useState<boolean>(true); 

	const controls = useRef();
	
	const setControls = (newRef) => {
		controls.current = newRef;
	};

	//#endregion

	//#region Passed Props

	const passedFunctions = {
		controls,
		controlsActive
	};

	const passedValues = {
		setControls,
		setControlsActive
	};

	//#endregion

	const globalContextValues = useContext(GlobalContext);

	//#render

	return (
		<ThreeContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</ThreeContext.Provider>
	);

	//#endregion
};

const useThreeContext = () => useContext(ThreeContext);

export {ThreeContextProvider, ThreeContext, useThreeContext};
import { KantoorIcon01, KantoorIcon02, KantoorIcon03, SeaIcon, SluisIcon, VaarwegIcon, WegIcon } from 'assets/icons';

export type Level = {
	id: number;
	startingPannellumScene: number,
	startingDialogue?: string,
	firstTime: boolean;
	completed: boolean;
	locked: boolean;
	objectiveId?: number;
	unlockRequirements: number[];
	levelSelectDetails: {
		image: string,
		imageTop: string,
		imageLeft: string,
		name: string,
		firstTimeDialogue: string,
		otherDialogue: string,
		completedDialogue: string,
	}
}


let Levels : Level[] =  [
	{
		id: 0,
		startingPannellumScene: 13,
		startingDialogue: 'l_00_tutorial_01',
		firstTime: true,
		completed: false,
		locked: false,
		unlockRequirements: [],  // Array of level indentifiers. 
		objectiveId: 2, // Identifier to determine what objective needs to be active during the level 
		levelSelectDetails: { // Details import for the LevelSelect component.
			image: KantoorIcon01, // Path to image
			imageTop: '40%',
			imageLeft: '40%',
			name: 'tutorial',
			firstTimeDialogue: 'l_00_first_01',
			otherDialogue: 'l_00_standard',
			completedDialogue: 'l_00_completed',

		}
	},
	{
		id: 1,
		startingPannellumScene: 7,
		startingDialogue: 'l_01_start_01',
		firstTime: true,
		completed: false,
		locked: true,
		unlockRequirements: [0],  // Array of level indentifiers. 
		objectiveId: 3, // Identifier to determine what objective needs to be active during the level 
		levelSelectDetails: { // Details import for the LevelSelect component.
			image: SluisIcon, // Path to image
			imageTop: '10%',
			imageLeft: '58%',
			name: 'sluis',
			firstTimeDialogue: 'l_01_first_01',
			otherDialogue: 'l_01_standard',
			completedDialogue: 'l_01_completed',
		}
	},
	{
		id: 2,
		startingPannellumScene: 1,
		startingDialogue: 'l_02_introduction_01',
		firstTime: true,
		completed: false,
		locked: true,
		unlockRequirements: [1],  // Array of level indentifiers. 
		objectiveId: 4, // Identifier to determine what objective needs to be active during the level 
		levelSelectDetails: { // Details import for the LevelSelect component.
			image: KantoorIcon02, // Path to image
			imageTop: '53%',
			imageLeft: '30%',
			name: 'Afdeling Communicatie',
			firstTimeDialogue: 'l_02_standard',
			otherDialogue: 'l_02_standard',
			completedDialogue: 'l_02_completed',
		}
	},
	{
		id: 3,
		startingPannellumScene: 5,
		startingDialogue: 'l_03_introduction_01',
		firstTime: true,
		completed: false,
		locked: true,
		unlockRequirements: [2],  // Array of level indentifiers. 
		objectiveId: 5, // Identifier to determine what objective needs to be active during the level 
		levelSelectDetails: { // Details import for the LevelSelect component.
			image: SeaIcon, // Path to image
			imageTop: '30%',
			imageLeft: '15%',
			name: 'zee',
			firstTimeDialogue: 'l_03_standard',
			otherDialogue: 'l_03_standard',
			completedDialogue: 'l_03_completed',
		}
	},
	{
		id: 4,
		startingPannellumScene: 11,
		startingDialogue: 'l_04_first_01',
		firstTime: true,
		completed: false,
		locked: true,
		unlockRequirements: [5],  // Array of level indentifiers. 
		objectiveId: 6, // Identifier to determine what objective needs to be active during the level 
		levelSelectDetails: { // Details import for the LevelSelect component.
			image: WegIcon, // Path to image
			imageTop: '35%',
			imageLeft: '70%',
			name: 'weg',
			firstTimeDialogue: 'l_04_standard',
			otherDialogue: 'l_04_standard',
			completedDialogue: 'l_04_completed',
		}
	},
	{
		id: 5,
		startingPannellumScene: 4,
		startingDialogue: 'l_05_start',
		firstTime: true,
		completed: false,
		locked: true,
		unlockRequirements: [3],  // Array of level indentifiers. 
		objectiveId: 7, // Identifier to determine what objective needs to be active during the level 
		levelSelectDetails: { // Details import for the LevelSelect component.
			image: VaarwegIcon, // Path to image
			imageTop: '52%',
			imageLeft: '55%',
			name: 'vaarweg',
			firstTimeDialogue: 'l_05_standard',
			otherDialogue: 'l_05_standard',
			completedDialogue: 'l_05_completed',
		}
	},
	{
		id: 6,
		startingPannellumScene: 9,
		startingDialogue: 'l_06_introductie_01',

		firstTime: true,
		completed: false,
		locked: true,
		unlockRequirements: [4],  // Array of level indentifiers. 
		objectiveId: 8, // Identifier to determine what objective needs to be active during the level 
		levelSelectDetails: { // Details import for the LevelSelect component.
			image: KantoorIcon03, // Path to image
			imageTop: '87%',
			imageLeft: '61%',
			name: 'control room',
			firstTimeDialogue: 'l_06_standard',
			otherDialogue: 'l_06_standard',
			completedDialogue: 'l_06_completed',
		}
	}
];

const checkLevelsForUnlock = () => {

	Levels.map((level) => {
		let unlocks = 0;
		level.unlockRequirements.map((req) => {
			if(Levels[req].completed){
				unlocks++;
			} 
		});
		if(unlocks >= level.unlockRequirements.length){
			level.locked = false;
		}
	});
};

export const setLevels = (saveData: any) => {
	if(! saveData || ! saveData.levels) return;
	
	Levels = saveData.levels;
	checkLevelsForUnlock();

};

// checkLevelsForUnlock();

export {checkLevelsForUnlock};

export default Levels;

import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Levels from 'data/levels';
import { ConsoleLabel, ConsoleRow } from './Styles';

const ConsoleLevelSelect = () => {

	const { t } = useTranslation();

	const selectTabRef = useRef<HTMLSelectElement>(null);
	//const { activeLevel, setActiveLevel } = useGlobalContext();
	const [activeLevel, setActiveLevel ] = useState<number>(0);
	const completedData = () => {
		Levels[activeLevel].completed = !Levels[activeLevel].completed;
	};
	const firsttimeData = () => {
		Levels[activeLevel].firstTime = !Levels[activeLevel].firstTime;
	};
	const lockedData = () => {
		Levels[activeLevel].locked = !Levels[activeLevel].locked;
	};


	return(
		<ConsoleRow>
			<ConsoleLabel>{'levels'}</ConsoleLabel>
			<select onChange={(e) => setActiveLevel && setActiveLevel(Number(e.target.value))} ref={selectTabRef} value={String(activeLevel)}>
				{Levels && Levels.map((opt, index) => (<option key={index} label={String(index)} value={index}/>))}
			</select>		
			<button onClick={firsttimeData}>Toggle first time</button>
			<button onClick={completedData}>Toggle completed</button>
			<button onClick={lockedData}>Toggle locked</button>
			<button onClick={() => {console.log(Levels);}}>log</button>

		</ConsoleRow>
	);
};

export default ConsoleLevelSelect;
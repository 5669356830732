import { reset } from 'assets/icons';
import Theme from 'style/theme';
import styled from 'styled-components';

const Container = styled.div`
	width: 55%;
	display:flex;
	justify-content: center;
`;
const ResultContainer = styled.div`
	//background-color: green; 
	display: flex;
	text-align:left;
	flex-direction: column;
	height: 50%;
	margin-top: 5%;
	font-size: 2.5vh;
`;
const ExpressionContainer = styled.div`
	//background-color: green; 
	display: flex;
	//text-align:left;
	font-size: 2.5vh;
	flex-direction: column;
	width:100%;

`;

const Container2 = styled.div`
	width: 45%;
	text-align:left;

`;
const HelpImg = styled.div<{img: string}>`
	background-image: url(${p => p.img});
	background-position: center;
	background-size: 100% 100%;
	background-color: white;
	background-repeat: no-repeat;
	width: 90%;
	height: 90%;
	margin-top: 5%;
`;

const Title = styled.div`
	color: black;
	font-size: 2.5vh;
	padding: 2% 2% 2% 0%;
	font-weight: bold;
	position: relative;
`;

const ExpressionRow = styled.div`
	//background-color: green; 

	display: flex;
	flex-direction: row;
	//justify-content: center;

	width: 100%;
	//sheight: 20%;
`;
const FlexBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 5%;
	margin-top: 1%;
	width: 100%;
	//height: 100%;
`;
const Box = styled.button<{	selected : boolean}>`
	//position: relative;
    background-color: white;
    width: max-content;
    height: 50%;
	text-align:center;
	border:none;	
	font-size: 3vh;
	color: ${Theme.colors.accent_2};
	font-weight: bold;
    cursor: pointer!important;
	//margin: 20px 0px 0px 20px;
	padding: 3%;

	&:hover{
		color: ${Theme.colors.hover};
		background-color: ${Theme.colors.hover_bg};;
	}
    `;

const ResultRow = styled.div`
	//background-color: green; 
	display: flex;
	flex-wrap: wrap;
	gap: 5%;

	width: 100%;
	//height: 20%;
	margin-top: 2%;
`;
const Row = styled.div`
	//background-color: green; 
	display: flex;
	flex-wrap: wrap;
	gap: 5%;
	flex-direction: row;
	margin-top: 2%;
	width: 100%;
	//height: 15%;
`;
const ButtonRow = styled.div`
	//background-color: green; 
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 5%;
	width: 100%;
	height: 50%;
`;

const ResultBox = styled.button`
	background-color: white;
	//height: 10%;
	width: 30%;
	text-align: center;
	border:none;
	//margin: 20px 0px 0px 20px;
	padding: 3%;
	color: black;
	font-size: 2vh;
	font-weight: bold;
`;

const PlaceHolderBox = styled.button<{bc : boolean}>`
	opacity: ${p => p.bc? 1 : 0.5 };
	outline: ${p => p.bc? '3px solid #1cb1e0' : 'none'};
	border: none;
	background-color: white;
	width: 30%;
	text-align: center;
	//margin: 20px 0px 0px 20px;
	padding: 3%;

	color: white;
	font-size: 2vh;
`;

const Go = styled.button`
	background-color: white;
	width: 18%;
	height: 60%;
	border-radius:50%;
	border:none;
	color: ${Theme.colors.accent_2};
	font-size: 2vh;
	font-weight: bold;
`;

const Reset = styled.button`
	background-image: url(${reset});
	background-size: 100% 100%;
	width: 18%;
	height: 60%;
	margin-left:20px;
	border-radius:50%;
	border: none;
	color: ${Theme.colors.hover};
	//font-size: 2rem;
	font-weight: bold;
`;

export {Container, ResultContainer, ExpressionContainer, Container2, Go, Reset, Box, PlaceHolderBox, ResultBox, ButtonRow, Row, ResultRow, FlexBox, Title, HelpImg, ExpressionRow};
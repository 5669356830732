import { Data_lijndiagram, Data_routekaart, Data_sentiment_analysis, Grafiek1_lijndiagram, Grafiek1_routekaart, Grafiek1_sentiment_analysis, Grafiek2_lijndiagram, Grafiek2_routekaart, Grafiek2_sentiment_analysis, Grafiek3_lijndiagram, Grafiek3_routekaart, Grafiek3_sentiment_analysis, Grafiek_lijndiagram_stap2, Grafiek_lijndiagram_stap3, Grafiek_lijndiagram_stap4, Grafiek_routekaart_stap2, Grafiek_routekaart_stap3, Grafiek_routekaart_stap4, Grafiek_routekaart_stap5, Grafiek_sentiment_analysis_stap2, Grafiek_sentiment_analysis_stap3, Grafiek_sentiment_analysis_stap4, Grafiek_sentiment_analysis_stap5 } from 'assets/images';

export interface VOPOptions {
	type: number; // 0 == div with text, 1 == image
	param: string; // Text or Image path
}


export interface VOPStep {
	options?: VOPOptions[]
	image: string; // Image seen at the left hand side of the screen
	title: string; // header text
	correctAnswer?: number; // index of correct option
	onWrongDialogue?: string; // reference to dialogueChunk
	onCompleteDialogue?: string; // reference to dialogueChunk
}

export interface VOPFirstStep {
	correctAnswer?: number; // index of correct option
	image: string;
	description: string;
	title: string;
}

export interface VOPData {
    question: string;
    steps: VOPStep[];
    info: string; // image seen when clicking on the 'i' icon.
    firstStepData: VOPFirstStep[]; 
	firstStepTitle: string;
	firstStepCorrect: number;
}

const data : VOPData[] = [
	{ // VOP Monitor 1 | Staafdiagram
		question: 'We hebben het sentiment van de burgers gemonitord vanaf het moment van botsing tot plaatsing van de nieuwe sluisdeur. Er moet worden geanalyseerd hoe de burgers de ontwikkeling van het incident hebben ervaren. Maak een datavisualisatie van de resultaten van de sentimentanalyse. Zie onderstaande opgehaalde data.',
		steps: [
			{ // Step 1
				options: [
					{
						type: 0,
						param: 'uren vanaf botsing & percentage aantal mediaberichten (%)'
					},
					{
						type: 0,
						param: 'tijd vanaf incident (dagen) & percentage aantal mediaberichten (%)'
					},
					{
						type: 0,
						param: 'mediaberichten (aantal) & aantal dagen'
					},
				],
				correctAnswer: 1,
				image: Grafiek_sentiment_analysis_stap2,
				title: 'De basis van de visualisatie staat. Een gestapelde staafdiagram in de kleuren die bij de huisstijl van Rijkswaterstaat passen. Heel belangrijk! Maar waar staan de assen voor?',
			},
			{ // Step 2
				options: [
					{
						type: 0,
						param: 'positief, negatief, neutraal'
					},
					{
						type: 0,
						param: 'negatief, neutraal, positief'
					},
					{
						type: 0,
						param: 'neutraal, positief, negatief'
					},
				],
				correctAnswer: 1,
				image: Grafiek_sentiment_analysis_stap3,
				title: 'De as-titels zijn nu bekend. Het is nog niet duidelijk waar de kleuren voor staan. Label de kleuren van boven naar beneden (geel, blauw, groen)',
			},

			{ // Step 3
				options: [
					{
						type: 0,
						param: 'Resultaat sentiment analysis '
					},
					{
						type: 0,
						param: 'Meningen burgers over de sluis situatie'
					},
					{
						type: 0,
						param: 'Sentiment van burgers over sluisincident'
					},
				],
				correctAnswer: 2,
				image: Grafiek_sentiment_analysis_stap4,
				title: 'De datavisualisatie is nu bijna compleet. Geef de visualisatie een passende en beschrijvende titel. ',
			},
			{ 	// Step 3
				image: Grafiek_sentiment_analysis_stap5,
				title: 'De visualisatie is af. Met behulp van deze visualisatie kunnen we analyseren hoe burgers het incident en de nasleep daarvan hebben ervaren.',
			},
		],
		info: Data_sentiment_analysis,
		firstStepData: [
			{
				title: 'gestapelde staafdiagram',
				image: Grafiek1_sentiment_analysis,
				description: 'Een gestapelde staafdiagram kan goed gebruikt worden bij ontwikkelen over tijd en de verhouding in die tijdsperiode te laten zien.'
			},
			{
				title: 'cirkeldiagram',
				image: Grafiek2_sentiment_analysis,
				description: 'Een cirkeldiagram wordt gebruikt om procentuele verdelingen makkelijk te lezen.'
			},
			{
				title: 'spreidingsdiagram',
				image: Grafiek3_sentiment_analysis,
				description: 'Een spreidingsdiagram wordt gebruik voor het aantonen van relaties tussen verschillende gegevensreeksen.'
			},
		],
		firstStepCorrect: 0,
		firstStepTitle: 'Datavisualisatie start bij het kiezen van de juiste diagramsoort voor de informatie die je wilt laten zien. Kies een passende diagram voor deze opdracht.'
	},  
	{ // VOP Monitor 2 | Lijndiagram
		question: 'Door de botsing van het schip met de sluis is stookolie in het water vrijgekomen. Dit moest snel opgeruimd worden. De vrijgekomen stookolie is geanalyseerd net als hoe lang het heeft geduurd voordat het water weer helemaal schoon was. Het is aan jou om deze data te visualiseren. Zie de bijbehorende opgehaalde data.',
		steps: [
			{ // Step 1
				options: [
					{
						type: 0,
						param: 'tijd na incident (uur) & hoeveelheid stookolie (liters)'
					},
					{
						type: 0,
						param: 'tijd na incident (uur) & percentage stookolie (liters)'
					},
					{
						type: 0,
						param: 'aantal minuten na incident (min) & aantal liters stookolie'
					},
				],
				correctAnswer: 0,
				image: Grafiek_lijndiagram_stap2,
				title: 'De basis van de visualisatie staat maar waar staan de assen voor? Bepaal de as-titels.',
			},
			{ // Step 2
				options: [
					{
						type: 0,
						param: 'Hoeveelheid stookolie in water na incident'
					},
					{
						type: 0,
						param: 'Hoe staat er ervoor met de waterkwaliteit?'
					},
					{
						type: 0,
						param: 'Status van de waterkwaliteit'
					},
				],
				correctAnswer: 0,
				image: Grafiek_lijndiagram_stap3,
				title: 'De datavisualisatie is nu bijna compleet. Geef de visualisatie een passende en beschrijvende titel. ',
			},
			{ // Step final
				image: Grafiek_lijndiagram_stap4,
				title: 'De visualisatie is af. Met behulp van dit diagram kunnen we zien hoeveel stookolie er is vrijgekomen en hoe lang het duurde voordat het was opgeruimd.',
			},
		],
		info: Data_lijndiagram,
		firstStepData: [
			{
				title: 'histogram',
				image: Grafiek1_lijndiagram,
				description: 'Een histogram is een weergave van de frequentieverdeling van in klassen gegroepeerde data.'
			},
			{
				title: 'spreidingsdiagram',
				image: Grafiek2_lijndiagram,
				description: 'Een spreidingsdiagram wordt gebruik voor het aantonen van relaties tussen verschillende gegevensreeksen.'
			},
			{
				title: 'lijndiagram',
				image: Grafiek3_lijndiagram,
				description: 'Lijndiagrammen worden gebruikt bij het weergeven van de continue ontwikkeling van een verschijnsel in de tijd.'
			},
		],
		firstStepCorrect: 2,
		firstStepTitle: 'Datavisualisatie start bij het kiezen van de juiste diagramsoort voor de informatie die je wilt laten zien. Kies een passende diagram voor deze opdracht. '
	}, 
	{ // VOP Monitor 3 | Routekaart
		question: 'De nieuwe sluisdeur is succesvol aangekomen. Maak een datavisualisatie van de route die de sluisdeur heeft afgelegd. Laat hierbij zien hoe laat de sluisdeur op welke locatie was. Maak hierbij gebruik van de opgehaalde data (zie I). ',
		steps: [
			{ // Step 1
				options: [
					{
						type: 0,
						param: 'Norwich, Arnhem, Rotterdam'
					},
					{
						type: 0,
						param: 'Rotterdam, Arnhem, Heerenveen'
					},
					{
						type: 0,
						param: 'Arnhem, Rotterdam, Heerenveen'
					},
				],
				correctAnswer: 1,
				image: Grafiek_routekaart_stap2,
				title: 'De basis van de visualisatie staat maar langs welke steden is de sluis gekomen?  Vul de nummers in.',
			},
			{ // Step 2
				options: [
					{
						type: 0,
						param: '08:30, 12:00, 15:45'
					},
					{
						type: 0,
						param: '08:45, 13:00, 16:00'
					},
					{
						type: 0,
						param: '07:00, 08:30, 12:00'
					},
				],
				correctAnswer: 0,
				image: Grafiek_routekaart_stap3,
				title: 'Nu de steden bekend zijn moeten de tijden in de visualisatie wordt opgenomen. Hoe laat was de sluisdeur op welke locatie?',
			},
			{ // Step 3
				options: [
					{
						type: 0,
						param: 'Route sluisdeur'
					},
					{
						type: 0,
						param: 'Routekaart sluis'
					},
					{
						type: 0,
						param: 'Aankomsttijd sluisdeur'
					},
				],
				correctAnswer: 2,
				image: Grafiek_routekaart_stap4,
				title: 'De datavisualisatie is nu bijna compleet. Geef de visualisatie een passende en beschrijvende titel. ',
			},
			{ // Step final
				image: Grafiek_routekaart_stap5,
				title: 'Dit is het eindresultaat van de visualisatie. Met behulp van deze visualisatie kunnen we precies zien welke route de sluisdeur heeft afgelegd, inclusief tijden.',
			},
		],
		info: Data_routekaart,
		firstStepData: [
			{
				title: 'routekaart',
				image: Grafiek1_routekaart,
				description: 'Een routekaart wordt gebruikt om te laten zien langs welke plekken iets of iemand komt.'
			},
			{
				title: 'choropletenkaart',
				image: Grafiek2_routekaart,
				description: 'Een choropletenkaart wordt gebruik om een waarde per regio weer te geven.'
			},
			{
				title: 'stippenkaart',
				image: Grafiek3_routekaart,
				description: 'Een puntdistributiekaart is een kaart die een geografisch spreiding visualiseert.'
			},
		],
		firstStepCorrect: 0,
		firstStepTitle: 'Datavisualisatie start bij het kiezen van de juiste diagramsoort voor de informatie die je wilt laten zien. Kies een passende diagram voor deze opdracht.'
	}, 
    
];

export default data;
import { useGlobalContext } from 'context/global';
import { dialogueChunks } from 'data/dialogues';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsoleLabel, ConsoleRow } from './Styles';

const ConsoleDialogSelect = () => {
	const { t } = useTranslation();

	const selectTabRef = useRef<HTMLSelectElement>(null);

	const {activeDialogueChunk, setActiveDialogueChunk} = useGlobalContext();

	const [dropdownItems, setDropdownItems] = useState<string[]>();

	
	const fillDropdownItems = () => {
		const a: string[] = [];
		for (const [key] of Object.entries(dialogueChunks)) {
			a.push(key);
		}

		setDropdownItems(a);

	};
	
	useEffect(() => {
		fillDropdownItems();
	}, []);

	const onChangeChunk = (event) => {
		if (chunk in dialogueChunks) {
			setActiveDialogueChunk && setActiveDialogueChunk(chunk);

		}
		// Preventing the page from reloading
		event.preventDefault();

	};

	const [chunk, setChunk] = useState('');

	return(

		<ConsoleRow>
			<ConsoleLabel>{t('general.4')}</ConsoleLabel>
			<select onChange={(e) => setActiveDialogueChunk && setActiveDialogueChunk(e.target.value)} ref={selectTabRef} value={String(activeDialogueChunk)}>
				<option label={'none'} value={undefined}/>
				{dropdownItems && dropdownItems.map((opt, index) => (<option key={index} label={String(opt)} value={opt}/>))}
			</select>
			<form onSubmit={onChangeChunk}>
				<input
					value={chunk}
					onChange={(e) => setChunk(e.target.value)}
					type="text"
				/>
				<button type="submit" >Set</button>
			</form>
		</ConsoleRow>
	);
};

export default ConsoleDialogSelect;
import { Snelweg_02 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Snelweg_02,
	yaw: -10,
	pitch: -2.27172
};


let customHotspots  =	[
	{
		'pitch': 0,
		'yaw': 176.774,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 11,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene6 = {
	sceneId: '12',
	config: getConfig(customConfig),
};	

export default Scene6;
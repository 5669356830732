export type Option = {
	text: string;
	followUpChunk?: string;
}

export type DialogueChunk = {
	text: string;
	avatarId?: number;
	audioId?: string;
	followUpChunk?: string;
	options?: Option[];
	levelSelectChunk?:  boolean;
	blockContinue?: boolean;
	blockInput?: boolean;
}

type DialogueChunkRecord = {
	[Key in string]?:DialogueChunk;
}

const dialogueChunks: DialogueChunkRecord =  {
	

	//#region Level Select dialogues per level

	//#region introduction
	hm_introduction_01 : {
		text: 'Welkom bij de game Ontdek data! Rijkswaterstaat is één van de grootste dataproducenten van de overheid. In deze game ontdek je wat data is en wat jij en Rijkswaterstaat er allemaal mee kan. Speel de game en laat je verwonderen. ',
		avatarId: 0,
		followUpChunk: 'hm_introduction_011',
		blockInput: true

	},
	hm_introduction_011 : {
		text: 'Ik ben Karla, projectleider bij Rijkswaterstaat. Ik help je op weg in de virtuele wereld van Ontdek data! In deze game is een sluisdeur beschadigd. Jouw opdracht: vervang deze zo snel mogelijk en zorg ervoor dat burgers en het milieu zo min mogelijk overlast ervaren.',
		avatarId: 0,
		followUpChunk: 'hm_introduction_02',
		blockInput: true

	},

	hm_introduction_02 : {
		text: 'Via dit menu kun je navigeren naar verschillende locaties als deze ontgrendeld zijn. Nadat je een locatie hebt uitgespeeld wordt een volgende ontgrendeld. ',
		avatarId: 0,
		followUpChunk: 'hm_introduction_03',
		blockInput: true

	},

	hm_introduction_03 : {
		text: 'Elke locatie is een mini game en heeft zijn eigen doel(en). Door deze doelen te behalen kom je stap voor stap dichter bij het hoofddoel: De effectieve vervanging van de sluisdeur. Op een manier dat de burgers en het milieu hier zo min mogelijk overlast van hebben ondervonden.</br>Daarnaast rapporteer je het verloop van deze calamiteit aan je leidinggevende.',
		avatarId: 0,
		followUpChunk: 'hm_introduction_032',
		blockInput: true

	},
	hm_introduction_032 : {
		text: 'De speeltijd van dit spel bedraagt 30 tot 45 minuten. Je hoeft de game niet in één keer uit te spelen. Na elke mini-game slaat de game automatisch je voortgang op. Bij de minigames zee en vaarweg krijg je soms een hint zodat je verder kunt met deze minigame.</br></br>Als je de game tussentijds afsluit, ga je de volgende keer verder waar je gebleven bent. Let op! Het spel slaat alleen voortgang op na het afronden van een mini-game. Stop je halverwege een mini-game? Dan start je de volgende keer weer bij het beginscherm van die mini-game.</br></br>Veel plezier!',
		avatarId: 0,
		followUpChunk: 'hm_introduction_04',
		blockInput: true

	},

	hm_introduction_04 : {
		text: 'Het is tijd om in actie te komen! Klik op het ontgrendelde icoon om de tutorial te starten!',
		avatarId: 0,
		followUpChunk: 'hm_introduction_05',
	},

	hm_introduction_05 : {
		text: 'Selecteer het kantoor op de kaart. Dit is de enige locatie die momenteel ontgrendeld is. Als je deze tutorial hebt afgerond, speel je een nieuwe locatie vrij.',
		avatarId: 0,
	},

	// l[theme/type]_01[level identifier]_first[subtype]_01[chunk identifier]
	//#endregion

	//#region Level 00

	// l_00_first_01 : {
	// 	text: 'Hee leuk dat je er bent.',
	// 	avatarId: 0,
	// 	followUpChunk: 'l_00_first_02'
	// },

	l_00_first_01 : {
		text: 'Welkom in ons kantoor. Hier leg ik je uit hoe de game werkt.',
		avatarId: 0,
		followUpChunk: 'l_00_first_03'
	},

	l_00_first_03 : {
		text: 'Druk op Start om met de tutorial te beginnen.',
		avatarId: 0,
		levelSelectChunk: true,
	},

	l_00_standard : {
		text: 'Druk op Start om met de tutorial te beginnen.',
		//text: 'Kom naar het hoofdkantoor dan leggen we je wat basics uit',
		avatarId: 0,
		levelSelectChunk: true,

	},

	l_00_completed : {
		text: 'Je weet nu hoe de functionaliteiten van de game werken. Je hebt de tutorial afgerond en je kunt nu starten met de game.',
		avatarId: 0,
	},
	l_00_completed_01 : {
		text: 'Om op de juiste manier met dit incident om te gaan moet je eerst een accuraat beeld vormen van de schade aan de sluis. ',
		avatarId: 0,
		blockInput: true,
		followUpChunk: 'l_00_completed_02'
	},
	l_00_completed_02 : {
		text: 'Ga naar de sluis om de schade te inspecteren. Gebruik de beschikbare tools om de juiste data te verzamelen. De ingewonnen data verstuur je aan het einde van iedere mini game aan de control room.',
		avatarId: 0,
		blockInput: true
	},
	


	//#endregion

	//#region Level 01

	l_01_first_01 : {
		text: 'In deze mini game verzamel je data en vertaal je deze naar bruikbare informatie.',
		avatarId: 0,
		followUpChunk: 'l_01_first_03'
	},
			
	// l_01_first_02 : {
	// 	text: 'We gaan jou sturen om de schade te analyseren.',
	// 	avatarId: 1,
	// 	followUpChunk: 'l_01_first_03'
	// },
			
	l_01_first_03 : {
		text: 'Ik kan je hulp goed gebruiken! Laten we snel beginnen.',
		avatarId: 1,
		levelSelectChunk: true,
	},

			
	l_01_standard : {
		text: 'Ga naar de sluis om de schade te analyseren.',
		avatarId: 1,
		levelSelectChunk: true,
	},

	l_01_completed : {
		text: 'Deze mini game heb je succesvol afgerond. De conclusie is dat de sluisdeur zo snel mogelijk vervangen moet worden. ',
		avatarId: 0,
	},

	//#endregion

	//#region Level 02
	l_02_standard : {
		text: 'Je hebt de schade aan de sluis  in kaart gebracht. Er moet zo snel mogelijk een nieuwe sluisdeur komen. Inmiddels zien we dat de burgers zich zorgen maken over de situatie. Het is belangrijk om op de juiste manier te communiceren over dit incident. Stel samen met afdeling Communicatie een communicatieplan op.',
		avatarId: 0,
		followUpChunk: 'l_02_standard_01',
	},
	l_02_standard_01 : {
		text: 'De afdeling Communicatie wacht op je. Zij krijgen zoveel berichten binnen over het incident bij de sluis, hier moet wat mee gebeuren.',
		avatarId: 0,
		followUpChunk: 'l_02_standard_02',
	},
	l_02_standard_02 : {
		text: 'Ga aan de slag en stel een communicatieplan op. Om dit te doen maak je gebruik van \'text mining\'. Ik leg je zo uit hoe dit werkt.',
		avatarId: 6,
		levelSelectChunk: true,
	},

	l_02_completed : {
		text: ' Je hebt een communicatieplan opgesteld waardoor alle relevante partijen juist en tijdig worden geïnformeerd.',
		// avatarId: 0,
	},

	//#endregion
			
	//#region Level 03
	// l_03_standard : {
	// 	text: 'Goed bezig! De nieuwe sluisdeur is met spoed besteld en op het juiste schip geladen. Vergezel de kapitein en help hem met het uitstippelen van de juiste route.',
	// 	followUpChunk: 'l_03_standard_02',
	// 	avatarId: 0,
	//},
	l_03_standard : {
		text: 'De nieuwe sluisdeur is op het schip geladen. Zet verschillende sets aan data in om de meest effectieve route naar Nederland te bepalen. Vergezel de kapitein en help hem met het uitstippelen van de juiste route.',
		followUpChunk: 'l_03_standard_01',
		avatarId: 0,
	},
	l_03_standard_01 : {
		text: 'Het schip met de nieuwe sluisdeur moet zo snel mogelijk van Norwich naar Rotterdam varen. Help jij mee de juiste route te bepalen? Kom aan boord!',
		levelSelectChunk: true,
		avatarId: 3,
	},

	l_03_completed : {
		text: 'Level behaald. De juiste route is ingevuld en het schip is precies volgens schema aangekomen in de Rotterdamse Haven. ',
		avatarId: 0,
	},

	//#endregion

	//#region level 04
	l_04_standard : {
		text: 'Het schip met de nieuwe sluisdeur is via de vaarweg aangekomen in Arnhem. Het laatste gedeelte van de route moet via de weg afgelegd worden. Ga naar de weg en help de chauffeur met het bepalen van de juiste route.',
		followUpChunk: 'l_04_standard_02',
		levelSelectChunk: true,
		avatarId: 0,
	},
	l_04_standard_02 : {
		text: 'De sluis moet een deel van de route over de autoweg worden vervoerd. Het is belangrijk dat het transport zo effectief mogelijk verloopt en zo min mogelijk overlast veroorzaakt voor de burgers.',
		followUpChunk: 'l_04_standard_01',
		levelSelectChunk: true,
		avatarId: 0,
	},
	l_04_standard_01 : {
		text: 'De sluisdeur is zwaar en groot, je kunt gerust spreken van speciaal transport. Help mij om de juiste route te bepalen! ',
		levelSelectChunk: true,
		avatarId: 4,
	},

	l_04_completed : {
		text: 'Deze mini game heb je behaald. Je hebt bepaald welke data nuttig is voor het uitstippelen van een effectieve route over de wegen van Nederland.',
		avatarId: 0,
	},

	//#endregion

	//#region Level 05

	l_05_standard : {
		text: 'De sluisdeur is aangekomen in de haven van Rotterdam! De nieuwe sluisdeur wordt, in Nederland, zo ver mogelijk via het water vervoerd. Ga naar de vaarweg en help de mobiel verkeersleider om het binnenvaartschip met de sluisdeur door de vaarweg te begeleiden.',
		followUpChunk: 'l_05_standard_01',
		avatarId: 0,
	},
	l_05_standard_01 : {
		text: 'De vaarwegen door het binnenland zijn een uitkomst in efficiënt transport. Dit geldt ook voor de sluis!',
		followUpChunk: 'l_05_standard_02',
		avatarId: 0,
	},
	l_05_standard_02 : {
		text: 'Dit grote binnenvaartschip vervoert de gigantische sluisdeur. Het wordt nog een uitdaging om precies te bepalen wanneer deze vracht door de vaarweg past. Kun je me helpen?',
		levelSelectChunk: true,
		avatarId: 5,
	},
	l_05_completed : {
		text: 'Dit level heb je behaald. Je hebt berekend wanneer het schip door de vaarweg kan varen. Je hebt het meest geschikte tijdslot gekozen.',
		avatarId: 0,
	},

	//#endregion

	//#region Level 06



	l_06_standard: {
		text: 'Goed werk! De sluisdeur is ook succesvol over de weg vervoerd. Voordat het project afgerond kan worden wacht er nog één belangrijke taak op je. Ga naar de control room om een visualisatie te maken van alle data die bij dit project is ingezet. Met deze visualisatie kun je rapporteren aan je leidinggevende over het succesvol vervangen van de sluisdeur.',
		avatarId: 0,
		followUpChunk: 'l_06_standard_02',
	},
	// l_06_standard_01: {
	// 	text: 'De sluisdeur is succesvol vervoerd! Nu is het belangrijk om alle verzamelde data en informatie te verwerken in een rapportage. ',
	// 	avatarId: 0,
	// 	followUpChunk: 'l_06_standard_02',
	// },

	l_06_standard_02  : {
		text: 'Welkom. Ik ben Jullienne, Data Specialist. In de control room voeg je alle data en informatie samen om een visualisatie te maken over het incident. Hier kan men van leren en waar nodig het proces aanscherpen voor een calamiteit in de toekomst.',
		levelSelectChunk: true,
		avatarId: 2,
	},

	l_06_completed : {
		text: 'Deze mini game heb je behaald. Je hebt een visuele rapportage gemaakt van het incident.',
		// avatarId: 0,
	},

	//#endregion

	//#endregion
	
	//#region Level 0 - Tutorial
	l_00_tutorial_01 : {
		text: 'Goed dat je er bent. We gaan van start! In het spel voer je niet alleen acties uit, maar ontmoet je ook verschillende personages. ',
		avatarId: 0,
		
		followUpChunk: 'l_00_tutorial_011'
	},

	l_00_tutorial_011 : {
		text: 'Sommige personages stellen je vragen. Je kunt antwoord geven door één van de opties aan te klikken. Ben je er klaar voor?',
		avatarId: 0,
		options: [
			{
				text: 'Ik weet het niet zeker, maar ik wil zeker een poging wagen!',
				followUpChunk: 'l_00_tutorial_02',
			},
			{
				text: 'Jazeker!',
				followUpChunk: 'l_00_tutorial_02',
			},
		],
		blockContinue: true,
		blockInput: true
	},

	l_00_tutorial_02 : {
		text: 'Goed! Klik met je muis en houd deze ingedrukt (of sleep met je vinger) om rond te kijken in een omgeving.',
		avatarId: 0,
		blockContinue: true,
	},
	l_00_tutorial_02_noblock : {
		text: 'Goed! Klik met je muis en houd deze ingedrukt (of sleep met je vinger) om rond te kijken in een omgeving.',
		avatarId: 0,
		//blockContinue: true,
	},

	l_00_tutorial_02_next : {
		text: 'Goed! Klik met je muis en houd deze ingedrukt (of sleep met je vinger) om rond te kijken in een omgeving.',
		avatarId: 0,
		followUpChunk: 'l_00_tutorial_03',
	},

	l_00_tutorial_03 : {
		text: 'Je kunt door de ruimte bewegen door op de ronde cirkel met schoentjes te klikken.',
		avatarId: 0,
		blockContinue: true,
	},

	l_00_tutorial_04 : {
		text: 'Soms is verplaatsen nodig voordat je verder kunt met het spel!',
		avatarId: 0,
		blockInput: true,
		followUpChunk: 'l_00_tutorial_05',
	},

	l_00_tutorial_05 : {
		text: 'Ik help je op weg door je doelen te geven. De doelen zijn links bovenin het scherm te zien. Zo weet je altijd wat de volgende stap is. Klik het dialoogvenster weg om je huidige doel te bekijken.',
		avatarId: 0,
		blockInput: true,
		//followUpChunk: 'l_00_tutorial_055',

	},
	// l_00_tutorial_055 : {
	// 	text: 'Je doelen zijn altijd links bovenin te zien in een overzicht. Zo weet je altijd wat de volgende stap is!',
	// 	avatarId: 0,
	// 	blockInput: true,

	// },

	l_00_tutorial_06 : {
		text: 'Tijd voor de laatste soort actie die je in de game tegenkomt: interactie met objecten. Er ligt een tablet in de ruimte. Pak hem op door op de cirkel met blauwe rand te klikken.',
		avatarId: 0,
		blockContinue: true,
	},

	l_00_tutorial_07 : {
		text: 'Super! Kijk op de tablet voor informatie.',
		blockInput: true,
		followUpChunk: 'l_00_tutorial_071',
		avatarId: 0,
	},
	l_00_tutorial_071 : {
		text: 'Er is zojuist een calamiteit binnen gekomen. Dit kan consequenties hebben voor het milieu en burgers. Belangrijk om hier als team direct actie op te ondernemen. Bekijk de melding op de tablet.',
		avatarId: 0,
	},

	l_00_tutorial_08 : {
		text: 'Dat klinkt niet goed. Leg de tablet snel weg door rechtsboven op het kruisje te klikken.',
		avatarId: 0,
		blockContinue: true,
	},

	l_00_tutorial_09 : {
		text: 'Ik stuur je naar de sluis om de schade in kaart te brengen en de vervolgstappen te bepalen.',
		avatarId: 0,
	},


	//#endregion

	//#region level 1 - sluis
	l_01_start_01 : {
		text: 'Goed dat je er zo snel bent! Ik ben Joey, de sluisbeheerder. Er is een schip tegen de sluis aangevaren en ik vrees voor schade. ',
		avatarId: 1,
		followUpChunk: 'l_01_start_02',
		blockInput: true
	},
	l_01_start_02 : {
		text: 'Kun je me helpen met het analyseren van de status van de sluis? Ik heb al een lucht- en onderwaterdrone klaar staan. Klik één van de drones aan om te beginnen.',
		avatarId: 1,
		followUpChunk: 'l_01_start_03',
		blockInput: true
	},
	l_01_start_03 : {
		text: 'Voordat je aan de slag gaat, vertelt Merijn Doggen je hoe lucht- en onderwaterdrones precies werken.',
		avatarId: 1,
		blockInput: true
	},

	l_01_airdrone_01 : {
		text: 'Met de drone kun je de sluis scannen. Deze heeft verschillende sensoren om de afmetingen, waterkwaliteit en de sluis zelf te kunnen scannen. Er zijn drie verschillende filters die je kunt selecteren. Gebruik ze alle drie om een volledig beeld te vormen vanuit de lucht.',
		avatarId: 1,
		blockInput: true
		//followUpChunk: 'l_01_first_02'
	},
	l_01_waterdrone_01f : {
		text: 'Scan om te kijken of er onderdelen van het schip liggen.',
		avatarId: 1,
		//followUpChunk: 'l_01_first_02'
	},
	l_01_WDfilter_open: {
		text: 'Gebruik de onderwaterdrone om de sluis en de bodem te inspecteren. ',
		avatarId: 1,
		blockInput: true,
		//followUpChunk: 'l_01_first_02'
	},
		
	l_01_WDfilter_01_second : {
		text: 'En, zijn er onderdelen van het schip of de sluis afgebroken?',
		avatarId: 1,
		blockInput: true,
		options: [
			{
				text: 'Ik zie niets liggen',
				followUpChunk: 'l_01_WDfilter_03',
			},
			{
				text: 'Ik zie een grote rode vlek. Betekent dit dat er onderdelen op de bodem liggen?',
				followUpChunk: 'l_01_WDfilter_031',
			},
		],
		//followUpChunk: 'l_01_first_02'
	},
	l_01_WDfilter_03: {
		text: 'Dat is in ieder geval een meevaller!',
		avatarId: 1,
		blockInput: true,
	},
	l_01_WDfilter_031: {
		text: 'De scan laat hoogteverschil zien. Maar er liggen geen brokstukken op de bodem. Dat is in ieder geval goed nieuws.',
		avatarId: 1,
		blockInput: true,
	},

	l_01_WDfilter_01_first: {
		text: ' Druk op Start scan om de bodem te inspecteren. De drone gebruikt sonar om een accuraat beeld te geven van de bodem. Met sonar meet je met geluidsecho hoe diep de ondergrond is. Met deze techniek kun je eenvoudig zien of er bijvoorbeeld onderdelen van het schip of de sluis op de bodem liggen.',
		avatarId: 1,
		blockInput: true,
		//followUpChunk: 'l_01_first_02'
	},
	l_01_WDfilter_02_first : {
		text: 'Tijd om de schade onder water in kaart te brengen. Klik op de beschadigde plek om een grid te genereren. Het grid laat accuraat zien welke schade er is.',
		avatarId: 1,
		blockInput: true
		//followUpChunk: 'l_01_first_02'
	},
		
	l_01_WDfilter_02_second : {
		text: ' En, hoe ziet het eruit?',
		avatarId: 1,
		blockInput: true,
		options: [
			{
				text: 'Ik zie een klein deukje, dat kan geen kwaad.',
				followUpChunk: 'l_01_WDfilter_02_answer1'

			},
			{
				text: 'Ik zie een deuk. De sluis heeft nu zeker een zwakke plek.',
				followUpChunk: 'l_01_WDfilter_02_answer2'

			},
		],
	},
	l_01_WDfilter_02_answer1 : {
		text: 'Mag ik eens meekijken? Volgens mij is de schade aanzienlijk. Dit ziet er niet goed uit.',
		avatarId: 1,
		blockInput: true,
	},

	l_01_WDfilter_02_answer2 : {
		text: 'Helaas kan ik niet anders dan dezelfde conclusie trekken.',
		avatarId: 1,
		blockInput: true,
		
	},
	l_01_LDfilter_02_first : {
		text: 'Klik op de verschillende punten om de afmetingen van de sluis in te scannen. De afmetingen komen goed van pas als er een onderdeel aan vervanging toe is.',
		avatarId: 1,
		blockInput: true
		//followUpChunk: 'l_01_first_02'
	},
	l_01_LDfilter_02_second: {
		text: 'Top! De afmetingen van de sluis zijn nu bekend.',
		avatarId: 1,
		blockInput: true
		//followUpChunk: 'l_01_first_02'
	},
		
	l_01_LDfilter_01_first: {
		text: 'Met dit filter kun je de kwaliteit van de sluis boven water in kaart brengen. Zoek naar drie datapunten op de sluis. Klik ze aan om de kwaliteit te analyseren.',
		avatarId: 1,
		blockInput: true,
	},
	
	l_01_LDfilter_01_second : {
		text: 'Wat is de status van de sluis?',
		avatarId: 1,
		blockInput: true,
		options: [
			{
				text: 'De kwaliteit van de sluisdeur is op sommige punten te laag.',
				followUpChunk: 'l_01_LDfilter_031',
			},
			{
				text: 'De sluis is wat beschadigd, maar hij kan nog best een jaartje mee.',
				followUpChunk: 'l_01_LDfilter_03',
			},
		],
		//followUpChunk: 'l_01_first_03'
	},

	l_01_LDfilter_031 : {
		text: 'Ik zie het. Gebruik de andere filters om meer informatie de verzamelen.',
		avatarId: 1,
	},
	l_01_LDfilter_03 : {
		text: 'Bekijk eerst alle data voordat je een conclusie trekt.',
		avatarId: 1,
	},

	l_01_LDfilter_05 : {
		text: 'Moeten we op basis van wat je nu hebt gezien een nieuwe sluisdeur bestellen?',
		avatarId: 1,
		options: [
			{
				text: 'De sluis is misschien niet in optimale conditie, maar kan nog wel even mee.',
				followUpChunk: 'l_01_LDfilter_06',
			},
			{
				text: 'Het ziet er niet al te best uit, maar dit kan ik beter inschatten met een volledig beeld van de schade. Hiervoor gebruik ik de andere drone.',
				followUpChunk: 'l_01_LDfilter_07',
			},	
		],
		blockInput: true
		//followUpChunk: 'l_01_first_03'
	},
	l_01_LDfilter_06 : {
		text: 'Je hebt nog geen compleet beeld verzameld van de sluis. Zorg dat je alle data hebt verzameld voordat je een conclusie trekt.',
		avatarId: 1,
		//followUpChunk: 'l_01_first_03'
	},

	l_01_LDfilter_07 : {
		text: 'Helemaal mee eens. Gebruik de andere drone om een compleet beeld te krijgen van de schade.',
		avatarId: 1,
		//followUpChunk: 'l_01_first_03'
	},
	l_01_LDfilter_03_first: {
		text: 'Ik hoop vooral dat er geen olie in het water lekt, dat is namelijk een ramp  voor het milieu! Met deze filter kun je goed beoordelen of er olie in het water is gelekt.',
		avatarId: 1,
		blockInput: true,
		//followUpChunk: 'l_01_first_03'
	},


	l_01_LDfilter_03_second : {
		text: 'Hoe staat het ervoor met de kwaliteit van het water?',
		avatarId: 1,
		blockInput: true,
		options: [
			{
				text: 'Ik heb slecht nieuws. Er is veel stookolie gelekt. Dit moet zo snel mogelijk worden opgeruimd.',
			},
			{
				text: 'Op de scan is stookolie te zien. Ik vrees voor de kwaliteit van het water en de flora en fauna.',
			},
		],
		//followUpChunk: 'l_01_first_03'
	},

	l_01_hologram_01 : {
		text: 'Als je beide drones hebt gebruikt om de data te verzamelen is het tijd voor de volgende stap. Data omzetten in informatie. Klik de drone weg en genereer een 3D-model van de sluis. ',
		avatarId: 1,
		blockContinue: true,
		// followUpChunk: 'l_01_hologram_022'
	},
	l_01_hologram_022 : {
		text: 'Je hebt alle schade aan de sluis in kaart gebracht. Maak nu een 3D-model van de sluis. Het 3D-model laat de schade aan de sluis goed zien. Het geeft het team alle nodige informatie om de volgende stap te zetten. Ik stuur het 3D-model met bijbehorende data naar onze collega\'s.',
		avatarId: 1,
		blockContinue: true,
	},
	l_01_hologram_033 : {
		text: 'Door meerdere camerabeelden te combineren kun je een 3D-model maken van een object, mooi hè. Klik op het 3D-model en sleep rond om het goed te bekijken. Zo kan de schade goed geanalyseerd worden. Wat valt er op? ',
		avatarId: 1,
		options: [
			{
				text: 'De sluis is al ouder en lekt wat olie. Dat deukje kan geen kwaad en de olie kunnen we opruimen.',
				followUpChunk: 'l_01_hologram_02'
			},
			{
				text: 'De sluis heeft een aardige klap gehad. De sluisdeur is verouderd en beschadigd. Er moet zo snel mogelijk een nieuwe sluisdeur worden geplaatst!',
				followUpChunk: 'l_01_hologram_03'
			},
		],
		//followUpChunk: 'l_01_first_03'
	},
	l_01_hologram_02 : {
		text: 'Met het oog op veiligheid, bereikbaarheid en het milieu is dit niet de beste optie. Er moet zo snel mogelijk een nieuwe sluisdeur worden geplaatst.',
		avatarId: 1,
		blockInput: true,
		followUpChunk: 'l_01_hologram_04'
	},
	l_01_hologram_03 : {
		text: 'Helemaal mee eens. Er is geen tijd te verliezen.',
		avatarId: 1,
		followUpChunk: 'l_01_hologram_04',
		//followUpChunk: 'l_01_first_03'
	},
	l_01_hologram_04 : {
		text: 'Ik stuur het 3D-model naar de control room. De nieuwe sluisdeur kan dan vlot hiernaartoe gebracht worden. Je hulp is zo te zien op andere plekken ook hard nodig. Ga terug naar het hoofdmenu en ontdek hoe je verder nog kunt helpen!',
		avatarId: 1,
		blockInput: true,

		//followUpChunk: 'l_01_first_03'
	},

	// l_01_final_01 : {
	// 	text: 'De inventarisatie van ruwe data is compleet. Maak een 3D-model van de sluis zodat collega\'s aan de slag kunnen. ',
	// 	avatarId: 1,
	// 	blockContinue: true,
	// 	//followUpChunk: 'l_01_first_03'
	// },

	//#endregion

	//#region Level 2 - Crisiskantoor
	l_02_introduction_01 : {
		text: 'Hallo! Ik ben Dirk, communicatieadviseur. De kapotte sluisdeur heeft grote gevolgen voor de leefbaarheid van Nederland. De waterkwaliteit is verminderd door de olielekkage en de scheepvaart is gestremd.',
		avatarId: 6,
		followUpChunk: 'l_02_introduction_02',
		blockInput: true
	},

	l_02_introduction_02 : {
		text: 'Op social media staan veel berichten van burgers over het incident met de sluis. Het is de taak van Rijkswaterstaat de burgers te informeren en op de hoogte te houden. Het is zaak om tijdig en op de juiste manier te communiceren over het incident. Het is noodzakelijk om rekening te houden met hoe de burgers over het incident denken en praten.',
		avatarId: 6,
		followUpChunk: 'l_02_introduction_03',
		blockInput: true

	},	

	l_02_introduction_03 : {
		text: 'Het is noodzakelijk te inventariseren wat de burgers belangrijk vinden. Zo kan een goed communicatieplan worden opgesteld. ',
		avatarId: 6,
		followUpChunk: 'l_02_introduction_04',
		blockInput: true

	},

	l_02_introduction_04 : {
		text: 'Er zijn de afgelopen 4 uur al 2250 social mediaberichten en e-mails binnengekomen. Kun jij helpen deze data te verwerken en te duiden?',
		avatarId: 6,
		blockInput: true,
		options: [
			{
				text: 'Ja zeker!',
				followUpChunk: 'l_02_first_01'
			},
			{
				text: 'Ik zou niet weten hoe al deze data te verwerken…',
				followUpChunk: 'l_02_first_01'
			},
		],
	},

	l_02_first_01 : {
		text: 'We kunnen de data het best verwerken door middel van text mining. Hiermee kun je belangrijke conclusies trekken uit een grote bulk data. ',
		avatarId: 6,
		blockInput: true,
		options:[
			{
				text: 'Wat houdt text mining in?',
				followUpChunk: 'l_02_first_02',
			}
		]
	},

	l_02_first_02 : {
		text: 'Text mining heeft vele mogelijkheden. In dit geval lijkt het mij belangrijk dat er wordt gekeken naar hoe de burgers zich voelen en wat ze te zeggen hebben over dit ongeval. ',
		avatarId: 6,
		followUpChunk: 'l_02_first_021',
		blockInput: true,


	},
	l_02_first_021 : {
		text: 'Met sentimentanalyse wordt bepaald of de berichten met name positief of negatief zijn. ',
		avatarId: 6,
		followUpChunk: 'l_02_first_022',
		blockInput: true,


	},
	l_02_first_022 : {
		text: 'Met contextanalyse wordt bepaald welke trefwoorden het meest voorkomen rond een bepaald onderwerp. ',
		avatarId: 6,
		followUpChunk: 'l_02_first_023',
		blockInput: true,
	},
	l_02_first_023 : {
		text: 'Wat denk je ervan?',
		avatarId: 6,
		blockInput: true,
		options:[
			{
				text: 'Ik denk dat text mining perfect is voor deze bulk aan data.',
				followUpChunk: 'l_02_first_024',
			},
			{
				text: 'Top! Ik wist niet dat text mining dé manier is om een grote hoeveelheid data te verwerken.',
				followUpChunk: 'l_02_first_024',
			}
		]
	},

	l_02_first_024 : {
		text: 'Super. Op één van de computers staat het text mining programma klaar. ',
		avatarId: 6,
	},

	l_02_open : {
		text: 'We willen graag weten wat de burgers denken over het ongeluk met de sluis. Kies een trefwoord en analysesoort die ons hier antwoord op kan geven. ',
		avatarId: 6,
		blockInput: true
	},

	l_02_result_00 : {
		text: 'Hier was ik al bang voor, het sentiment is grotendeels negatief. Begrijpelijk natuurlijk, maar wel vervelend. Dit moet worden meegenomen bij het opstellen van het communicatieplan. ',
		avatarId: 6,
		blockInput: true

	},
	l_02_open_00 : {
		text: 'Dit ongeluk heeft een grote impact op flora en fauna. Het is belangrijk te analyseren wat burgers hierover zeggen.',
		avatarId: 6,
		blockInput: true

	},
	l_02_result_01 : {
		text: 'Zo te zien maken ze zich zorgen om het olielek en welke gevolgen dit heeft op het milieu. Het is belangrijk dat hier in de communicatie rekening mee wordt gehouden.',
		avatarId: 6,
		blockInput: true

	},
	l_02_open_01 : {
		text: 'Ik heb veel langs zien komen over de financiële kant van dit incident. Ben benieuwd wat ze hierover te zeggen hebben…',
		avatarId: 6,
		blockInput: true

	},
	l_02_result_02 : {
		text: 'De burgers vragen zich af hoe de financiële afhandeling van dit incident werkt. Hier moet in het communicatieplan rekening mee worden gehouden. ',
		avatarId: 6,
		blockInput: true

	},
	l_02_open_02 : {
		text: 'Dit incident heeft een grote impact op mens en milieu. Het is belangrijk om te weten hoe de burgers de hinder ervaren.',
		avatarId: 6,
		blockInput: true

	},
	l_02_result_03 : {
		text: 'Zo te zien hebben ze de afgelopen tijd meer overlast ervaren door werkzaamheden in die regio. Hier moet zeker rekening mee worden gehouden. ',
		avatarId: 6,
		blockInput: true

	},
	l_02_first_03 : {
		text: 'Bedankt voor je hulp. Dankzij de text mining is duidelijk welke aandachtspunten burgers het belangrijkst vinden. Het is essentieel om deze punten mee te nemen in mijn communicatieplan.',
		avatarId: 6,
		blockInput: true,
		followUpChunk: 'l_02_first_04',

	},
	l_02_first_04 : {
		text: 'Door slim gebruik te maken van data kan ik het communicatieplan beter laten aansluiten op de behoefte van de burgers die met dit incident, of de effecten ervan, te maken hebben.',
		avatarId: 6,
		blockInput: true,
		followUpChunk: 'l_02_first_041',
	},

	l_02_first_041 : {
		text: 'Ik kan beginnen met de communicatie. De nieuwe sluisdeur is inmiddels besteld. Keer terug naar het hoofdmenu en help onze collega\'s met het vervoeren van de sluis door de juiste route van Norwich naar Nederland uit te stippelen!',
		avatarId: 6,
		blockInput: true,
	},
	//#endregion

	//#region Level 3 - Zee

	l_03_introduction_01 : {
		text: 'Hallo, ik ben kapitein Thomas. Ik heb de opdracht gekregen een sluisdeur naar Nederland te vervoeren, maar de precieze route moet nog bepaald worden. Fijn dat jij me komt helpen!',
		avatarId: 3,
		followUpChunk: 'l_03_introduction_02',
		blockInput: true,
		// options: [
		// 	{
		// 		text: 'Jazeker. Dan heb ik wel wat data nodig.',
		// 		followUpChunk: 'l_03_introduction_02',
		// 	},
		// 	{
		// 		text: 'Ja, dat kan ik wel.',
		// 		followUpChunk: 'l_03_introduction_02',
		// 	},
		// ],
	},
	l_03_introduction_02 : {
		text: 'Bij het uitstippelen van deze vaarroute zijn er vier factoren waar ik rekening mee moet houden:',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_introduction_03'
	},
	l_03_introduction_03 : {
		text: 'Golfhoogte, weerrisico\'s, dichtheid van het wolkendek en de hoeveelheid schepen op onze route. De route moet op het gebied van deze factoren voldoen aan bepaalde criteria.',
		avatarId: 3,
		followUpChunk: 'l_03_introduction_04',
		blockInput: true
	},
	l_03_introduction_04 : {
		text: 'Het is aan jou om, aan de hand van de weer- en vaarkaarten en deze criteria, alle mogelijke vaarpunten te vinden. De vaarpunten van de vier kaarten leg je vervolgens naast elkaar om de definitieve route te bepalen. Succes!',
		avatarId: 3,
		blockInput: true,
		options:[
			{
				text: 'Jazeker!',
				followUpChunk: 'l_03_introduction_05',
			},
			{
				text: 'Denk het wel!',
				followUpChunk: 'l_03_introduction_05',
			},
		]
	},
	l_03_introduction_05 : {
		text: 'De weer- en vaarkaarten zijn te vinden op de monitoren. Nieuwsgierig naar de criteria? Klik dan op het vraagteken rechtboven in de kaart.',
		avatarId: 3,
		blockInput: true,
	},

	l_03_rightgmap : {
		text: 'Goed gedaan! De mogelijke vaarpunten worden opgeslagen.',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_rightgmap_02'
	},
	l_03_rightgmap_02 : {
		text: 'Klik op het kruisje rechtsboven om de kaart te sluiten. Ga daarna verder met een andere kaart.',
		avatarId: 3,
		blockInput: true,
	},

	l_03_golfhoogte_01 : {
		text: 'Gebruik de golfhoogtekaart om mogelijke routepunten te bepalen. Klik op de gridpunten op de kaart om te zien waar het schip eventueel langs kan varen.',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_criteriaS_02'
	},
	l_03_weerrisico_01 : {
		text: 'Bekijk de weerrisico\'s en bijgaande criteria om mogelijke routepunten te bepalen. Klik op de gridpunten op de kaart om te zien waar het schip eventueel langs kan varen.',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_criteriaS_04'

	},
	l_03_bewolking_01 : {
		text: 'Bekijk de kaart met het wolkendek om mogelijke routepunten te bepalen. Klik op de gridpunten op de kaart waarlangs het schip daadwerkelijk kan varen.',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_criteriaS_01'

	},
	l_03_vaarroutes_01 : {
		text: 'Bekijk de scheepvaart om mogelijke routepunten te bepalen. Klik op de gridpunten op de kaart om te zien waar het schip eventueel langs kan varen.',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_criteriaS_03'
	},
	l_03_final_01 : {
		text: 'Alle mogelijke vaarpunten zijn nu opgeslagen. Leg ze naast elkaar en bepaal welke vaarroute mogelijk is. ',
		avatarId: 3,
		blockInput: true
	},
	l_03_zeeminigame_01 : {
		text: 'Het schip kan alleen varen door gebieden waar alle omstandigheden (golfhoogte, wolkendek, het weerrisico en aantal schepen) goed zijn. De blauwe cirkels op de tekeningen onderaan geven deze gebieden aan. Bepaal met behulp van de blauwe cirkels welke route het schip kan afleggen.',
		avatarId: 3,
		blockInput: true
	},
	l_03_rightroute : {
		text: 'De perfecte route is uitgestippeld. Ik stuur hem naar de control room voor rapportage.',
		followUpChunk: 'l_03_rightroute_02',
		avatarId: 3,
		blockInput: true

	},
	l_03_rightroute_02 : {
		text: 'Je hebt verschillende sets aan data gecombineerd om de juiste route te bepalen. De sluisdeur kan nu veilig van Norwich naar Rotterdam worden vervoerd. ',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_rightroute_03',
	},
	l_03_rightroute_03 : {
		text: 'Ga terug naar het hoofdmenu om de sluis verder het binnenland in te begeleiden.',
		avatarId: 3,
		blockInput: true
	},



	l_03_criteria_00 : {
		text: 'Wanneer het wolkendek een dichtheid heeft van 95% of meer is er te weinig zicht om veilig te varen.',
		blockInput: true,
	},

	l_03_criteria_01 : {
		text: 'Vermijd golven van 2,5 meter of hoger. Deze vormen een risico.',
		blockInput: true,
	},

	l_03_criteria_02 : {
		text: 'Vermijd punten waarop meer dan 4 schepen samenkomen',
		blockInput: true,
	},

	l_03_criteria_03 : {
		text: 'Risico\'s van \'hevig niveau\' zijn voor onze route niet acceptabel.',
		blockInput: true,
	},

	l_03_criteriaS_01 : {
		text: 'Wanneer het wolkendek een dichtheid heeft van 95% of meer is er te weinig zicht om veilig te varen. Criteria nogmaals bekijken? Klik dan op het vraagteken rechtsboven in de kaart.',
		blockInput: true,
	},

	l_03_criteriaS_02 : {
		text: 'Vermijd golven van 2,5 meter of hoger. Deze vormen een risico. Criteria nogmaals bekijken? Klik dan op het vraagteken rechtsboven in de kaart.',
		blockInput: true,
	},

	l_03_criteriaS_03 : {
		text: 'Vermijd punten waarop meer dan 4 schepen samenkomen. Criteria nogmaals bekijken? Klik dan op het vraagteken rechtsboven in de kaart.',
		blockInput: true,
	},

	l_03_criteriaS_04 : {
		text: 'Risico\'s van \'hevig niveau\' of hoger zijn voor onze route niet acceptabel. Criteria nogmaals bekijken? Klik dan op het vraagteken rechtsboven in de kaart.',
		blockInput: true,
	},

	//hints
	l_03_wrongmap_00 : {
		text: 'Niet helemaal. Kijk goed naar de criteria en probeer het nog een keer. ',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_hint_wolkendek'
	},
	l_03_wrongmap_01 : {
		text: 'Niet helemaal. Kijk goed naar de criteria en probeer het nog een keer. ',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_hint_golfhoogte'
	},
	l_03_wrongmap_02 : {
		text: 'Niet helemaal. Kijk goed naar de criteria en probeer het nog een keer. ',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_hint_zeevaart'
	},
	l_03_wrongmap_03 : {
		text: 'Niet helemaal. Kijk goed naar de criteria en probeer het nog een keer. ',
		avatarId: 3,
		blockInput: true,
		followUpChunk: 'l_03_hint_weerrisico'
	},
	l_03_wrongmap_04 : {
		text: 'Niet helemaal. Kijk goed naar de grids en stippel de route nogmaals uit. ',
		blockInput: true,
		followUpChunk: 'l_03_hint_eind'
	},

	l_03_hint_wolkendek: {
		text: 'In gebieden waar het wolkendek een hogere dichtheid heeft dan 95% is het gebied wit. Het schip kan niet door deze gebieden varen. Selecteer de 12 cirkels die NIET wit zijn.',
		avatarId: 7,
	},
	l_03_hint_golfhoogte: {
		text: 'Gebieden met een golfhoogte van lager dan 2.5 meter zijn blauw gekleurd. Het schip kan langs die gebieden varen. Selecteer de acht cirkels die alleen de kleur blauw bevatten.',
		avatarId: 7,
	},
	l_03_hint_zeevaart: {
		text: 'Het schip kan WEL varen in gebieden waar er 4 of minder dan 4 schepen varen. Tel de schepen goed! Er zijn 11 cirkels die 4 of minder dan 4 schepen bevatten.',
		avatarId: 7,
	},
	l_03_hint_weerrisico: {
		text: 'Gebieden waar het weerriscio \'hevig\' is hebben altijd een rode of donkerpaarse kleur. Het schip kan NIET door deze gebieden varen. Selecteer de cirkels die NIET rood of donkerpaars zijn.  ',
		avatarId: 7,
	},
	l_03_hint_eind: {
		text: 'Kijk goed welke blauwe cirkels overlappen. Leg als het ware de tekeningen op elkaar.  Als de cirkels op alle 4 de tekeningen blauw zijn dan betekent dit dat de route aan alle criteria (golfhoogte, wolkendek, weerrisico en aantal schepen) voldoet!',
		avatarId: 7,
	},

	l_03_answer_00: {
		text: 'Helaas, het door jou gegeven antwoord is onjuist. Het goede antwoord is te zien op het scherm hiernaast. </br></br>Het schip kan varen in de gebieden waar de dichtheid van het wolkendek lager is dan 95%. Dit zijn alle cirkels waar geen wit te zien is. ',
		avatarId: 8,
		followUpChunk: 'l_03_rightgmap_02'
	},
	l_03_answer_01: {
		text: 'Helaas, het door jou gegeven antwoord is onjuist. Het goede antwoord is te zien op het scherm hiernaast. </br></br>Het schip kan varen in de gebieden waar de golfhoogte lager is dan 2,5 meter. Dit zijn alle groene cirkels. ',
		avatarId: 8,
		followUpChunk: 'l_03_rightgmap_02'
	},
	l_03_answer_02: {
		text: 'Helaas, het door jou gegeven antwoord is onjuist. Het goede antwoord is te zien op het scherm hiernaast. </br></br>Het schip kan varen in de gebieden waar het niet druk is. Dit zijn de gebieden met 4 of minder dan 4 schepen.',
		avatarId: 8,
		followUpChunk: 'l_03_rightgmap_02'
	},
	l_03_answer_03: {
		text: 'Helaas, het door jou gegeven antwoord is onjuist. Het goede antwoord is te zien op het scherm hiernaast. </br></br>Het schip kan NIET varen in de gebieden waar het weerrisico hevig is. Dit zijn alle cirkels die NIET rood of donkerpaars zijn.',
		avatarId: 8,
		followUpChunk: 'l_03_rightgmap_02'
	},
	l_03_answer_04: {
		text: 'Helaas, het door jou gegeven antwoord is onjuist. Het goede antwoord is te zien op het scherm hiernaast. </br></br>Het schip kan alleen varen langs gebieden waar alle omstandigheden (golfhoogte, wolkendek, het weer en aantal schepen) goed zijn. De route die het schip kan volgen is te herkennen aan de groene cirkels.',
		avatarId: 8,
		followUpChunk: 'l_03_rightroute'

	},
	//#endregion

	//#region level 4 weg
	l_04_first_01 : {
		text: 'Hallo! Ik ben Simon, ik transporteer de sluis over de weg. Goed dat je er bent! Het wordt een hele klus om deze zware en grote sluisdeur over de weg te vervoeren. Uiteraaard zonder teveel overlast voor andere weggebruikers. Mag ik jou hierbij om hulp vragen? ',
		avatarId: 4,
		blockInput: true,
		options:[
			{
				text:'Ja hoor.',
				followUpChunk: 'l_04_first_02'
			},
			{
				text: 'Natuurlijk!',
				followUpChunk: 'l_04_first_02'
			}
		]
	},
	l_04_first_02 : {
		text: 'Top! Met enkele aspecten moet in ieder geval rekening worden gehouden. Denk aan de sterkte van het asfalt, bruggen en tunnels op de route, drukte op de weg, ontstaan van files en meer. Er is zoveel data op het gebied van wegen en onze infrastructuur… Ik weet  niet waar ik moet beginnen. ',
		avatarId: 4,
		blockInput: true,
		options:[
			{
				text:'Ik ook niet.',
				followUpChunk: 'l_04_first_03'
			},
			{
				text: 'Samen lukt het vast!',
				followUpChunk: 'l_04_first_04'
			}
		]
	},
	l_04_first_03 : {
		text: 'Snap ik helemaal. Data kan zo overweldigend zijn. Het is een kunst om te filteren wat je wel en niet kunt gebruiken.',
		avatarId: 4,
		blockInput: true,
		followUpChunk: 'l_04_first_05'
	},

	l_04_first_04 : {
		text: 'Fijn om te horen! Data kan zo overweldigend zijn. Het is een uitdaging om te filteren wat je wel en niet kunt gebruiken.',
		avatarId: 4,
		blockInput: true,
		followUpChunk: 'l_04_first_05'
	},
	l_04_first_05 : {
		text: 'Ik heb een Alternate Reality bril meegenomen. Als je deze opzet en rondkijkt zie je allerlei soorten data. Het is aan jou om te filteren welke data we kunnen gebruiken voor het bepalen van onze route.  ',
		avatarId: 4,
		blockInput: true,
		followUpChunk: 'l_04_first_06'
	},
	l_04_first_06 : {
		text: 'Klik op het icoon met bril om deze op te zetten!',
		avatarId: 4,
	},

	l_04_first_07 : {
		text: 'Wow wat een hoeveelheid data! Waar te beginnen? Kijk goed om je heen. Er zijn in totaal zes video\'s te vinden.',
		avatarId: 4,
		//blockInput: true,
	},

	l_04_question_01 : {
		text: 'Floating car data, dat klinkt interessant! Hoe kunnen weggebruikers deze data onderweg gebruiken?',
		avatarId: 4,
		blockInput: true,
		options: [
			{
				text: 'Route aanpassen aan de hand van actuele reisadviezen, super handig!',
				followUpChunk:'l_04_question_011'
			},
			{
				text: 'Een volledige route bepalen.',
				followUpChunk:'l_04_question_0111'
			},
		],
	},	
	l_04_question_011 : {
		text: 'Helemaal goed!',
		blockInput: true,
		avatarId: 4,
	},
	l_04_question_0111 : {
		text: 'Een volledige route bepalen niet, maar de route aanpassen met actuele reisadviezen wel!',
		avatarId: 4,
		blockInput: true,
		followUpChunk:'l_04_question_01'

	},	

	l_04_question_02 : {
		text: 'Wanneer krijgt de vrachtwagen te maken met een hoogtedetectiesysteem?',
		avatarId: 4,
		blockInput: true,
		options: [
			{
				text: 'Als hij op deze route blijft en onder de tunnel door wil.',
				followUpChunk:'l_04_question_021'
			},
			{
				text: 'Als de vrachtwagen binnendoor weggetjes neemt.',
				followUpChunk:'l_04_question_0211'
			},
		],
	},	
	l_04_question_021 : {
		text: 'Helemaal goed!',
		blockInput: true,

		avatarId: 4,
	},
	l_04_question_0211 : {
		text: 'Dit wordt lastig, de vrachtwagen krijgt met een hoogtedetectiesysteem te maken wanneer hij op snelwegen lage tunnels tegen komt.',
		avatarId: 4,
		blockInput: true,
		followUpChunk:'l_04_question_02'


	},	

	l_04_question_03 : {
		text: 'Dit vind ik ingewikkeld. Wat doen deze algoritmes precies?',
		avatarId: 4,
		blockInput: true,
		options: [
			{
				text: 'De algoritmes kunnen de locaties waar grotere kans op ongevallen is voorspellen.',
				followUpChunk:'l_04_question_031'
			},
			{
				text: 'De algoritmes kunnen ongelukken voorkomen.',
				followUpChunk:'l_04_question_0311'
			},
		],
	},	
	l_04_question_031 : {
		text: 'Top!',
		avatarId: 4,
		blockInput: true,

	},
	l_04_question_0311 : {
		text: 'Was het maar zo. Direct ongelukken voorkomen helaas nog niet.',
		avatarId: 4,
		blockInput: true,
		followUpChunk:'l_04_question_03'


	},
	l_04_question_04 : {
		text: 'Slimme verkeerslichten. Klinkt handig, hoe kunnen deze positief ingezet worden?',
		avatarId: 4,
		blockInput: true,
		options: [
			{
				text: 'Wellicht krijgt vrachtverkeer prioriteit krijgen bij stoplichten.',
				followUpChunk:'l_04_question_041'
			},
			{
				text: 'Stoplichten kunnen worden omzeild door een andere route te kiezen.',
				followUpChunk:'l_04_question_0411'
			},
		],
	},	
	l_04_question_041 : {
		text: 'Dat lijkt me ideaal!',
		avatarId: 4,
		blockInput: true,

	},
	l_04_question_0411 : {
		text: 'Dat lijkt me niet nodig.',
		avatarId: 4,
		blockInput: true,
		followUpChunk:'l_04_question_04'

	},
	l_04_question_05 : {
		text: 'Hoe maakt gladheidsbestrijding het verkeer veiliger?',
		avatarId: 4,
		blockInput: true,
		options: [			
			{
				text: 'Gladde wegen kunnen afgezet worden.',
				followUpChunk:'l_04_question_0511'
			},
			{
				text: 'In de toekomst kunnen auto\'s gladheid constateren en daarop anticiperen.',
				followUpChunk:'l_04_question_051'
			},

		],
	},	
	l_04_question_051 : {
		text: 'Klinkt goed!',
		avatarId: 4,
		blockInput: true,

	},
	l_04_question_0511 : {
		text: 'Gladheid voorkomen lijkt mij beter dan gladheid omzeilen.',
		avatarId: 4,
		blockInput: true,
		followUpChunk:'l_04_question_05'

	},
	l_04_question_06 : {
		text: 'Communicatie tussen brug en weg. Fascinerend, hoe kan dit ons helpen?',
		avatarId: 4,
		blockInput: true,
		options: [
			{
				text: 'De brug gaat sneller dicht afhankelijk van de hoeveelheid wachtende weggebruikers.',
				followUpChunk:'l_04_question_061'
			},
			{
				text: 'Als er rekening wordt gehouden met openstaande bruggen kan preventief de route worden aangepast.',
				followUpChunk:'l_04_question_0611'
			},
		],
	},	
	l_04_question_061 : {
		text: 'Dat niet. Boten moeten ook de kans krijgen om de brug te passeren',
		avatarId: 4,
		blockInput: true,
		followUpChunk:'l_04_question_06'

	},
	l_04_question_0611 : {
		text: 'Heel erg handig!',
		avatarId: 4,
		blockInput: true,

	},

	l_04_finalquestion_01 : {
		text: 'Super! Alle data is verzameld. Laten we kijken naar een overzicht. ',
		avatarId: 4,
		blockInput: true,
		followUpChunk: 'l_04_finalquestion_02'
	},
	l_04_finalquestion_02 : {
		text: 'Kies welke soorten data kunnen helpen met het verbeteren van onze route. Wil je meer informatie over de verschillende datasoorten, klik dan op het I\'tje rechtsboven.',
		blockInput: true,
		avatarId: 4,
	},
	l_04_finalquestion_03 : {
		text: 'Ik denk niet dat de juiste datamogelijkheden zijn geselecteerd. Kijk er nog eens naar. ',
		blockInput: true,
		avatarId: 4,
	},
	l_04_finalquestion_04 : {
		text: 'Super! Er zijn drie mogelijke routes gegenereerd. Met de data in gedachten, welke route vind jij het beste?',
		blockInput: true,
		avatarId: 4,
	},
	l_04_finalquestion_05 : {
		text: 'Helemaal top! De route is aangepast op basis van de door jou geselecteerde datapunten. Ik start zo snel mogelijk met het vervoer van de sluisdeur.',
		avatarId: 4,
		blockInput: true,
		followUpChunk: 'l_04_finalquestion_051'
	},
	l_04_finalquestion_051 : {
		text: 'Bedankt voor je hulp tot nu toe. Ga terug naar het hoofdmenu voor je laatste opdracht!',
		avatarId: 4,
		blockInput: true
	},
	l_04_finalquestion_05_wrong : {
		text: 'Deze route lijkt me niet verstandig. Kijk nog een keer goed.',
		avatarId: 4,
		blockInput: true
	},

	//#endregion

	//#region level 5 vaarweg
	l_05_start : {
		text: 'Aangenaam. Ik ben Renée, mobiel verkeersleider. De sluisdeur moet over deze vaarweg vervoerd worden.',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_start_01'
	},
	l_05_start_01 : {
		text: 'Voordat het schip kan varen moet je zeker weten dat het water overal diep genoeg is. Het water mag ook niet te hoog staan, dan past het schip niet onder de bruggen door.  Via de website:  <a href="https://vaarweginformatie.nl/" target="_blank">vaarweginformatie.nl</a> is actuele vaarweginformatie voor iedereen toegankelijk.',
		avatarId: 5,
		followUpChunk: 'l_05_start_02',
		blockInput: true,
	},	
	l_05_start_02 : {
		text: 'Met behulp van verschillende gegevens kun je een algoritme schrijven. Het algoritme berekent automatisch de mogelijke vaartijden.',
		avatarId: 5,
		followUpChunk: 'l_05_start_03',
		blockInput: true,
	},	
	l_05_start_03 : {
		text: 'Geef de juiste input voor het algoritme en de computer doet de rest! Ga snel van start want er is weinig tijd.',
		avatarId: 5,
		followUpChunk: 'l_05_start_04',
		blockInput: true,
	},	
	l_05_start_04 : {
		text: 'Voordat het algoritme gebouwd kan worden is er data nodig. Deze data kan opgehaald worden uit open data van Rijkswaterstaat. Bekijk de video over hoe deze data opgehaald wordt in de binnenvaart. Verzamel daarna de juiste informatie over de vaargeul, de bruggen en de waterstand.',
		avatarId: 5,
		blockInput: true,
	},

	l_05_screen_01 : {
		text: 'De diepte van de vaargeul verschilt per locatie. Schepen kunnen alleen over de vaarweg varen als ze langs het ondiepste punt kunnen. Analyseer de dieptes op de drie plekken door op de kaart te klikken. ',
		avatarId: 5,
		blockInput: true,
	},
	l_05_screen_011 : {
		text: 'Hoe diep is het ondiepste punt? Kies voor optie 1, 2 of 3. ',
		avatarId: 5,
		options: [
			{
				text:'min 4 m',
				followUpChunk: 'l_05_screen_good',
			},
			{
				text:'min 6 m',
				followUpChunk: 'l_05_screen_wrong',
			},
			{
				text:'min 8 m',
				followUpChunk: 'l_05_screen_wrong',
			}
		]
	},
	l_05_screen_good : {
		text: 'De vaargeul is op de ondiepste plek -4m diep ten opzichte van het Normaal Amsterdams Peil (NAP). Dit wordt genoteerd.',
		avatarId: 5,
		blockInput: true
	},
	l_05_screen_wrong : {
		text: 'Dat is niet het ondiepste punt. Kijk nog eens goed op de kaart.',
		avatarId: 5,
		followUpChunk: 'l_05_screen_011',
		blockInput: true
	},		
	l_05_screen_02 : {
		text: 'Onder invloed van het getij fluctueert de waterspiegel gedurende de dag. De tijden wanneer ons schip over de vaarweg kan varen zijn afhankelijk van dit gegeven. De grafiek laat zien hoe hoog de waterstand is van de afgelopen en aankomende dagen. Bekijk de grafiek.',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_021'
	},
	l_05_screen_021 : {
		text: 'Eén van de lijnen geeft informatie over de waterstand voor de aankomende uren. Deze informatie is nodig. Welke van de gekleurde lijnen laat deze informatie zien? ',
		avatarId: 5,
		options: [
			{
				text:'blauw',
				followUpChunk: 'l_05_screen_021_wrong',
			},
			{
				text:'rood',
				followUpChunk: 'l_05_screen_021_good',
			},
			{
				text:'zwart',
				followUpChunk: 'l_05_screen_021_wrong',
			}
		]
	},
	l_05_screen_021_good : {
		text: 'De rode lijn geeft inderdaad de juiste informatie! De rode lijn laat namelijk zien wat de voorspelde waterstand is voor vandaag en morgen.',
		avatarId: 5,
		blockInput: true,
	},	
	
	l_05_screen_021_wrong : {
		text: 'Dit is niet de goede lijn. De blauwe lijn visualiseert de waterstand van gister en eergister. De zwarte lijn laat het astronomische getij zien. Je hebt de voorspelde waterstand voor vandaag en morgen nodig. Kijk nog eens goed en kies opnieuw. ',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_021',

	},

	l_05_screen_03 : {
		text: 'Elke brug heeft een andere hoogte. Het schip dat de sluisdeur vervoert, moet onder alle bruggen door kunnen varen. Analyseer de brughoogtes van de drie bruggen door op de kaart te klikken. ',
		avatarId: 5,
		blockInput: true,

	},
	l_05_screen_031 : {
		text: 'Wat is de hoogte van de laagste brug?',
		avatarId: 5,
		options: [
			{
				text:'10 m',
				followUpChunk: 'l_05_screen_03_wrong',
			},
			{
				text:'12 m',
				followUpChunk: 'l_05_screen_03_wrong',
			},
			{
				text:'8 m',
				followUpChunk: 'l_05_screen_03_good',
			}
		]
	},
	l_05_screen_03_wrong : {
		text: 'Dat is niet de laagste brug. Kijk nog eens goed op de kaart.',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_031',
	},
	l_05_screen_03_good : {
		text: 'De Oosterbeek-brug is 8 m hoog. Het staat genoteerd.',
		avatarId: 5,
		blockInput: true,
	},
	l_05_screen_04 : {
		text: 'Alle data die nodig is om het algoritme te schrijven is nu bekend! Wist je dat alle informatie over waterhoogte voor iedereen beschikbaar is? Je kunt het hier bekijken: <a href="https://waterinfo.rws.nl/#!/kaart/astronomische-getij/" target="_blank">waterinfo.rws.nl</a>',
		avatarId: 5,
		followUpChunk: 'l_05_screen_041',
		blockInput: true,
	},
	l_05_screen_041 : {
		text: 'Een algoritme kan verschillende vormen hebben, in deze situatie is het een wiskundige formule. Hiermee kun je bijvoorbeeld informatie trechteren op basis van \'regels\'. In dit geval moet er een regel geschreven worden die aangeeft tussen welke twee standen de waterstand mag vallen zodat het schip over de vaarweg kan varen zonder de bodem of een brug te raken.',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_042',
	},
	l_05_screen_042 : {
		text: 'De formule moet rekening houden met de waterstand, de hoogte van de brug, de diepte van de vaargeul en de ligging van het schip in het water. Het is verstandig om de formule stap voor stap op te bouwen, in dit geval bestaat de formule uit twee delen.',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_043',
	},

	l_05_screen_043 : {
		text: 'Laten we beginnen met deel 1: het waterpeil ten opzichte van de diepte van de vaargeul. Voer het algoritme in door de waarde-blokken in de juiste volgorde in te vullen.  ',
		avatarId: 5,
		blockInput: true,
	},

	goed_antwoord_deel_1 : {
		text: 'Top! Het is je gelukt. Het eerste deel van het algoritme staat. Op naar het tweede deel. ',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'goed2_antwoord_deel_1'
		//followUpChunk: 'l_05_screen_051'
	},	
	goed2_antwoord_deel_1 : {
		text: 'Tijd voor deel twee van het algoritme. Schrijf een formule die rekening houdt met het waterpeil en de hoogte van de brug.',
		avatarId: 5,
		blockInput: true,
		//followUpChunk: 'l_05_screen_051'
	},
	fout_antwoord_deel_1 : {
		text: 'Let goed op de onderstreepte woorden. Waterstand is waterpeil. Voer deze dus als eerste in. Hoger is > (het groter-dan-teken) en wordt als tweede geplaatst. Probeer op deze manier het algoritme in te vullen! Klik op \'reset\' om het nog een keer te proberen.',
		avatarId: 7,
	},

	goed_antwoord_deel_2 : {
		text: 'Goed gedaan! We hebben nu beide delen van het algoritme geschreven. ',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_05'
	},
	fout_antwoord_deel_2 : {
		text: 'Let goed op de onderstreepte woorden. Waterstand is waterpeil. Voer deze als eerste in. Lager is < (het kleiner-dan-teken) en wordt als tweede geplaatst. Probeer op deze manier het algoritme in te vullen! Klik op \'reset\' om het nog een keer te proberen. ',
		avatarId: 7,
	},

	l_05_screen_05 : {
		text: 'Het algoritme is geschreven. Het resultaat zegt het volgende: de waterstand moet tussen de -2 m en 5 m zijn, dan kan het schip varen. Het algoritme wordt geplot op de rode waterstandlijn. Dit geeft de mogelijke vaartijden. ',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_050'
	},
	l_05_screen_050 : {
		text: 'De grafiek laat zien dat er drie mogelijke vaartijden zijn. Nu moet alleen nog gekeken worden welk van deze mogelijkheden de beste optie is. ',
		avatarId: 5,
		blockInput: true,
		followUpChunk: 'l_05_screen_051'
	},

	l_05_screen_051 : {
		text: 'Het schip uit Engeland is om 07:30 aangekomen in Rotterdam Haven. Het binnenvaartschip met de sluisdeur is klaar om te varen. Welke van de drie tijdsloten kan het beste gevaren worden? De sluisdeur moet zo spoedig mogelijk ter plaatse zijn.',
		avatarId: 5,
		//blockInput: true,
		options: [
			{
				text:'07:00 - 15:00 vandaag',
				followUpChunk: 'l_05_good_01'
			},
			{
				text:'20:00 - 03:00 vandaag',
				followUpChunk: 'l_05_wrong_01'

			},
			{
				text:'08:00 - 12:00 morgen',
				followUpChunk: 'l_05_wrong_01'
			}
		]
	},
	l_05_good_01 : {
		text: 'Je hebt de snelste optie gevonden!',
		avatarId: 5,
		followUpChunk: 'l_05_help_01',
		blockInput: true,
	},

	l_05_wrong_01 : {
		text: 'Dit is niet de snelste optie. De sluisdeur moet zo snel mogelijk ter plaatse zijn. Daarom is het tijdslot 07:00 - 15:00 vandaag de beste optie. ',
		avatarId: 5,
		followUpChunk: 'l_05_screen_051',
		blockInput: true,

	},
	l_05_final_01 : {
		text: 'Het schip is onderweg vanuit Rotterdam. Het komt om 12:00 aan in Arnhem. Vanaf daar gaat de sluisdeur verder over de weg. Ga terug naar het hoofdmenu en help de vrachtwagenchauffeur met het bepalen van de juiste route over de weg!',
		avatarId: 5,
		blockInput: true,
	},
	l_05_help_01 : {
		text: 'Dankzij het algoritme is het volgende bepaald. De waterstand moet tussen de -2m en 5m hoogte zijn. Het meest geschikte tijdslot is dat van 07:00 - 15:00. ',
		avatarId: 5,
		followUpChunk: 'l_05_final_01',
		blockInput: true,
	},
	// l_05_help_02 : {
	// 	text: 'Let op! Het is belangrijk om rekening te houden met de veiligheidsmarges. Tussen het schip en de vaargeul, en het schip en de brug moeten marges van 1 meter zitten. ',
	// 	avatarId: 5,
	// },
	
	//hints
	l_05_play_01:{
		text: 'Kijk goed naar de onderstreepte tekst. Elk onderstreept gedeelte staat voor één waarde-blok.',
		avatarId: 5,
		blockInput: true
	},
	l_05_answer_01:{
		text: 'Helaas, het door jou gegeven antwoord is onjuist.  Het goede antwoord is te zien op het scherm hiernaast.</br></br>Het schip kan door de rivier varen als de buik van het schip niet de bodem van het rivier raakt. Dit betekent dat het waterpeil ten opzichte van NAP hoger moet zijn dan het verschil tussen de diepte van de vaargeul en de dieptegang van de boot.</br></br>Klik nogmaals op \'go\' om verder te gaan.',
		avatarId: 8,
	},
	l_05_answer_02:{
		text: 'Helaas, het door jou gegeven antwoord is onjuist.  Het goede antwoord is te zien op het scherm hiernaast.</br></br>Het schip kan door de rivier varen als het hoogste punt van het schip niet de brug raakt. Dit betekent dat het waterpeil ten opzichte van N.A.P lager moet zijn dan het verschil tussen de hoogte van de brug en de hoogte van de bootligging.</br></br>Klik nogmaals op \'go\' om verder te gaan.',
		avatarId: 8,
	},

	//#endregion

	//#region level 6 control room

	l_06_introductie_01 : {
		text: 'Je hebt een aardige reis afgelegd! Dankzij jou is de sluisdeur succesvol vervoerd en is de sluis gerepareerd. Ook is eventuele ontevredenheid bij de burgers en de schade aan het milieu beperkt gebleven. ',
		avatarId: 2,
		followUpChunk: 'l_06_introductie_02',
		blockInput: true,
	},
	l_06_introductie_02 : {
		text: 'Het visualiseren van de verzamelde data is de laatste actie en is net zo belangrijk als de andere taken. Een heldere visualisatie is nodig voor rapportage en transparantie over de werkzaamheden van Rijkswaterstaat. Zo zorg je ervoor dat je leidinggevende en de betrokken partijen een helder overzicht krijgen. Bekijk de <a href="https://vimeo.com/371356273" target="_blank">video</a> over verschillende soorten datavisualisaties. Maak daarna zelf een datavisualisatie die je kunt gebruiken tijdens de rapportage over deze calamiteit. ',
		avatarId: 2,
		blockInput: true,
		followUpChunk: 'l_06_introductie_03',
	},
	l_06_introductie_03 : {
		text: 'Klik op de monitoren om te beginnen met het visualiseren van de data.',
		avatarId: 2,
		blockInput: true,
	},

	// Monitor 1 - Staafdiagram

	l_06_monitor1 : {
		text: 'Welkom bij opdracht 1',
	},

	l_06_monitor1_wrong1 : {
		text: 'Dit diagram lijkt me niet geschikt voor het visualiseren van het sentiment van de burgers. Probeer het nogmaals.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor1_correct1 : {
		text: 'Super. Een gestapelde staafdiagram laat perfect de verhoudingen tussen negatief, positief en neutraal sentiment zien. ',
		avatarId: 2,
		blockInput: true,
	},


	l_06_monitor1_wrong2 : {
		text: 'Deze titel is niet specifiek genoeg. De start van de tijdsperiode en de eenheid moeten allebei duidelijk zijn.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor1_correct2 : {
		text: 'Helemaal goed. Ik vul het in.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor1_wrong3 : {
		text: 'Deze verdeling komt niet overeen met de data. Kijk er nog eens goed naar. ',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor1_correct3 : {
		text: 'Super!',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor1_wrong4 : {
		text: 'Deze titel lijkt me niet geschikt. De titel moet het onderwerp duidelijk maken. Kijk nog eens.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor1_correct4 : {
		text: 'Helder. Deze titel beschrijft kort en concreet waar het diagram over gaat. ',
		avatarId: 2,
		blockInput: true,
	},

	// Monitor 2

	l_06_monitor2_wrong1 : {
		text: 'Dit diagram lijkt me niet geschikt voor het visualiseren van de hoeveelheid stookolie in het water. Probeer het nog eens.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor2_correct1 : {
		text: 'Super. Een lijndiagram laat perfect zien hoe de hoeveelheid stookolie in het water verandert naarmate de tijd verstrijkt.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor2_wrong2 : {
		text: 'Deze titel lijkt me niet specifiek genoeg. ',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor2_correct2 : {
		text: 'Helemaal goed. Ik vul het in.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor2_wrong3 : {
		text: 'Deze titel klopt niet helemaal. De titel moet zo specifiek mogelijk zijn. Probeer het nogmaals. ',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor2_correct3 : {
		text: 'Top. Ik vul het in.',
		avatarId: 2,
		blockInput: true,
	},

	// Monitor 3

	l_06_monitor3_wrong1 : {
		text: 'Deze kaart lijkt me niet geschikt voor het visualiseren van een route. Kies een andere kaart.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor3_correct1 : {
		text: 'Goed gedaan. Op deze kaart kunnen we de route mooi laten zien.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor3_wrong2 : {
		text: 'Dit is niet de goede volgorde van steden. Probeer het nog een keer. ',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor3_correct2 : {
		text: 'Helemaal goed!',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor3_wrong3 : {
		text: 'Dit waren niet de tijden waarop de sluisdeur aankwam op de verschillende locaties. Probeer het nog een keer. ',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor3_correct3 : {
		text: 'Super! Dit zijn inderdaad de juiste tijden.',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor3_wrong4 : {
		text: 'Niet helemaal. Welke titel geeft het beste de route van de sluis weer?',
		avatarId: 2,
		blockInput: true,
	},

	l_06_monitor3_correct4 : {
		text: 'Helemaal mee eens!',
		avatarId: 2,
		blockInput: true,
	},

	//#region 

	l_06_eind_01 : {
		text: 'Er is een visualisatie-overzicht gemaakt. Dit geeft een goed beeld van alle data en informatie die we hebben opgehaald gedurende het project. ',
		avatarId: 2,
		blockInput: true,
		followUpChunk: 'l_06_eind_02',
	},

	l_06_eind_02 : {
		text: 'Dit project laat goed zien hoeveel data er om ons heen is, wat voor verschillende soorten data er zijn en wat je ermee kunt. ',
		avatarId: 2,
		blockInput: true,
		followUpChunk: 'l_06_eind_03',
	},

	l_06_eind_03 : {
		text: 'Bedankt voor je hulp! Dankzij jou is het incident met de sluis op de best mogelijke manier opgelost.',
		avatarId: 2,
		blockInput: true,
	},

	//#endregion

	//#region Oud

	l_06_monitor1_01 : {
		text: 'Goed gedaan',
		avatarId: 6,
	},
	l_06_monitor1_02 : {
		text: 'Fout',
		avatarId: 6,
	},
	l_06_monitor1_03 : {
		text: 'Foute grafiek keuze',
		avatarId: 6,
	},
	l_06_monitor2 : {
		text: 'Welkom bij opdracht 2',
		avatarId: 6,
	},
	l_06_monitor3 : {
		text: 'Welkom bij opdracht 3',
		avatarId: 6,
	},

	//#endregion

	//#endregion

	// #region other

	wip : {
		text: 'Dit onderdeel is nog onder constructie',
	},


	//#endregion

//#endregion
};


export {dialogueChunks} ;

import { CheckIcon } from 'assets/icons';
import { useGlobalContext } from 'context/global';
import { setSubCompleted } from 'data/objectives';
import { useState } from 'react';
import { theme } from 'style';
import styled, { css, keyframes } from 'styled-components';

 
const Resultaat = () => {

	const {updateObjectiveData, setActiveDialogueChunk, setCloseButtonActive} = useGlobalContext();
	const [switcher, setSwitch] = useState<boolean>(false);

	const [send, setSend] = useState<boolean>(false);
	const [selection, setSelection] = useState<number>(0);

	const complete = () => {
		setSubCompleted(5, 1);
		setSubCompleted(0, 5);
		updateObjectiveData && updateObjectiveData();
		setCloseButtonActive && setCloseButtonActive(true);
	};

	const checkSend = () => {
		if(selection === 1){
			setSend(true);
			setActiveDialogueChunk && setActiveDialogueChunk('l_04_finalquestion_05');
		}
		else {
			// Dialogue wrong
			setActiveDialogueChunk && setActiveDialogueChunk('l_04_finalquestion_05_wrong');
			setSelection(0);
		}
	};

	return (  
		<>
			<Container height={40}>
				<span style={{marginBottom: '5%', fontSize: '3vh' }}>Op basis van data is de volgende informatie beschikbaar geworden</span>
				<span>Tussen Meppel en Heerenveen staat een brug waar vandaag 100 boten onder doorvaren.</span>
				<span>Tussen Zwolle en Emmeloord staat een tunnel met een hoogte van 3,50m.</span>
				<span>Tussen Emmeloord en Heerenveen staat een file die drie uur duurt. </span>
			</Container>
			{!send ? <Container animate={send} height={60} style={{backgroundColor:theme.colors.accent_4, borderRadius: '2% 2% 0 0' }}>
				<Column>
					<span>De mogelijke routes voor het vervoer van de sluisdeur:</span>
					<div>kies de beste route</div>
					<Button width={'100%'}  selected={selection === 1 ? true : false} onClick={(e) => {setSelection(1); e.currentTarget.blur();}}>Zwolle - Meppel - Heerenveen</Button>
					<Button width={'100%'}  selected={selection === 2 ? true : false} onClick={(e) => {setSelection(2); e.currentTarget.blur();}}>Zwolle - Emmeloord - Heerenveen</Button>
					<Button width={'100%'} selected={selection === 3 ? true : false} onClick={(e) => {setSelection(3); e.currentTarget.blur();}}>Harderwijk - Emmeloord - Heerenveen</Button>
				</Column>
				<Button width={'40%'} style={{height: '10%'}} disabled={selection === 0 ? true: false} onClick={() => checkSend()}>Verzend gegevens naar control room</Button>
			</Container>
				:
				<Box>
					{ send &&
				<Sent onAnimationEnd={complete}><div>Data verzonden</div></Sent>
					}
				</Box>}
		</>
	);
};

//#region Styled Components

const bounceIn = keyframes`
	0% {
		transform: translate(-50%, 200%);
	}
	60% {
		transform: translate(-50%, -90%);
	}

	100% {
		transform: translate(-50%, -50%);
	}
`;

const Sent = styled.div`

	position: relative;
	top:60%;
	left:12%;

	transform: translateY(200%);

	height: 100%;
	width: 25%;

	background-image: url(${CheckIcon});
	background-position:center;
	background-size: 100% 100%;


	& > div {
		position: absolute;
		font-size: 2.5vh;
		bottom: -50%;
		left:50%;
		width: 200%;
		text-align: center;
		transform: translate(-50%, -50%);
	}

	animation: ${bounceIn} 1s ease-in-out forwards;
	animation-delay: 1.2s;

`;

const Columns = styled.div`
	display: flex;

	text-align: left;

	height: 70%;

	color: white;

`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: stretch; */

	text-align: center;
	margin-bottom: 3%;

	height: 70%;
	width: 50%;

	& > button {
		margin-bottom: 3%;
		//padding: 30px;
		display: flex;
		justify-content: center;
	}

	& > span {
		color:white;
		/* margin-bottom: 50px; */
	}

	& > div {
		color:white;
		font-size:2vh;
		margin-bottom: 5%;
	}
`;

const Container = styled.div<{height:number, animate?:boolean}>`

    height: ${p => p.height}%;

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	font-size: 2.5vh;
    color: ${theme.colors.accent_2};
    text-align: center;

    font-weight: bold;

    padding: 0px;

	transition: transform 1s;

	${ p => p.animate && css`
		transform: translateY(100%);
	`}
`;
const Box = styled.div`
    width: 100%;
	height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;
const Button = styled.button<{selected?:boolean, width:string}>`

    background-color: ${theme.colors.primary};
    color: ${theme.colors.accent_2};
    margin: 0;

	cursor: pointer;

    display: flex;
    align-items: center;
justify-content: center;
    font-weight: bold;
	font-size: 2.5vh;
    line-height: 100%;
	text-align: center;
	width: ${p => p.width};
	height: 20%;
	border: none;

	padding: 1%;

    box-shadow: 0 0 10px grey;

	&:hover, :focus{
		color: ${theme.colors.hover};
		background-color: ${theme.colors.hover_bg};
	}

	&:disabled {
        pointer-events: none;
        filter: grayscale(100%);
    }

	${ p => p.selected && css`
	
		outline: solid 4px ${theme.colors.accent_2};
		text-decoration: underline;

	`}

`;

//#endregion
 
export default Resultaat;
import { CheckIcon, LockIcon } from 'assets/icons';
import styled from 'styled-components';

interface LockProps {
	top : string
	left : string
	locked : boolean
	completed: boolean
}

const Lock = styled.div<LockProps>`
   	background-image: url(${p => p.locked? LockIcon : p.completed? CheckIcon : ''}) ;
    background-position: center;
    background-size:  45%;
	background-repeat: no-repeat;
	position: absolute;
	pointer-events: none;
	left:0;
	top:0;
	right:0;
	bottom:0;
	z-index: 6;
	width: 100%;
	height: 100%;
	aspect-ratio: 1/1;

`;

export default Lock;
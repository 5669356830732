import React, {useState, useRef} from 'react';
import { useGlobalContext } from 'context/global/index';
import { useTranslation } from 'react-i18next';
import ConsoleLanguageSelect from './Views/LanguageSelect';
import ConsoleSceneSelect from './Views/SceneSelect';
import ConsoleModelSelect from './Views/ModelSelect';
import { ConsoleContainer, ConsoleRow, ConsoleTitle, ConsoleWrap } from './Views/Styles';
import ConsoleDialogSelect from './Views/DialogSelect';
import ConsoleShowMap from './Views/Showmap';
import ConsoleLevelSelect from './Views/LevelData';
import ObjectivesSelect from './Views/ObjectivesData';

const Console = () => {
	const {displayConsole} = useGlobalContext();
	const { t } = useTranslation();
	
	const [activeInterfaceItem, setActiveInterfaceItem] = useState('Scenes');
	const selectTabRef = useRef<HTMLSelectElement>(null);
	const menuOptions = ['Language', 'Scenes', 'Dialog', 'Model', 'LevelData', 'Objectives data'];

	// Render Function
	return <> {displayConsole && (<ConsoleContainer>
		<ConsoleWrap>
			<ConsoleTitle>{t('general.1')}</ConsoleTitle>
			<ConsoleShowMap/>
			<ConsoleRow>
				<select onChange={(e) => setActiveInterfaceItem(e.target.value)} ref={selectTabRef} value={activeInterfaceItem}>
					{menuOptions && menuOptions.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
				</select>
				
			</ConsoleRow>
			{activeInterfaceItem === 'Scenes' && <ConsoleSceneSelect/>}
			{activeInterfaceItem === 'Language' && <ConsoleLanguageSelect/>}
			{activeInterfaceItem === 'Dialog' && <ConsoleDialogSelect/>} 
			{activeInterfaceItem === 'Model' && <ConsoleModelSelect/>}
			{activeInterfaceItem === 'LevelData' && <ConsoleLevelSelect/>}
			{activeInterfaceItem === 'Objectives data' && <ObjectivesSelect/>}			
		</ConsoleWrap>
	</ConsoleContainer>)}
	</>;
	
};

export default Console;


import {  useEffect,  } from 'react';
import {  useGlobalContext } from 'context/global';
import ChartOveriew from './ChartOverview';
import { Objectives } from 'data/objectives';
import ZeeschermBorder from 'components/UniversalComponent/Borders/zeeschermborder';

const ZeeScherm1 = () => {

	const { setActiveDialogueChunk } = useGlobalContext();

	useEffect(() => {
		if(!Objectives[4].subObjectives[0].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_03_bewolking_01');
		}
	},[]);

	return (
		<>
			<ZeeschermBorder>
				<ChartOveriew id={0}/>
			</ZeeschermBorder>
		</>
	);
};

export default ZeeScherm1;
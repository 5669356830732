import { wrong } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import { useGlobalContext } from 'context/global';
import { useEffect, useState } from 'react';
import { useResponsiveFontSize } from 'react-responsive-font-size';
import Theme from 'style/theme';
import styled, { keyframes } from 'styled-components';
import { FilterProps } from '../index';
import { measureDots } from './data';

interface ScanFilterProps {
    img: string;
}
 
const MeasureFilter = (props : ScanFilterProps & FilterProps  ) => {
	const [afmeting, setAfmeting] = useState<string>('AA');
	const [counter, setCounter] = useState<number>(0);
	const [load, setLoad] = useState<boolean>();
	const [error, setError] = useState<boolean>(false);
	const [ selection, setSelection] = useState<{place:number, id:number}[]>([]);
	const { setActiveDialogueChunk} = useGlobalContext();
	const {playSound} = useAudioContext();
	const responsiveFont = useResponsiveFontSize(0.8, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });

	const setMeasure = (id, place, index) => {
		setSelection(a => [...a, {id:id, place:place}]);
		if (place == 0) {
			setAfmeting(id+afmeting[1]);
			measureDots[index].first.selected = true;
			setCounter(counter+1);
		}
		if (place == 1) {
			setAfmeting(afmeting[0]+id);
			measureDots[index].second.selected = true;
			setCounter(counter+1);
		}
	};

	useEffect(() => {
		if (counter === 2) {
			switch(afmeting){
			case '00':
				measure(0);
				break;
			case '11':
				measure(1);
				break;
			case '22':
				measure(2);
				break;	
			case '33':
				measure(3);
				break;
			case '44':
				measure(4);
				break;
			default:				
				if(afmeting[1] === 'A' || afmeting[0] === 'A'){
					playSound && playSound(wrong);
					setError(true);	
				}
				playSound && playSound(wrong);	 
				setError(true);	
				setTimeout(() => {	
					setSelection([]);	
					measureDots[selection[0].id][selection[0].place == 0? 'first' : 'second'].selected = false;
					measureDots[selection[1].id][selection[1].place == 0? 'first' : 'second'].selected = false;
					setCounter(0);
					setError(false);
					setAfmeting('AA');
				}, 2000);	
			} 
		}
	},[counter]);

	const measure = (index) => {
		setLoad(true);
		setTimeout(() => {
			measureDots[index].completed = true;
			setLoad(false);
			checkIfComplete();
		}, 3500);
	};

	const onComplete = () => {
		setCounter(0);
		setSelection([]);	
	};

	const checkIfComplete = () => {
		if (measureDots[0].completed == true && measureDots[1].completed == true && measureDots[2].completed == true && measureDots[3].completed == true && measureDots[4].completed == true) {
			props.onCompletion();
		}
	};

	return (
		<Container>
			{measureDots.map((measure, index) => (
				<>	
					{load && 
					//loading screen
						<ScanningStatus>Bezig met meten{[...Array(3)].map((x, index) => <span key={`${index}-dots-filter-scan`}>.</span>)}</ScanningStatus>}
					{afmeting == measure.first.id+measure.second.id || measureDots[index].completed == true ?
						//line area
						<>        
							{measure.horizontal ? <ScanLineHorizontaldiv 
								rotate={measure.rotate? measure.rotate : 0}
								width={measure.second.left-measure.first.left} 
								left={measure.first.left+1} 
								top={measure.first.top+1}>
								<ScanLineHorizontal	complete={props.complete} onAnimationEnd={onComplete}>
									{measureDots[index].completed == true && <Outcome>{measure.afmeting}</Outcome>
									}								
								</ScanLineHorizontal>
							</ScanLineHorizontaldiv>
								:
								<ScanLineVerticalDiv
									rotate={measure.rotate? measure.rotate : 0}										
									height={measure.second.top-measure.first.top} 
									left={measure.first.left+0.7} 
									top={measure.first.top+1}>
									<ScanLineVertical complete={props.complete} onAnimationEnd={onComplete}>											
										{measureDots[index].completed == true && <Outcome2>{measure.afmeting}</Outcome2>}				
									</ScanLineVertical>
								</ScanLineVerticalDiv>
							}
						</>
						: ''} 	
					{measureDots[index].completed != true &&
					//dots area
					<>
						<Dots usable={counter == 2 ? false : true} error={error} selected={measure.first.selected} key={`${index}-${measure}-dot1`} left={measure.first.left} top={measure.first.top} onClick={() => setMeasure(measure.first.id, measure.first.place, index)}/>
						<Dots usable={counter == 2 ? false : true} error={error} selected={measure.second.selected} key={`${index}-${measure}-dot2`} left={measure.second.left} top={measure.second.top} onClick={() => setMeasure(measure.second.id, measure.second.place, index)}/>
					</>}
				</>
			))}
		</Container>
	);

};

//#region Animations

const HorizontalAnimation = keyframes`
	0% {
		transform: scaleX(0%)
	}
	100% {
		transform: scaleX(100%)
	}
`;
const VerticalAnimation = keyframes`
	0% {
		transform: scaleY(0%)	
	}
	100% {
		transform: scaleY(100%)	
	}
`;
const DotAnimation = keyframes`
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

//#endregion

//#region Styled Components
interface DotsProps {
	top : number
	left : number
	selected: boolean
	usable: boolean
	error: boolean
}

const Dots = styled.div<DotsProps>`
	position: absolute;
	left: ${p => p.left+'%'};
	top: ${p => p.top+'%'};
	height: 2%;
	width: 1.5%;
	background-color: ${p => p.selected && p.error?  'red' :  p.selected? 'yellow' : 'lightgrey' };
	border-radius: 50%;
	border: none;
	pointer-events: ${p => p.usable ? 'auto' : 'none!important' };
	cursor: pointer!important;
  `;

const Outcome = styled.div`
	margin-top: 5%;
	background-color: white;
    width: max-content;
	height: max-content;
	font-size: 4vh;
`;

const Outcome2 = styled.div`
	background-color: white;
	height: max-content;
    width: max-content;
	font-size: 4vh;
`;
const ScanningStatus = styled.div`
	position: absolute;
	left:50%;
	top:50%;
	transform: translate(-50%,-50%);
	font-size: 5vh;

	& > span {
		font-size: 1.2em;
		animation: ${DotAnimation} 2s infinite;
	}
	& :nth-child(2){
		animation-delay: 400ms;
	}
	& :nth-child(3){
		animation-delay: 600ms;
	}	
`;

const Container = styled.div`
	position: absolute;
	left:0;
	top:0;
	bottom: 0;
	right:0;
	pointer-events: none;
	& > * {
		pointer-events: all;
	}
`;

interface scanLineHProps {
	complete : boolean
}

const ScanLineHorizontal = styled.div<scanLineHProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 5px;
	width: 100%;
	background-color: ${Theme.colors.accent_1};
	animation: ${p => p.complete?  'none' : HorizontalAnimation} 4s; 
`;

interface scanLineHdivProps {
	rotate : number
	width : number
	left : number
	top : number
}

const ScanLineHorizontaldiv = styled.div<scanLineHdivProps>`
	position: absolute;
	width: ${p => p.width+'%'};
	top: ${p => p.top+'%'};
	left: ${p => p.left+'%'};
	transform-origin: left center;
	transform: rotate(${p => p.rotate+'deg'});
`;

interface scanLineVDivProps {
	rotate : number
	height : number
	left : number
	top : number
}

const ScanLineVerticalDiv = styled.div<scanLineVDivProps>`
	position: absolute;
	height: ${p => p.height+'%'};
	top: ${p => p.top+'%'};
	left: ${p => p.left+'%'};
	transform-origin: left center;
	transform: rotate(${p => p.rotate+'deg'});
`;

interface scanLineVProps {
	complete : boolean
}

const ScanLineVertical = styled.div<scanLineVProps>`
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	width: 5px;
	background-color: ${Theme.colors.accent_1};
 	animation: ${p => p.complete?  'none' : VerticalAnimation} 4s; 
`;

//#endregion
 
export default MeasureFilter;
import { useGlobalContext } from 'context/global';
import { useTranslation } from 'react-i18next';
import { ConsoleRow, ConsoleLabel } from './Styles';

const ConsoleShowMap = () => {

	const { t } = useTranslation();

	const {levelSelectActive, setLevelSelectActive} = useGlobalContext();

	const onCloseMap = (event) => {
		setLevelSelectActive && setLevelSelectActive(event.target.checked);
	};

	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.6')}</ConsoleLabel>
			<input 
				type="checkbox" 
				onChange={onCloseMap}
				defaultChecked={levelSelectActive}
			/>
		</ConsoleRow>
	);
};

export default ConsoleShowMap;
import { ResizeObserver } from '@juggle/resize-observer';
import { Canvas } from '@react-three/fiber';
import Close from 'components/Buttons/CloseButton';
import { AudioContext } from 'context/audio';
import { GlobalContext, useGlobalContext } from 'context/global';
import { ThreeContextProvider } from 'context/three';
import { ReactNode, useContext } from 'react';
import styled from 'styled-components';

const CanvasContainer = styled.div`

    position: absolute;
    left:0;
	top:0;
	z-index: 2;
    height: 100vh;
    width: 100vw;

`;

type ThreeCanvasProps = {
    children: ReactNode;
}

const camera = {
	position:[0,0,2],
	fov: 90,
};
const Allowed = ['sluishologram', 'scheurkalender','scheurkalender2','kantoortv'];

const ThreeCanvas = ({children}: ThreeCanvasProps) => {

	const { activeIntObj } = useGlobalContext();
	const globalContextValues = useContext(GlobalContext);
	const audioContextValues = useContext(AudioContext);

	return (
		<>
			{ activeIntObj && Allowed.includes(activeIntObj) &&
				<CanvasContainer >
					<Close/>
					<Canvas resize={{polyfill: ResizeObserver}} camera={camera}>
						<GlobalContext.Provider value={globalContextValues}>
							<AudioContext.Provider value={audioContextValues}>
								<ThreeContextProvider>
									{children}
								</ThreeContextProvider>
							</AudioContext.Provider>
						</GlobalContext.Provider>
					</Canvas>
				</CanvasContainer>
			}
		</>
	);
};

export {Allowed};

export default ThreeCanvas;
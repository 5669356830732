import styled from 'styled-components';

const FilterLabel = styled.button`
    position: absolute;
    background-color:white;
    border: none;
    width: 20%;
    height: 5%;
    left:50%;
    top:5%;
    transform: translateX(-50%);

`;

const Container = styled.div`

    position: relative;

    height:100%;
    width: 100%;

    pointer-events: none;

    & > * {
        pointer-events: all;
    }

`;

export interface FilterProps {
    img: string;
    onCompletion: () => void;
    complete: boolean;
}


export {FilterLabel, Container};
import { CrossIconHover, CrossIconNormal } from 'assets/icons';
import { useGlobalContext } from 'context/global';
import { setSubCompleted } from 'data/objectives';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { proportional } from 'util/proportional';

const CloseButton = styled.button`

	${proportional};

    background-color: ${props => props.theme.colors.primary};
	position: absolute;
	top:0;
	right:0;
	z-index: 4;

	border: none;

	margin: 30px;
	height: 50px;
	width:50px;

	cursor: pointer;

	background-image: url(${CrossIconNormal});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 50%;

	&:hover, :focus {
		background-image: url(${CrossIconHover});
		background-color: ${p => p.theme.colors.hover_bg};
	}
    
`;

const Close = () => {
    
	const {closeButtonActive, activeIntObj, setActiveIntObj, updateObjectiveData} = useGlobalContext();

	const onCloseObject = () => {
		setActiveIntObj && setActiveIntObj('');
	};

	return (<>
		{ closeButtonActive && 
		<motion.div   
			animate={{ opacity: [0,0,1] }}
			transition={{ duration: 1.2 }}>
			<CloseButton onClick={onCloseObject}/>
		</motion.div>
		}
	</>
	);
};

export default Close;
import { goal_accomplished_01, goal_accomplished_02 } from 'assets/sounds';
import { objective } from 'context/global/types';
import Levels, { checkLevelsForUnlock, setLevels } from './levels';

import {SCORM} from 'pipwerks-scorm-api-wrapper';

let Objectives:objective[] = [
	{
		text: 'Vervang de sluisdeur, zorg ervoor dat burgers en het milieu zo min mogelijk overlast ervaren en rapporteer hierover aan je leidinggevende',
		completed: false,
		levelId: 0,
		subObjectives:[
			{
				text: 'Voltooi de tutorial',
				completed: false,
			},
			{
				text: 'Mini game 1: Breng de schade aan de sluis in kaart',
				completed: false,
			},
			{
				text: 'Mini game 2: Stel een communicatieplan op',
				completed: false,
			},
			{
				text: 'Mini game 3: Bepaal de juiste route uit alle mogelijke vaarpunten',
				completed: false,
			},
			{
				text: 'Mini game 4: Bepaal vaartijden voor de vaarweg',
				completed: false,
			},
			{
				text: 'Mini game 5: Stel de route van de vrachtwagen vast',
				completed: false,
			},

			{
				text: 'Mini game 6: Maak een visualisatie van alle verzamelde data; rapporteer hierover en sluit het project af',
				completed: false,
			}
		]
	},
	{
		text: 'Voltooi de tutorial',
		completed: false,
		levelId: 0,
		subObjectives: [
			{
				text: 'Klik op het kruisje om het overzicht te sluiten',
				completed: false,	
			},
			{
				text: 'Voltooi de rest van de tutorialstappen',
				completed: false,	
			},
		]
	},
	{
		text: 'Onderzoek de sluis',
		completed: false,
		levelId: 1,
		subObjectives: [
			{
				text: 'Scan met de luchtdrone de afmetingen, schade en waterkwaliteit',
				completed: false,	
			},
			{
				text: 'Scan met de onderwaterdrone schade en obstakels',
				completed: false,	
			},
			{
				text: 'Genereer een 3D-model van de sluis',
				completed: false,	
			},
		]
	},
	{
		text: 'Maak een communicatieplan aan de hand van social media posts en e-mails',
		completed: false,
		levelId: 2,
		subObjectives:[
			{
				text: 'Inspecteer het grote scherm',
				completed: false,	
			},
			{
				text: 'Voltooi de text mining opdracht',
				completed: false,	
			}
		]
	},
	{
		text: 'Bepaal de juiste route voor het schip',
		completed: false,
		levelId: 3,
		subObjectives:[
			{
				text: 'Bepaal mogelijke vaarpunten op de wolkendek kaart',
				completed: false,	
			},
			{
				text: 'Bepaal mogelijke vaarpunten op de golfhoogte kaart',
				completed: false,	
			},
			{
				text: 'Bepaal mogelijke vaarpunten op de zeevaart kaart',
				completed: false,	
			},
			{
				text: 'Bepaal mogelijke vaarpunten op de weerrisico kaart',
				completed: false,	
			},
			{
				text: 'Bepaal de juiste route uit alle mogelijke vaarpunten',
				completed: false,	
			}
		]
	},
	{
		text: 'Analyseer welke data bruikbaar is voor routebepaling',
		completed: false,
		levelId: 4,
		subObjectives:[
			// {
			// 	text: 'Zet de smart bril op',
			// 	completed: false,	
			// },
			{
				text: 'Analyseer alle data',
				completed: false,	
			},
			{
				text: 'Stel vast welke data geschikt is',
				completed: false,	
			},
		]
	},
	{
		text: 'Bereken wanneer de vaarweg begangbaar is',
		completed: false,
		levelId: 5,
		subObjectives:[			
			{
				text: 'Analyseer de diepte van de vaargeul',
				completed: false,	
			},
			{
				text: 'Verzamel de juiste informatie over de hoogte van het oppervlaktewater',
				completed: false,	
			},
			{
				text: 'Analyseer de hoogtes van de bruggen',
				completed: false,	
			},
			{
				text: 'Vul het algoritme aan met de verzamelde data',
				completed: false,	
			},
		]
	},
	{
		text: 'Maak een visualisatie van alle verzamelde data; rapporteer hierover en sluit het project af',
		completed: false,
		levelId: 6,
		subObjectives:[
			{
				text: 'Maak een visualisatie van de sentiment analyses',
				completed: false,	
			},
			{
				text: 'Maak een visualisatie van de hoeveelheid stookolie in het water',
				completed: false,	
			},
			{
				text: 'Maak een visualisatie van de route die de sluis heeft afgelegd',
				completed: false,	
			},
			{
				text: 'Bekijk de poster',
				completed: false,	
			},
		]
	},

];

export const setObjectives = (data) => {
	Objectives = data;
};

const setSubCompleted = (id: number, subId: number) => {
	const subObjective = Objectives[id].subObjectives[subId];
	// audio.play();

	subObjective.completed = true;

	checkObjective(Objectives[id]);
	
	// if(id == activeObjective){
	// 	setActiveObjectiveData && setActiveObjectiveData(Objectives[id]);
	// }
};
// const audio1 = new Audio(goal_accomplished_02);

const checkObjective = (objective) => {
	let unlocks = 0;
	objective.subObjectives.map((sub) => {
		if(sub.completed){
			unlocks++;		
		}
	});
	if(unlocks >= objective.subObjectives.length){
		objective.completed = true;
		// audio1.play();
	}
	if (objective.completed === true) {
		Levels[objective.levelId].completed = true;
	}
	checkLevelsForUnlock();
};

// To be called by any component
export const completeObjective = (objectiveId, subObjectiveId) => {
	// Completed subObjectives
};

export {Objectives, checkObjective, setSubCompleted};

import React, { useEffect, useRef, useState, } from 'react';

import styled from 'styled-components';
import Theme from 'style/theme';
import { useGlobalContext } from 'context/global';
import FinalDataScreen from '../Screens/Final';
import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';

interface contentprops{
	img: string
}

const ContentWrapper = styled.div<contentprops>`
background-image: url(${p => p.img});
	background-color: ${Theme.colors.accent_4};
	display: flex;
	flex-direction: column;

	justify-content: center;

	width: 1650px;
	height: 980px;

	padding: 15px;
	user-select: none;
	border-radius: 20px;

	font-size: 3rem;


	/* transform: translate(-50%, -50%); */
`;

const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	//flex-direction: column;

	justify-content: center;
	align-items: center;
`;

const WegTabletFinal = () => {

	const { setActiveDialogueChunk, updateObjectiveData, setCloseButtonActive} = useGlobalContext();

	useEffect(() => {
		setActiveDialogueChunk &&setActiveDialogueChunk('l_04_finalquestion_01');
		setCloseButtonActive && setCloseButtonActive(false);
	},[]);


	return (
		<>
			<TabletBorder>
				<FinalDataScreen/>
			</TabletBorder>
		</>
	);
};

export default WegTabletFinal;
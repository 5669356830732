import { Diepte, Diepte_Hover, Schade, Schade_Hover } from 'assets/icons';
import { Underwater_01, Underwater_02, Underwater_03 } from 'assets/images';
import { click_01, filter_disable, filter_enable } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import { useGlobalContext } from 'context/global';
import { Objectives, setSubCompleted } from 'data/objectives';
import { useEffect, useState } from 'react';
import { useResponsiveFontSize } from 'react-responsive-font-size';
import { PaddingWrapper } from '../../components/BasicHtml';
import { Container, FilterLabel } from '../components';
import FilterInterface from '../components/FilterInterface';
import WaterScanFilter from '../components/scan/ScanFilter';
import WaterSearchFilter from '../components/searchWater/SearchFilter';

const icons = [	
	[Diepte,Diepte_Hover],
	[Schade,Schade_Hover]

];

const filterNames = [
	'Geen filter actief',
	'Dieptesonar',
	'Schade grid',
];

const WaterDroneView = () => {
	
	const responsiveFont = useResponsiveFontSize(0.6, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });
	const {activeDialogueChunk, setActiveDialogueChunk, updateObjectiveData, setCloseButtonActive} = useGlobalContext();
	const [activeFilter, setActiveFilter] = useState<number>(0);
	const {playSound} = useAudioContext();

	// Tracks filter progression. 0 == not seen, 1 == seen but not completed, 2 == completed
	const [filterProgress, setFilterProgress] = useState<string>(Objectives[2].subObjectives[1].completed ? '22' : '00');

	const filterButtonHandler = (i: number) => {			
		playSound && playSound(click_01);

		if(activeFilter != i){
			setActiveFilter(i);
		}
		else{
			setActiveFilter(0);

		}
	};

	// On Mount
	useEffect(() => {
		if(!Objectives[2].subObjectives[1].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_01_waterdrone_01');
			setCloseButtonActive && setCloseButtonActive(false);
		}
	}, []);

	// On change activeFilter
	useEffect(() => {
		if(activeFilter !== 0 && filterProgress[activeFilter - 1] === '0'){
			setActiveDialogueChunk && setActiveDialogueChunk(`l_01_WDfilter_0${activeFilter}_first`);		
			setFilterProgress(a => replaceAt(a, activeFilter - 1, 1));
		}
	}, [activeFilter]);
	
	// On change filterProgress
	useEffect(() => {
		if(filterProgress === '22'){
			// Completion of LuchtDrone
			setCloseButtonActive && setCloseButtonActive(true);
			setSubCompleted(2, 1); 
			updateObjectiveData && updateObjectiveData();
		}
	}, [filterProgress]);

	const onFilterComplete = (i: number) => {
		setActiveDialogueChunk && setActiveDialogueChunk(`l_01_WDfilter_0${activeFilter}_second`);	
		setFilterProgress(a => replaceAt(a, i, 2));
	};

	return (
		<PaddingWrapper style={{backgroundImage: `url("${Underwater_01}")`}}>
			<Container>  
				{ activeFilter === 1 ?

					<WaterScanFilter
						img={Underwater_02}
						onCompletion={() => onFilterComplete(0)}
						complete={filterProgress[0] === '2'}
					/>

					: activeFilter === 2 ?
						<WaterSearchFilter
							img={Underwater_03}
							onCompletion={() => onFilterComplete(1)}
							complete={filterProgress[1] === '2'}
						/>
						: null
				}
			
				<FilterLabel ref={responsiveFont}>{filterNames[activeFilter]}</FilterLabel>
				<FilterInterface
					icons={icons}
					clickHandler={(i) => filterButtonHandler(i)}
					activeFilter={activeFilter}
					filterProgress={filterProgress}
				/>
			</Container>
		</PaddingWrapper>
	);
};


//#region Styled Components



//#endregion

//#region Helper Functions

const replaceAt = (string, index, replacement) => {
	return string.substring(0, index) + replacement + string.substring(index + 1);
};

//#endregion
 
export default WaterDroneView;
const algorithm = [
	{
		text:'waterpeil',
		selected: false,
	},
	{
		text:'>',
		selected: false,
	},
	{
		text:'-4',
		selected: false,
	},
	{
		text:'-',
		selected: false,
	},
	{
		text:'vaardiepte',
		selected: false,
	},
	{
		text:'<',
		selected: false,
	},
	{
		text:'-2',
		selected: false,
	},
	{
		text:'+',
		selected: false,
	},
];


const answers = [
	'01236',
	'23650',
	'016AA',
	'650AA',
	'07612',
	'67612',
	'25070',
	'25670',
	'61230',
	'23056'
];

const algorithm2 = [
	{
		text:'waterpeil',
		selected: false,
	},
	{
		text:'>',
		selected: false,
	},
	{
		text:'3',
		selected: false,
	},
	{
		text:'-',
		selected: false,
	},
	{
		text:'brug hoogte',
		selected: false,
	},
	{
		text:'<',
		selected: false,
	},
	{
		text:'8',
		selected: false,
	},
	{
		text:'+',
		selected: false,
	},

];

const answers2 = [
	'05632',
	'63210',
	'07256',
	'27056',
	'61072',
	'61270',
	'25630',
	'63012',
];


export {algorithm, answers, algorithm2, answers2};
import { tabletBorder } from 'assets/images';
import { ReactNode } from 'react';
import styled from 'styled-components';
type tabletborderProps = {
    children: ReactNode;
}
const TabletBorder = ({children}: tabletborderProps) => {
	return(

        
		<Content>
			<ActiveComponent>
				{children}
			</ActiveComponent>
		</Content> 
	);
};

//styled components
const Content = styled.div`
    background-image: url(${tabletBorder});
	background-size: 100% 100%;
	background-repeat: no-repeat;
overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 120vh;
`;

const ActiveComponent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    height: 86.5%;
    width: 92%;
	//overflow: hidden; 

`;


export default TabletBorder;
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { Objectives, setSubCompleted } from 'data/objectives';
import { useGlobalContext } from 'context/global';
import { TV_m } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    Cube200: THREE.Mesh
    Cube200_1: THREE.Mesh
  }
  materials: {
    Plastic_01: THREE.MeshStandardMaterial
    TwitterTweet_2: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(TV_m) as GLTFResult;
	const { updateObjectiveData } = useGlobalContext();

	useEffect(() => {
		if(!Objectives[3].subObjectives[0].completed){
			setSubCompleted(3,0);
			updateObjectiveData && updateObjectiveData();
		}
	},[]);
	return (
		<group rotation-y={4.716} scale={3}  ref={group} {...props} dispose={null}>
			<mesh geometry={nodes.Cube200.geometry} material={materials.Plastic_01} />
			<mesh geometry={nodes.Cube200_1.geometry} material={materials.TwitterTweet_2} />
		</group>
	);
}

useGLTF.preload(TV_m);

import { useEffect, } from 'react';
import { useGlobalContext } from 'context/global';
import ChartOveriew from './ChartOverview';
import ZeeschermBorder from 'components/UniversalComponent/Borders/zeeschermborder';
import { Objectives } from 'data/objectives';

const ZeeMinigame= () => {

	const {setActiveDialogueChunk} = useGlobalContext();

	useEffect(() => {
		if(!Objectives[4].subObjectives[4].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_03_zeeminigame_01');
		}
	},[]);

	return (
		<>
			<ZeeschermBorder>
				<ChartOveriew id={4}/>
			</ZeeschermBorder>
		</>
	);
};

export default ZeeMinigame;
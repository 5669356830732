import Theme from 'style/theme';
import styled from 'styled-components';

interface ContentProps {
	img : string 
}

const ContentWrapper = styled.div<ContentProps>`
	background-image: url(${p => p.img});
	background-position: center;
    background-size: 100% 100%;
	display: flex;
	flex-direction: column;

	justify-content: center;
	width: 100%;
	height: 100%;
	
	font-size: 4rem;
	user-select: none;
	border-radius: 2%;
`;

const Content = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	

`;



export {Content, ContentWrapper};
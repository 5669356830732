declare module 'styled-components' {
	export interface DefaultTheme {
		colors: {
			primary: string;
			secondary: string;
			accent_1: string;
			accent_2: string;
			accent_3: string;
			accent_4: string;
			accent_5: string;
			hover: string;
			hover_bg: string;
		}
		designFormats: string[];
		mediaQueries: {
				l_max: string,
				l: string,
				xl: string 
		};
		devices: {
			mobile: string,
			desktop: string,
		}
		filters:{
			dropShadow: string;
		}
	}
}

const Theme = {
	designFormats: ['428', '1920'],
	mediaQueries: {
		l_max: '@media (max-width: 640px)',
		l: '@media (min-width: 640px)',
		xl: '@media (min-width: 1920px)',
	},
	devices : {
		mobile: '(max-width: 999px)',
		desktop: '(min-width: 999px)',
	},
	colors: {
		// primary: '#1f1f1f',
		primary: 'white',
		secondary: 'black',
		accent_1: '#fff22a',
		accent_2: '#1cb1e0',
		accent_3: '#f7232f',
		accent_4: '#7dcff5',
		accent_5: '#6fb51d',

		hover: 'white',
		hover_bg: '#1cb1e0',
	},
	filters: {
		dropShadow: 'drop-shadow(0px 1px 5px rgba(0,0,0,0.5))',
	}
};

export default Theme;
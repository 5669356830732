import { Option } from 'data/dialogues';
import styled from 'styled-components';
import { proportional } from 'util/proportional';

const OptionsContainer = styled.div.attrs({bottom:[-15,-30]})`

    ${proportional}

    position: absolute;
    min-width: 50%;
    right:0;
    transform: translateY(100%);


    display: flex;
    flex-direction: column;
`;

const OptionEntry = styled.button`
    cursor: pointer;
    margin-bottom: 1rem;
    background-color: ${props => props.theme.colors.primary};

    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    color: ${p => p.theme.colors.accent_2};


    border: none;

    &:hover, :focus {
        background-color:  ${p => p.theme.colors.hover_bg};
        color: ${p => p.theme.colors.hover};
        font-weight: bold;
    }

`;

type OptionsProps = {
    options: Option[];
    onSelectOption : (val:  number) => void;
}

const Options = ({options, onSelectOption} : OptionsProps) => {
	return (
		<OptionsContainer>
			{ options.map((option, index) => (
				<OptionEntry key={`${index}-${option.text}-option`} onClick={() => onSelectOption(index)}>
					{option.text}
				</OptionEntry>
			))}
		</OptionsContainer>
	);
};

export default Options;
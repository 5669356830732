import { hoog, kritiek, laag } from 'assets/images';

const materialScans = [
	{
		left: '18%',
		top:'22%',
		img: hoog,
		width: '60%',
		heigth: '73%',
		complete:false
	},
	{
		top: '19%',
		left: '45%',
		img: laag,
		width: '60%',
		heigth: '100%',
		complete:false
	},
	
	{
		left: '65%',
		top:'25%',
		img: kritiek,
		width: '70%',
		heigth: '145%',
		complete:false
	},
];

export default materialScans;
import { crisiskantoor_01, Kantoor_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: crisiskantoor_01,
	pitch: 0,
	yaw: 10
};

let customHotspots  =	[
	// {
	// 	'pitch': -11.9687,
	// 	'yaw': -0.51685,
	// 	'action': 'changeScene',
	// 	'createTooltipArgs': {
	// 		sceneId: 2,
	// 	}
	// },
	{

		'pitch': -31.3345,
		'yaw': -161.3346,
		'action': 'openModel',
		'createTooltipArgs': {
			'type': 'scene',
			'text': 'Text mining',
			'param': 'textminingmonitor',
			//'param': 'placeholdertablet',
			
		}
	},
	{
		'pitch': -1.497464,
		'yaw': 45.50765,
		'action': 'openModel',
		'createTooltipArgs': {
			'type': 'scene',
			'text': 'Kalender',
			'param': 'scheurkalender',			
		}
	},
	{

		'pitch': 14.590,
		'yaw': -9.126,
		'action': 'openModel',
		'createTooltipArgs': {
			'type': 'scene',
			'text': 'Groot scherm',
			'param': 'kantoortv',
			
		}
	},
	
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene1 = {
	sceneId: '1',
	config: getConfig(customConfig),

};	

export default Scene1;
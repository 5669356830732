import { Vaarweg_02 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Vaarweg_02,
	pitch: 176.2338,
	yaw: 2.333
};

let customHotspots  =	[
	{
		'pitch': -26.19912,
		'yaw': 150.167,
		'action': 'changeScene',
		'createTooltipArgs': {
			sceneId: 3,
		}
	},
	{
		'pitch': -19.25236,
		'yaw': -134.999,
		'action': 'openModel',
		'createTooltipArgs': {
			'type':'scene',
			'text':'de brug',
			'param': 'brugtablet'
		}
	
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene4 = {
	sceneId: '4',
	config: getConfig(customConfig),

};	

export default Scene4;
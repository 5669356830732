import { s_scanning } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import { useEffect, useState } from 'react';
import { useResponsiveFontSize } from 'react-responsive-font-size';
import { theme } from 'style';
import styled, { keyframes } from 'styled-components';
import { FilterProps } from '..';

interface ScanFilterProps {
    img: string;
}
 
const ScanFilter = (props : ScanFilterProps & FilterProps  ) => {

	const [scanStep, setScanStep] = useState<number>(props.complete ? 2 : 0); // 0 == not started, 1 == scanning, 2 scan complete 
	const responsiveFont = useResponsiveFontSize(0.5, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });
	const {playSound} = useAudioContext();
	
	useEffect(() => {
		if(scanStep === 2 && !props.complete){
			props.onCompletion();
		}

		if(scanStep === 1){
			playSound && playSound(s_scanning);
		}

	}, [scanStep]);

	return (
		<Container>	
			{ scanStep === 0 ? 
				<StartScanButton ref={responsiveFont} onClick={() => setScanStep(1)}>Start scan</StartScanButton>
				: scanStep === 1 ?
					<ScanBackground>
						<ScanLine onAnimationEnd={() => setScanStep(2)}/>
						<ScanningStatus>Bezig met scan{[...Array(3)].map((x, index) => <span key={`${index}-dots-filter-scan`}>.</span>)}</ScanningStatus>
					</ScanBackground> 
					: scanStep === 2 ? 
						<Result img={props.img}/>// Resultaat
						: ''
			}
		</Container>
	);

};

//#region Animations

const ScanAnimation = keyframes`
	0% {
		top: -10%;	
	}

	50% {
		top: 110%;
	}

	100% {
		top: -10%;
	}
`;

const DotAnimation = keyframes`
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`;

const FadeInAnimation = keyframes`
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
`;

//#endregion

//#region Styled Components

const Container = styled.div`

	position: absolute;

	left:0;
	top:0;
	bottom: 0;
	right:0;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}

	/* background-color: honeydew; */

`;

const StartScanButton = styled.button`

	position: absolute;

	left:50%;
	top:50%;

	border: none;
	outline: none;
	width: 15%;
	height: 8%;
	padding: 1% ;
	pointer-events: all;

	color: ${theme.colors.accent_2};
	cursor:pointer;
	transform: translateX(-50%);

	& :hover, :focus {
		background-color: ${theme.colors.hover_bg};
		color: ${theme.colors.hover};
	}

`;

const ScanningStatus = styled.div`

	position: absolute;

	left:50%;
	top:50%;

	transform: translate(-50%,-50%);

	font-size: 5vh;

	& > span {
		font-size: 1.2em;
		animation: ${DotAnimation} 2s infinite;
	}

	& :nth-child(2){
		animation-delay: 400ms;
	}

	& :nth-child(3){
		animation-delay: 600ms;
	}
	
`;

const ScanLine = styled.div`
	position: absolute;
	left:0;
	right:0;
	height: 5px;
	top: -10%;

	background-color: red;

	animation: ${ScanAnimation} 4s ease;
	animation-iteration-count: 2;
	animation-delay: 500ms;

	box-shadow: 0 0 30px red;

`;

const ScanBackground = styled.div`
	position: absolute;
	left:0;
	top:0;
	bottom: 0;
	right:0;

	background-color: rgba(0,50,0,0.5);

	animation: ${FadeInAnimation} 1000ms;
`;

const Result = styled.div<{img:string}>`
	position: absolute;
	left:0;
	top:0;
	right:0;
	bottom: 0;

	animation: ${FadeInAnimation} 1s;

	background-image: url(${p => p.img});
	background-position: center;
	background-size: cover;
`;

//#endregion


 
export default ScanFilter;
import { Level } from 'data/levels';
import { ppid } from 'process';
import { ReactChild, ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import Lock from './Lock';

interface LevelButtonProps {
	img : string
	top : string
	left : string
	locked : boolean
	completed: boolean
	selected: boolean
	children: ReactNode
	hovertext: string
	level: Level
	index: number
	onSelect: (i:number) => void
}

const outlineSize = window.innerWidth <= 600 ? 3 : 4;

const LevelButton = (props: LevelButtonProps) => {

	const [showLabel, setShowLabel] = useState<boolean>(false);

	return (
		<LevelButtonWrapper 
			props={props} 
			onMouseEnter={() => setShowLabel(true)} 
			onMouseLeave={() => setShowLabel(false)}
			onClick={() => props.onSelect(props.index)}
			id={`level-${props.index}`}
		>
			{props.locked ? '' : props.selected || showLabel ?
				<LevelButtonLabel>{props.level.levelSelectDetails.name}</LevelButtonLabel> 
				: ''
			}
			<LevelButtonIcon locked={props.locked} selected={props.selected} img={props.img}/>
			<Lock
				top={props.level.levelSelectDetails.imageTop} 
				left={props.level.levelSelectDetails.imageLeft}
				locked={props.level.locked}
				completed={props.level.completed}
			/> 
		</LevelButtonWrapper>
	);
};

//#region Styled Components

const LevelButtonIcon = styled.div<{img: string, locked:boolean, selected:boolean}>`
	background-image: url(${p => p.img}) ;
	background-position: center;
	background-size: cover;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;

	transition: transform 0.5s;	 

	${props => !props.locked && css`
		&:hover {
			transform: scale(1.2);
		}
	`} 
	${p => p.selected && css`
		transform: scale(1.2);
	`}

`;

const LevelButtonLabel = styled.div`
	position: absolute;
	top:0;
	left:90%;

	background: white;
	padding: 8px 10px;
	font-weight: bold;

	transform: translate(0,-90%);
`;

interface LevelButtonWrapper  {
	props: LevelButtonProps,
}

const LevelButtonWrapper = styled.div<LevelButtonWrapper>`

	position: absolute;
	left: ${p => p.props.left};
	top: ${p => p.props.top};
	z-index: 5;
	width: 10%;
	padding-top: 10%;

`;

//#endregion

// const LevelButton = styled.div<LevelButtonProps>`
// 	position: absolute;
// 	left: ${p => p.left};
// 	top: ${p => p.top};
// 	z-index: 5;
//     width: 10%;
// 	padding-top: 10%;
// 	//hover label
// 	& > :first-child{
// 		filter:none!important;
// 		position: absolute;
// 		background-color: #fff;
// 		color: #000;
// 		text-align: center;
// 		width: max-content;
// 		height: max-content;
// 		padding:  10px 10px 10px 10px;
// 		transform: translate(-50%, -100%);
// 		top: -15px;
// 		opacity: 1;
// 		margin-left: 50%;
// 		border-radius: 20px;
// 		font-size: 1.5rem;
// 		cursor: default;
// 		filter: none!important;
// 	}  
// 	//level icon
// 	& > :nth-child(2){
// 		background-image: url(${p => p.img}) ;
// 		background-position: center;
// 		background-size: cover;
// 		position: absolute;
// 		left: 0;
// 		right: 0;
// 		top: 0;
// 		bottom: 0;

// 	& ::first-child:hover{
// 		outline: solid 2px red;
// 		opacity: 1!important;
// 	}

// // On Level Unlocked

// }
// }   
// `;

export default LevelButton;


import i18next from 'components/Localization';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ConsoleRow = styled.div`
    display: flex;
    justify-content: left;
	text-align: left;
    align-items: center;
	margin-top: 10px;
    button {
        margin-left: 30px;
    }
	select {
		font-size: 24px;
	}
`;

const ConsoleLabel = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
	padding-right: 10px;
`;

const ConsoleLanguageSelect = () => {
	const { t } = useTranslation();
	const languageOptions = ['en', 'nl'];

	const onChange =(event) =>{
		i18next.changeLanguage(event.target.value);
	};

	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.3')}</ConsoleLabel>
			<select name="language" onChange={onChange}>
				{languageOptions && languageOptions.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
			</select>
		</ConsoleRow>
	);
};

export default ConsoleLanguageSelect;

import { useGlobalContext } from 'context/global';
import Theme from 'style/theme';
import styled from 'styled-components';
import { useResponsiveFontSize } from 'react-responsive-font-size';


const SluishologramButton = () => {
	const {hologramButtonState, setHologramButtonState}= useGlobalContext();

	return (<>
		{hologramButtonState === 1 && <Button onClick={() => setHologramButtonState && setHologramButtonState(2)}>Genereer 3D-model</Button>}
	</>
	);
};
//styled components
const Button = styled.button`
    border: none;
    position:absolute;
    left: 50%;
    top:50%;
    width: 30vh;
    height: 5vh;
    transform: translateX(-50%);
    background-color: ${props => Theme.colors.primary};
    color: ${props => Theme.colors.accent_2};
    margin: 0;

    cursor: pointer;
    text-align: center;
    /* display: flex;
    align-items: center; */

    font-size: 2vh;

    font-weight: bold;
    line-height: 100%;
    z-index: 5;
    border: none;

    padding: 0px 10px 0px 10px;

    &:hover, :focus{
        color: ${p => Theme.colors.hover};
        background-color: ${p => Theme.colors.hover_bg};
    }
	`;

export default SluishologramButton;

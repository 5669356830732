/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState} from 'react';

import { Content, ContentWrapper} from '../styles';
import { useThreeContext } from 'context/three';
import { Peilschaal1} from 'assets/images';
import { useGlobalContext } from 'context/global';
import { Objectives, setSubCompleted } from 'data/objectives';

import TabletBorder from 'components/UniversalComponent/Borders/tabletborder';


interface DotsProps {
	top : string
	left : string
	selected: boolean
}

const PeilschaalScherm = () => {

	const { setActiveDialogueChunk, updateObjectiveData, setActiveIntObj } = useGlobalContext();

	useEffect(() => {
		setActiveDialogueChunk && setActiveDialogueChunk('l_05_screen_02');

		return () => {
			setSubCompleted(6,1);
			updateObjectiveData && updateObjectiveData();

			if(Objectives[6].subObjectives[0].completed == true && Objectives[6].subObjectives[1].completed == true && Objectives[6].subObjectives[2].completed == true ){
				setTimeout(() => {
					setActiveIntObj && setActiveIntObj('algoritmetablet');
				}, 1);
			}
		};
	},[]);

	return (
		<>
			<TabletBorder>
				<ContentWrapper img={Peilschaal1}>
					
				</ContentWrapper>
			</TabletBorder >
		</>
	);
};

export default PeilschaalScherm;
import { Afmetingen, Afmetingen_Hover, Materiaal, Materiaal_Hover, Water, Water_Hover } from 'assets/icons';
import { AerialShot_01, AerialShot_02, AerialShot_03, AerialShot_04 } from 'assets/images';
import { click_01, filter_disable, filter_enable } from 'assets/sounds';
import { useAudioContext } from 'context/audio';
import { useGlobalContext } from 'context/global';
import { Objectives, setSubCompleted } from 'data/objectives';
import { useEffect, useState } from 'react';
import { useResponsiveFontSize } from 'react-responsive-font-size';
import { PaddingWrapper } from '../../components/BasicHtml';
import { Container, FilterLabel } from '../components';
import FilterInterface from '../components/FilterInterface';
import MeasureFilter from '../components/measure/MeasureFilter';
import ScanFilter from '../components/scan/ScanFilter';
import SearchFilter from '../components/searchLucht/SearchFilter';

const icons = [
	[Materiaal,Materiaal_Hover],
	[Afmetingen,Afmetingen_Hover],
	[Water, Water_Hover]
];

const filterNames = [
	'Geen filter actief',
	'Materiaalkwaliteit',
	'Metingen',
	'Waterkwaliteit'
];

const LuchtDroneView = () => {

	const { setActiveDialogueChunk, updateObjectiveData, setCloseButtonActive} = useGlobalContext();
	const [activeFilter, setActiveFilter] = useState<number>(0);
	const {playSound} = useAudioContext();
	const responsiveFont = useResponsiveFontSize(0.6, { setFontSize: true, globalVariableName: 'responsiveFont', lockFontSize: false });

	// Tracks filter progression. 0 == not seen, 1 == seen but not completed, 2 == completed
	const [filterProgress, setFilterProgress] = useState<string>(Objectives[2].subObjectives[0].completed ? '222' : '000');

	const filterButtonHandler = (i: number) => {			
		playSound && playSound(click_01);
		if(activeFilter != i){
			setActiveFilter(i);

		}
		else{
			setActiveFilter(0);

		}
	};

	// On Mount
	useEffect(() => {
		if(!Objectives[2].subObjectives[0].completed){
			setActiveDialogueChunk && setActiveDialogueChunk('l_01_airdrone_01');
			setCloseButtonActive && setCloseButtonActive(false);
		}
	}, []);

	// On change activeFilter
	useEffect(() => {
		if(activeFilter !== 0 && filterProgress[activeFilter - 1] === '0'){
			setActiveDialogueChunk && setActiveDialogueChunk(`l_01_LDfilter_0${activeFilter}_first`);		
			setFilterProgress(a => replaceAt(a, activeFilter - 1, 1));
		}
	}, [activeFilter]);
	

	// On change filterProgress
	useEffect(() => {
		if(filterProgress === '222'){
			// Completion of LuchtDrone
			setCloseButtonActive && setCloseButtonActive(true);
			setSubCompleted(2, 0); 
			updateObjectiveData && updateObjectiveData();
		}
	}, [filterProgress]);

	const onFilterComplete = (i: number) => {
		setActiveDialogueChunk && setActiveDialogueChunk(`l_01_LDfilter_0${activeFilter}_second`);		
		setFilterProgress(a => replaceAt(a, i, 2));
	};

	return ( 
        
		<PaddingWrapper style={{backgroundImage: `url("${AerialShot_01}")`}}>
			<Container> 

				{ activeFilter === 1 ?
					<SearchFilter
						img={AerialShot_04}
						onCompletion={() => onFilterComplete(0)}
						complete={filterProgress[0] === '2'}
					/>

					: activeFilter === 2 ?
						<MeasureFilter
							img={AerialShot_03}
							onCompletion={() => onFilterComplete(1)}
							complete={filterProgress[1] === '2'}
						/>

						: activeFilter === 3 ?  
							<ScanFilter 
								img={AerialShot_03}
								onCompletion={() => onFilterComplete(2)}
								complete={filterProgress[2] === '2'}
							/>

							: null
				}
			
				<FilterLabel ref={responsiveFont}>{filterNames[activeFilter]}</FilterLabel>
				<FilterInterface
					icons={icons}
					clickHandler={(i) => filterButtonHandler(i)}
					activeFilter={activeFilter}
					filterProgress={filterProgress}
				/>
			</Container>
		</PaddingWrapper>
	);
};

//#region Styled Components



//#endregion

//#region Helper Functions

const replaceAt = (string, index, replacement) => {
	return string.substring(0, index) + replacement + string.substring(index + 1);
};

//#endregion
 
export default LuchtDroneView;


/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { scheurkalender2 } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    Cube272: THREE.Mesh
    Cube272_1: THREE.Mesh
    Cube272_2: THREE.Mesh
    Cube272_3: THREE.Mesh
  }
  materials: {
    Date: THREE.MeshStandardMaterial
    material: THREE.MeshStandardMaterial
    MetalRings: THREE.MeshStandardMaterial
    CalendarDate: THREE.MeshStandardMaterial
  }
}

export default function Scheurkalender2({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(scheurkalender2) as GLTFResult;
	return (
		<group rotation-y={4.8} scale={4} ref={group} {...props} dispose={null}>
			<group scale={[0.66, 0.66, 0.54]}>
				<mesh geometry={nodes.Cube272.geometry} material={materials.Date} />
				<mesh geometry={nodes.Cube272_1.geometry} material={materials.material} />
				<mesh geometry={nodes.Cube272_2.geometry} material={materials.MetalRings} />
				<mesh geometry={nodes.Cube272_3.geometry} material={materials.CalendarDate} />
			</group>
		</group>
	);
}

useGLTF.preload(scheurkalender2);

import { Sluis_01 } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: Sluis_01,
	pitch: 0,
	yaw: 0
};

// let customHotspots  =	[
// 	{
// 		'pitch': -21.541,
// 		'yaw': -138.537,
// 		'action': 'openModel',
// 		'createTooltipArgs': {
// 			'type':'scene',
// 			'text':'content.hoveritems.4',
// 			'param': 'van'
// 		}
// 	},
// ];

// customHotspots = getHotspots(customHotspots);
// customConfig['hotSpots'] = customHotspots;

const Scene16 = {
	sceneId: '16',
	config: getConfig(customConfig),

};	

export default Scene16;
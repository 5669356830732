import { CheckIcon } from 'assets/icons';
import { theme } from 'style';
import styled, { css } from 'styled-components';

interface FilterInterfaceProps {
    icons: string[][];
    clickHandler: (i:number) => void;
    activeFilter: number;
    filterProgress: string;
}
 
const FilterInterface = (props: FilterInterfaceProps) => {

	return (  
		<Container>
			{ props.icons.map( (icons, index) => (
				<FilterButton 
					key={`${index}-filter-button`}
					img={icons[0]} 
					hover={icons[1]}
					onClick={(e)=> {props.clickHandler(index + 1); e.currentTarget.blur();}} 
					selected={props.activeFilter === index + 1 ? true : false}
					complete={props.filterProgress[index] === '2'}
				>
				</FilterButton>
			))}
		</Container>
	);
};

//#region Styled Components

const FilterButton = styled.button<{img: string, hover:string, selected: boolean, complete: boolean}>`
    height: 70%;
    width: 6%;
    background-color: ${p => p.selected? 'yellow' : 'white'};


    background-image: url(${p => p.img});
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;

    border-radius: 100%;

    border:none;
    outline:none;

    margin-left: 0.5%;
    margin-right: 0.5%;

    position: relative;

    &:hover, :focus {
       background-color: ${theme.colors.hover_bg};
       background-image: url(${p => p.hover});
    }
    transition: height 0.2s, width 0.2s;
    /* transition-timing-function: ease-out; */

    ${ p => p.selected && css`
        width: 9%;
        height: 100%;
    `}

    ${ p => p.complete && css`

      &:after{
          content: '';
          position: absolute;
          right:-15%;
          top: -15%;
          height: 50%;
          width: 50%;

          background-image: url(${CheckIcon});
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          /* background-color: red; */
      }

    ` }
`;

const Container = styled.div`

    /* background-color: red; */

    position: absolute;
    height: 14%;
    width: 100%;
    bottom: 5%;
    /* height: 150px; */
    display: flex;
    justify-content: center;
    align-items: end;
`;

//#endregion
 
export default FilterInterface;